import React from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import LocationMgmtLayout from "../LocationMgmtLayout/LocationMgmtLayout";
import AnnouncementsLayout from "../AnnouncementsLayout/AnnouncementsLayout";
import "./AdminHomeLayout.css";

const AdminHomeLayout = () => {
  return <div className="homeLayoutContainer">
    <Tabs
      defaultActiveKey="announcement"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="announcement" title="Announcements">
        <AnnouncementsLayout/>
      </Tab>
      <Tab eventKey="location" title="Location Mgmt">
        <LocationMgmtLayout />
      </Tab>
      </Tabs>
  </div>;
};

export default AdminHomeLayout;
