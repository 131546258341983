import React, { useEffect,useContext, useState } from 'react';
import {Container} from 'react-bootstrap';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import classes from './SelectLocality.module.css';
import ContactFooter from '../../../Components/ContactFooter/ContactFooter';
import { EventTracking } from "../../../Utils/gaTracking";
import  SignupData  from '../../../Context/PublicUserContext';
import userServices from '../../../Services/UserServices';
import { stateList } from '../../../Utils/USStates';
import PropagateLoader from "react-spinners/PropagateLoader";
import Footbar from '../../../Components/Footbar/Footbar';

const SelectLocality = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const {setUserFirstName} = useContext(SignupData);
  const [locations, setLocations] = useState([])
  const [locationLoading, setLocationLoading] = useState();


  useEffect(()=>{
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    const storeCookies = localStorage.getItem("enableCookies");
    const userCookie = cookies.get("user");   
    if(storeCookies !== "false" && userCookie==="public"){
      cookies.set("user","prospect",[{path:"/"}]);
    }
    sessionStorage.setItem("addressDetails","");
    sessionStorage.setItem("selectedPackage","");
    sessionStorage.setItem("customerId","");
    setUserFirstName("");
    sessionStorage.setItem("termsAgreed",JSON.stringify(false))
  },[]);

  useEffect(()=>{
    setLocationLoading(true);
    userServices.getLocations()
    .then((response)=>{
      handleLocations(response.data);
      setLocationLoading(false);
    })
    .catch((err)=>{
      setLocationLoading(false);
    })
  },[])

    const handleLocations = (data) =>{
      let states = data
      .map(item => item.state)
      .filter((value, index, self) => self.indexOf(value) === index)
      let arrLength = (states.length);
      states.map((item)=>states[item]=
        data.filter((fitem)=>fitem.state===item).sort()
      )
      states.splice(0,arrLength);
      states=sortObj(states);
      setLocations(states)      
    }

    const displayStateName = (item)=>{
      let stateName= stateList.filter((state)=>{
        return state.abbreviation === item
      })
      return stateName[0].name;
    }

  const sortObj = (obj) => {
    return Object.keys(obj).sort().reduce(function (result, key) {
      result[key] = obj[key];
      return result;
    }, {});
  }

  const citySelector = (item)=>{
    const userCookie = cookies.get("user");  
    const storeCookies = localStorage.getItem("enableCookies"); 
    if(storeCookies !== "false" && item.service){
      // cookies set to indicate the user is a qualified prospect
      cookies.set("user","qp",[{path:"/"}]);
    }
    else if(storeCookies !== "false" && !item.service && userCookie !=="qp"){
      // cookies set to indicate the user as expression of interest
      cookies.set("user","eoi",[{path:"/"}]);
    }
    EventTracking(item.city, "Selected Location", item.state);
    if(item.futureLocation){
      navigate('/contact');
    } 
    else if(item.multiTenantDomain){
      if(process.env.NODE_ENV==="development"){
        navigate(`/signup/new`)
      }
      else if(process.env.NODE_ENV==="production"){
        window.location.assign(`https://${item.multiTenantDomain}/home`)
      }
    }
    else{
      navigate('/contact');
    }
  }
  return (
    <>
    <div className={classes.localityOuterContainer}>
        <h2 className={classes.localityTitle}>Select your nearby town.</h2>
        <Container className={classes.localityInnerContainer}>
        {locationLoading ? <div className={classes.loaderContainer}><PropagateLoader
              color="#E84E3B"
              size={16}
            />
            </div>:
            <>
          {Object.keys(locations).map((key, index) => {
            return(<div className={classes.stateContainer} key={index}>
            <h2 className={classes.StateName}>{displayStateName(key)}</h2>
            <div key={index} className={classes.stateInnerContainer}>
            {locations[key].sort((a,b)=>{if(a.city<b.city){return -1} if(a.city>b.city){return 1} return 0}).map((item,index)=>(
              <div className={classes.indvidualLocations} key={index}>
                <p className={classes.cityName} onClick={()=>citySelector(item)}>{item.city}, {item.state}</p> 
              </div>
            ))}
            </div>
            </div>)
           })
          }
          </>}
        </Container>
    </div>
    <ContactFooter isDark={false} />
    <Footbar />
  </>
  )
}

export default SelectLocality;