import React, { useEffect, useState,useContext } from "react";
import { Button, Container, InputGroup, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {useForm} from "react-hook-form";
import SignupProgressBar from "../../../Components/SignupProgressBar/SignupProgressBar";
import classes from "./CreateAccount.module.css";
import ConfirmationModal from "../../../Components/ConfirmationModal/ConfirmationModal";
import ContactFooter from "../../../Components/ContactFooter/ContactFooter";
import SignupData  from "../../../Context/PublicUserContext";
import services from "../../../Services/UserServices";
import { PropagateLoader } from "react-spinners";
import DefaultCheckSquare from "../../../Assets/SVGIcons/DefaultCheckSquare";
import SuccessCheck from "../../../Assets/SVGIcons/SuccessCheck";
import ErrorCheck from "../../../Assets/SVGIcons/ErrorCheck";
import EyeSlashIcon from "../../../Assets/SVGIcons/EyeSlashIcon";
import EyeIcon from "../../../Assets/SVGIcons/EyeIcon";
import Footbar from "../../../Components/Footbar/Footbar";

const CreateAccount = () => {
    let percentComplete = 0
    let prevPercentComplete = 0;
    const navigate = useNavigate();
    const {setUserFirstName} = useContext(SignupData);
    const {  register, handleSubmit,reset, setError,clearErrors, formState: { errors }} = useForm();
    const [selectedPackage] = useState(sessionStorage.getItem("selectedPackage") ?JSON.parse(sessionStorage.getItem("selectedPackage")):"");
    const [selectedLocation] = useState(sessionStorage.getItem("location") ?JSON.parse(sessionStorage.getItem("location")):"");
    const [userPassword, setUserPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [emailId, setEmailId] = useState("");
    const [confirmEmailId, setConfirmEmailId] = useState("");
    const [matchPwd, setMatchPwd] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);
    const [confirmPwdShown, setConfirmPwdShown] = useState(false);
    const [enableCreateButton, setEnableCreateButton] = useState(false);
    const [passwordAccepted,setPasswordAccepted] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pwdValidations, setPwdValidations] = useState({
      validation1: false,
      validation2: false,
      validation3: false,
      validation4: false,
      validation5: false,
    });
    const [defaultPwdCheck, setDefaultPwdCheck] = useState({
      validation1: true,
      validation2: true,
      validation3: true,
      validation4: true,
      validation5: true,
    });

    const pwdInputHandler = (e) =>{
      let { value } = e.target;
      setUserPassword(value);
      if(value.length > 0){
        setDefaultPwdCheck({
          validation1: false,
          validation2: false,
          validation3: false,
          validation4: false,
          validation5: false,})
      }
      else{
        setDefaultPwdCheck({
          validation1: true,
          validation2: true,
          validation3: true,
          validation4: true,
          validation5: true,
        })
      }
      validationCheck(value);
    }
    
    const validationCheck = (pwd) => {
      let validationObject={
        validation1: false,
          validation2: false,
          validation3: false,
          validation4: false,
          validation5: false
      }
      const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      if(pwd.length>=8){
        validationObject.validation1= true;
      }
      else{
        validationObject.validation1= false;
      }
      if(/[a-z]/.test(pwd)){
        validationObject.validation3= true;
      }
      else{
        validationObject.validation3= false;
      }
      if(/[A-Z]/.test(pwd)){
        validationObject.validation2= true;
      }
      else{
        validationObject.validation2= false;
      }
      if(/\d/.test(pwd)){
        validationObject.validation4= true;
      }
      else{
        validationObject.validation4= false;
      }
      if(specialChars.test(pwd)) {
        validationObject.validation5= true;
      }
      else{
        validationObject.validation5= false;
      }

      setPwdValidations(validationObject);
    }

    const confirmPwdHandler = (e) =>{
      let { value } = e.target;
      setConfirmPassword(value);
    }
    useEffect(()=>{
      if(confirmPassword===userPassword && userPassword){
        setMatchPwd(true);
        clearErrors("password2");
      }
      else if(confirmPassword!==userPassword && userPassword && confirmPassword ){
        setError("password2",{type:"pwdMismatch"});
        setMatchPwd(false);
      }
      else{
        setMatchPwd(false);
      }      
    },[confirmPassword, userPassword])

    const handlePwdView = () => {
      setPasswordShown(!passwordShown);
    }
    const handleConfirmPwdView = () => {
      setConfirmPwdShown(!confirmPwdShown);
    }
    useEffect(()=>{
      if(pwdValidations.validation1 && pwdValidations.validation2 && pwdValidations.validation3 && pwdValidations.validation4 && pwdValidations.validation5 && matchPwd){
        setEnableCreateButton(true);
      }
      else{
        setEnableCreateButton(false);
      }
      if(pwdValidations.validation1 && pwdValidations.validation2 && pwdValidations.validation3 && pwdValidations.validation4 && pwdValidations.validation5){
        clearErrors("password1")
        setPasswordAccepted(true);
      }
      else{
        if(userPassword){
          setError("password1",{type:"pwdCriteriaFailed"})
          setPasswordAccepted(false);
        }    
      }
    },[pwdValidations,matchPwd])

    useEffect(()=>{
      sessionStorage.setItem("accountData","");
      sessionStorage.setItem("ccBillingDetails","");
      sessionStorage.setItem("achPaymentDetails","");
      sessionStorage.setItem("customerId","");
      sessionStorage.setItem("termsAgreed",JSON.stringify(false));
      setUserFirstName("");
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    },[])

    useEffect(()=>{
      if(emailId && emailId === confirmEmailId){
        clearErrors("emailId2");
        clearErrors("emailId1")
      }
    },[emailId, confirmEmailId])

    const onSubmit = (data) =>{
      if(data.emailId1 !== data.emailId2){
        setError("emailId2",{type:"emailMismatch"})
        window.scrollTo({top: 100, left: 0, behavior: 'smooth'});
      }
      else{
        clearErrors("emailId2");
      }

      if(data.emailId1 === data.emailId2){
        setLoading(true);
        services.checkEmailId(selectedLocation.id,data.emailId1)
        .then((response)=>{
          setLoading(false);
          if(response.status===204){
            sessionStorage.setItem("accountData",JSON.stringify({emailId: data.emailId1, password: data.password1}));
            navigate("/contact-info");
          }
          else{
            setError("emailId1",{type:"emailExists"});
            window.scrollTo({top: 100, left: 0, behavior: 'smooth'});
          }
        })
        .catch(err=>{
          setLoading(false);
          setError("emailId1",{type:"emailExists"});
          window.scrollTo({top: 100, left: 0, behavior: 'smooth'});
        })
      }
    }
    const handleClose = () =>{
      setShowConfirmModal(false);
    }
   

  return <>
  {loading && <div className={classes.outerContainer}>
    <div className={classes.loadingDiv}>
        <PropagateLoader color="#E84E3B" size={14}/>
    </div>
    </div>}
    <Container fluid className={classes.outerContainer}>
      <h1 className={classes.createTitle}>Create your synKro account</h1>
      <p className={classes.packageDetails}>You’re currently signing up for {selectedPackage.name} at ${selectedPackage.monthlyFee?.toFixed(2)}</p>
      <p className={classes.para1}>To complete your request for internet service, please create an online account</p>
      <SignupProgressBar percentComplete={percentComplete} prevPercentComplete={prevPercentComplete} active="1"/>
      <div className={classes.formContainer}>
        <h3>Confirm Email</h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.errorWarning}>
            {errors.emailId1?.type === "emailExists" && <p>Email Id already exist with us, Please try a different Email Id to proceed further.</p>}
            {errors.emailId2?.type === "emailMismatch" && <p>Email Id doesnot match</p>}    
          </div>
          <Row className="mb-2 mt-4">
              <label>Email Address*</label>
          </Row>
          <Row className="mb-2">
              <input type="email" name="emailId1" placeholder="Enter Email ID" 
              value={emailId} 
              className={`${classes.emailInput} ${errors.emailId1 && classes.inputError}`}
              {...register("emailId1", {required: true})}
              onChange={(e)=>setEmailId(e.target.value)}/>                                      
          </Row>
          <Row className="mb-2 mt-4">
              <label>Confirm Email Address*</label>
          </Row>
          <Row className="mb-2">
              <input type="email" name="emailId2" placeholder="Confirm Email ID" 
              value={confirmEmailId || ""} 
              className={(errors.emailId2?.type=== "required" || errors.emailId2?.type=== "emailMismatch") ? classes.inputError : classes.emailInput}
              {...register("emailId2", {required: true})}
              onChange={(e)=>setConfirmEmailId(e.target.value)}/>                                      
          </Row>
          <h3 className="mt-3 mb-2">Create Password</h3>
          <p>Passwords must comply with our secure password policy.</p>
          {/* <div className={classes.passwordCheckContainer}></div> */}
          <div className={classes.passwordCheckRow}>
            {defaultPwdCheck.validation1 && 
            <DefaultCheckSquare width="16" height="16" color="#212529" /> }
            {(pwdValidations.validation1 ) &&
            <SuccessCheck width="16" height="16" color="#28A745" /> }
            {(!pwdValidations.validation1 && !defaultPwdCheck.validation1) && 
            <ErrorCheck width="16" height="16" color="#DC3545" /> }
            <p>At least 8 characters</p>
          </div>
          <div className={classes.passwordCheckRow}>
            {defaultPwdCheck.validation2 && 
            <DefaultCheckSquare width="16" height="16" color="#212529" /> }
            {pwdValidations.validation2 && 
            <SuccessCheck width="16" height="16" color="#28A745" /> }
            {(!pwdValidations.validation2 && !defaultPwdCheck.validation2) && 
            <ErrorCheck width="16" height="16" color="#DC3545" /> }
            <p>At least one uppercase letter (A-Z)</p>
          </div>
          <div className={classes.passwordCheckRow}>
            {defaultPwdCheck.validation3 && 
            <DefaultCheckSquare width="16" height="16" color="#212529" /> }
            {pwdValidations.validation3 && 
            <SuccessCheck width="16" height="16" color="#28A745" /> }
            {(!pwdValidations.validation3 && !defaultPwdCheck.validation3) && 
            <ErrorCheck width="16" height="16" color="#DC3545" /> }
            <p>At least one lowercase letter (a-z)</p>
          </div>
          <div className={classes.passwordCheckRow}>
            {defaultPwdCheck.validation4 && 
            <DefaultCheckSquare width="16" height="16" color="#212529" /> }
            {pwdValidations.validation4 && 
            <SuccessCheck width="16" height="16" color="#28A745" /> }
            {(!pwdValidations.validation4 && !defaultPwdCheck.validation4) && 
            <ErrorCheck width="16" height="16" color="#DC3545" /> }
            <p>At least one number (0-9)</p>
          </div>
          <div className={classes.passwordCheckRow}>
            {defaultPwdCheck.validation5 && 
            <DefaultCheckSquare width="16" height="16" color="#212529" /> }
            {pwdValidations.validation5 && 
            <SuccessCheck width="16" height="16" color="#28A745" /> }
            {(!pwdValidations.validation5 && !defaultPwdCheck.validation5) && 
            <ErrorCheck width="16" height="16" color="#DC3545" /> }
            <p>At least one special character</p>
          </div>
          <Row className="mb-2 mt-4">
              <label>Create Password*</label>
          </Row>
          <Row className="mb-2">
            <InputGroup className="px-0">
              <input type={passwordShown ? "text" : "password"} name="password1" placeholder="Password" 
                value={userPassword || ""} 
                className={`${classes.passwordInputStyle} ${errors.password1 && classes.inputError} ${passwordAccepted && classes.pwdMatchSuccess}`}
                {...register("password1", {required: true})}
                onChange={pwdInputHandler}
                />  
              <InputGroup.Text id="basic-addon1" className={classes.inputGroupStyle}>
                <div className={classes.passwordToggleButton} onClick={handlePwdView}>      
                {passwordShown ? <EyeIcon width="22" height="24" color="#9EA0A6" /> : 
                  <EyeSlashIcon width="22" height="24" color="#9EA0A6" />}
                </div>
              </InputGroup.Text>
            </InputGroup>
                {(errors?.password1?.type==="pwdCriteriaFailed" && userPassword) && <p className={classes.errorMessage}>Password does not meet requirements</p> }                     
          </Row>
          <Row className="mb-2 mt-4"> 
              <label>Confirm Password*</label>
          </Row>
          <Row className="mb-2">
            <InputGroup className="px-0">
              <input type={confirmPwdShown ? "text" : "password"} name="password2" placeholder="Password" 
              value={confirmPassword || ""} 
              className={`${classes.passwordInputStyle} ${errors.password2 && classes.inputError} ${matchPwd && classes.pwdMatchSuccess}`}
              {...register("password2", {required: true})}
              onChange={confirmPwdHandler}
              /> 
            <InputGroup.Text id="basic-addon1" className={classes.inputGroupStyle}>
              <div className={classes.passwordToggleButton} onClick={handleConfirmPwdView}>      
                {confirmPwdShown ? <EyeIcon width="22" height="24" color="#9EA0A6" /> : 
                  <EyeSlashIcon width="22" height="24" color="#9EA0A6" />}
              </div> 
            </InputGroup.Text>
            </InputGroup>
              {(errors?.password2?.type==="pwdMismatch" && userPassword && confirmPassword) && <p className={classes.errorMessage}>Passwords do not match</p> }                     
                                              
          </Row>
          <div className={classes.buttonContainer}>
            <Button onClick={()=>{setShowConfirmModal(true)}} className={classes.cancelButton}>Cancel</Button>
            <Button type='submit' className={classes.submitButton} disabled={!enableCreateButton}>Create Account</Button>
          </div>
          <ConfirmationModal showModal={showConfirmModal} handleClose={handleClose} />
        </form>
      </div>
    </Container>
    <ContactFooter isDark={false} />
    <Footbar />
  </>

};

export default CreateAccount;
