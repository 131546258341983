import React,{ useState,useEffect, useContext } from "react";
import { PropagateLoader } from "react-spinners";
import CustomButtonRedBg from "../../../Components/CustomButtonRedBg/CustomButtonRedBg";
import userServices from "../../../Services/UserServices";
import classes from "./CustomHomeLayout.module.css";
import ellipseImg from "../../../Assets/Images/CusotmHome_Ellipse\ 1.png"
import ContactFooter from "../../../Components/ContactFooter/ContactFooter";
import Cookies from 'universal-cookie';
import CustomButtonBlueBg from "../../../Components/CustomButtonBlueBg/CustomButtonBlueBg";
import  SignupData  from '../../../Context/PublicUserContext';
import Footbar from "../../../Components/Footbar/Footbar";


const CustomHomeLayout = () => {
  const [selectedLocation, setSelectedLocation] = useState();
  const [locationDataLoading, setLocationDataLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const {customerPartner,setCustomerPartner} = useContext(SignupData);

  const currentUrl = window.location.host;

  useEffect(()=>{ 
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    const cookies = new Cookies();
    const storeCookies = localStorage.getItem("enableCookies");
    const userCookie = cookies.get("user"); 
    if(storeCookies === null){
      setShowModal(true);
    }
    if(storeCookies === "true" && userCookie === undefined){
      cookies.set("user","public",[{path:"/"}]);
    }
  },[])

  useEffect(()=>{
    setLocationDataLoading(true);
    sessionStorage.removeItem("addressDetails");
    sessionStorage.removeItem("selectedPackage");
    sessionStorage.removeItem("customerId");
    userServices.getLocations()
    .then((response)=>{
        let locationSelected = response.data;
// For Dev instance on local -  CHANGETODEPLOY - REMOVE
      // locationSelected = locationSelected.find((item)=>item.city.toLowerCase()==="paris")
        locationSelected = locationSelected.filter((item)=>item.city?.toLowerCase() !== "wink")
        locationSelected = locationSelected.find((item)=>item.multiTenantDomain===currentUrl)
        setSelectedLocation(locationSelected);
        checkCustomPartner(locationSelected);
        setLocationDataLoading(false);
        sessionStorage.setItem("location",JSON.stringify(locationSelected));
    })
    .catch((err)=>{
        setLocationDataLoading(false);
    })
  },[])

    const checkCustomPartner = (location) =>{
      if(location.city.toLowerCase() ==="paris"){
        setCustomerPartner("Lamar Wireless");
      }
      else if(location.city.toLowerCase() ==="colville"){
        setCustomerPartner("Adept Internet Solution");
      }
      else{
        setCustomerPartner(null);
      }
    }

  return <>
  {locationDataLoading? <div className={classes.loaderContainer}>
    <div className={classes.loadingDiv}>
        <PropagateLoader color="#E84E3B" size={14}/>
    </div>
    </div>: <>
    <div className={classes.outerContainer}>
      <div className={classes.innerContainer1}>
        {customerPartner? <div className={classes.customContainer1Div1}>
          <h4>GETTING STARTED</h4>
          <p>with {customerPartner}</p>
        </div> :
        <div className={classes.container1Div1}>
          <h4>GETTING STARTED</h4>
          <p>Broadband Internet Service for {selectedLocation?.city?.toUpperCase()}, {selectedLocation?.state?.toUpperCase()}</p>
        </div>}
      </div>
      <div className={classes.innerContainer2}>
        <p className={classes.para1}>We do what others can't. Just because you live outside of a big city shouldn't 
          mean you have to compromise on your Internet service. We invented superior, patented, cloud technology 
          that brings the full power of the Internet to rural areas.
        </p>
        <p className={`${customerPartner? classes.customPara2: classes.para2}`}>Not in {selectedLocation?.city}? <a href='/select-location'>Find your town.</a></p>
        <div className={classes.signupButtonContainer}>
          <a href="/signup/new">{customerPartner?<CustomButtonBlueBg>Buy Now!</CustomButtonBlueBg>:
          <CustomButtonRedBg >Buy Now!</CustomButtonRedBg>}</a>
        </div>
        <div className={classes.borderedMiddleContainer}>        
          <div className={classes.innerDiv1}>
            <div className={classes.innerRow1}>
              <div className={classes.innerCol1}>
                <img src={ellipseImg} className={classes.ellipseImage} />
                <h6 className={`${customerPartner? classes.customColumnTitle: classes.columnTitle}`}>Unlimited Premium</h6>
                <p>High Capacity </p>
                <p>No data usage limits</p>
              </div>
              <div className={classes.innerCol1}>
                <img src={ellipseImg} className={classes.ellipseImage} />
                <h6 className={`${customerPartner? classes.customColumnTitle: classes.columnTitle}`}>Risk Free</h6>
                <p>No Contract</p>
                <p>No Termination Fee</p>
              </div>
            </div>
            <div className={classes.innerRow1}>
              <div className={classes.innerCol1}>
                <img src={ellipseImg} className={classes.ellipseImage} />
                <h6 className={`${customerPartner? classes.customColumnTitle: classes.columnTitle}`}>APS</h6>
                <p>For optimized Web-App </p>
                <p>performance beyond speed</p>
              </div>
              <div className={classes.innerCol1}>
                <img src={ellipseImg} className={classes.ellipseImage} />
                <h6 className={`${customerPartner? classes.customColumnTitle: classes.columnTitle}`}>Local, On-Site Service</h6>
                <p>Broadband Internet Services</p>
                <p>supported by trained staff living in the area</p>
              </div>
            </div>
          </div>  
        </div>
        <div className={classes.thirdContainer}>
          <div className={classes.innerCol2}>
            <p>Experience excellent performance and use any modern Web app. Our proprietary 5G fixed wireless 
              technology is superior to DSL, satellite, and cellular offerings.
            </p>
          </div>
          <div className={classes.innerCol3}></div>
        </div>
        <div className={classes.bottomContainer}>
          <h5>Dynamic Intelligence with APS</h5>
          <p>Maximize your personalized Internet experience beyond mere speed with our patented Dynamic Intelligence™.</p>
          <div className={classes.optimizeButtonContainer}>
            <a href="/signup/new">{customerPartner ? <CustomButtonBlueBg>Optimize Now!</CustomButtonBlueBg>:
            <CustomButtonRedBg>Optimize Now!</CustomButtonRedBg>}</a>
          </div>
        </div>
      </div>
      <ContactFooter customerPartner={customerPartner} />
      {/* <Modal size="md" centered show={showModal} onHide={handleClose} className="cookieContainer">
        <CookiePopup handleModal={handleModal}/>
      </ Modal> */}
  </div>
  <Footbar />
  </>}
  </>
};

export default CustomHomeLayout;
