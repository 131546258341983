import React, {createContext, useEffect, useState} from "react";

const PublicUserContext = createContext();


export function SignupData({children}){
    const [address, setAddress] = useState();  
    const [ customerPartner, setCustomerPartner] = useState(null);
    let accountData = sessionStorage.getItem("accountData")? JSON.parse(sessionStorage.getItem("accountData")): "";
    const [userFirstName, setUserFirstName] = useState(accountData?.firstName);
    
    useEffect(()=>{
        checkCustomPartner()
    },[]);
    const checkCustomPartner = () =>{
        let location = sessionStorage.getItem("location");
        if(location?.city?.toLowerCase() ==="paris"){
          setCustomerPartner("Lamar Wireless");
        }
        else if(location?.city?.toLowerCase() ==="colville"){
          setCustomerPartner("Adept Internet Solution");
        }
        else{
          setCustomerPartner(null);
        }
      }
    return(
        <PublicUserContext.Provider value={{address, setAddress,userFirstName, setUserFirstName,customerPartner,setCustomerPartner}}>
            {children}</PublicUserContext.Provider>
    );
}

export default PublicUserContext;
