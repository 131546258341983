import axios from 'axios';

const baseUrl = "/api";

const axiosPublicInstance = axios.create({
    baseURL: baseUrl,
    headers: {
        "access-control-allow-origin" : "*"
    }
})

axiosPublicInstance.interceptors.response.use(async (response)=>{
    return response;
},async (error)=>{
  if (error.response) {
      if(error.response.status===500){
        window.location.assign(`${process.env.REACT_APP_PROD_URL}/error`)     
      }
      return Promise.reject(error);
  } else if (error.request) {
      // Handle network errors
      window.location.assign(`${process.env.REACT_APP_PROD_URL}/error`)
      return Promise.reject(error);
    }
  else{
      return Promise.reject(error);
  }
  
})


const getStateFromZip = (data) =>{
    return axiosPublicInstance.get(`/Validation/FromZip/${data}`)
};

const validateAddress = (data) =>{
    return axiosPublicInstance.get(`/Validation/ValidateAddress`, { params: data })
}

const selectPackages = (id) =>{
    return axiosPublicInstance.get(`/Packages/${id}`)    
}

const getLocations = () =>{
    return axiosPublicInstance.get(`/Location`)
}

const newCircularServiceLocation = (data) =>{
    return axiosPublicInstance.post(`/Location/AddCircularLocation`, data)
}

const createAnnouncement = (data) =>{
    return axiosPublicInstance.post(`/Announcements`, data)
}

const getAnnouncements = (id) =>{
    return axiosPublicInstance.get(`/Announcements `)    
}

const getAllAnnouncements = () =>{
    return axiosPublicInstance.get(`/Announcements/Announcements`)
}

const deleteAnnouncement = (id)=>{
    return axiosPublicInstance.delete(`/Announcements/${id}`)
}

const submitContactDetails = (data) =>{
    return axiosPublicInstance.post(`/Contact`, data)
}

const createAccount = (data) => {
    return axiosPublicInstance.post(`/Account`, data)
}

const checkEmailId = (ispId,emailId) => {
    return axiosPublicInstance.get(`/Account/${ispId}/${emailId}`)
}

const verifyLocation = (ispId, data) =>{
    return axiosPublicInstance.get(`/Service/VerifyRegion/${ispId}`, { params: data })
}

const services = {
    getStateFromZip,
    validateAddress,
    selectPackages,
    getAnnouncements,
    newCircularServiceLocation,
    createAnnouncement,
    getLocations,
    getAllAnnouncements,
    deleteAnnouncement,
    submitContactDetails,
    createAccount,
    checkEmailId,
    verifyLocation
}

export default services;
