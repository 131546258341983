import React from "react";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "./TooltipComponent.css";


export default function TooltipComponent({ id, children, message }) {
  return (
    <div className={`${id === "tool-tip-1" ? "toolTipContainer1" : "toolTipContainer2"}`}>
        <OverlayTrigger
        overlay={<Tooltip id={id}>{message}</Tooltip>}
        placement="right"
        //   delayShow={300}
        //   delayHide={150}
        >
        {children}
        </OverlayTrigger>
    </div>
  );
}