

export const convertISOtoDDMMYYYY = (receivedDate) => {
    if (receivedDate){
        let formattedDate = new Date(receivedDate);
        let date=formattedDate.getDate();
        let month = formattedDate.getMonth()+1
        if (date < 10) {
            date = '0' + date;
          }
          if (month < 10) {
            month = '0' + month;
          }
        formattedDate= date +'-' + month + '-' + formattedDate.getFullYear();
        return formattedDate;
    } 
    else{
        return null        
    }
    
}
export const luhnCheck = num => {
  let arr = (num + '')
    .split(" ")
    .join("")
    .split('')
    .reverse()
    .map(x => parseInt(x));
  let lastDigit = arr.splice(0, 1)[0];
  let sum = arr.reduce(
    (acc, val, i) => (i % 2 !== 0 ? acc + val : acc + ((val *= 2) > 9 ? val - 9 : val)),
    0
  );
  sum += lastDigit;
  return sum % 10 === 0;
};

export const expiryDateCheck = date => {
    let today, someday;
    let exMonth= (date+'').split('').splice(0,2).join('');
    let exYear=(date).replace(/(.*)(\d{2})/, "$2");
    exYear="20"+exYear
    today = new Date();
    someday = new Date();
    if(parseInt(exMonth)<=12){
      someday.setFullYear(parseInt(exYear), parseInt(exMonth), 1);
      if (someday >= today) {
        return true;
      }
      else{
        return false;
      }
    }
    else{
      return false;
    }
}

export const isValidRoutingNumber = (n) => {
	if (n.length !== 9) {
		return false;
	}
	else {
  let digits = n.split("");
	let sum = 3 * digits[0] + 3 * digits[3] + 3 * digits[6] + 
            7 * digits[1] + 7 * digits[4] + 7 * digits[7] + 
            1 * digits[2] + 1 * digits[5]+ 1 * digits[8];		  
	return sum % 10 === 0;
  }
}

export const maskedNumbers = (n) =>{
  let maskedNumber = n.split(" ").join("")
  maskedNumber = maskedNumber.replace(/\b[\dX][-. \dX]+(\d{4})\b/g, function(match, capture) {
      return Array(match.length-3).join("X") + capture;
    });
  return maskedNumber;
}

export const convertToTitleCase = (sentence) => {
  let words = sentence?.split(" ");
  let formattedSentence
  if(words?.length>0){
    formattedSentence = words.map((word) => { 
      return word.charAt(0).toUpperCase()+ word.slice(1).toLowerCase()
     });
  }
  return formattedSentence?.join(" ")
  ;
}

export const getLastNdigits =(number, n) => {
  return Number(String(number).slice(-n));
}

export const extractNumber = (number) => {
  return number.replace(/\D/g,"");
}

export const convertDatetoDDMMMYYYY = (receivedDate) => {
  if (receivedDate){
    const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    let formattedDate = new Date(receivedDate);
    const day = formattedDate.getDate().toString().padStart(2, "0");
    const month = months[formattedDate.getMonth()];
    const year = formattedDate.getFullYear();

  return `${day}-${month}-${year}`;
  }
  return receivedDate;

}

export const checkValidDate = (dateString) => {
  let d = new Date(dateString);
  return !isNaN(d.getTime());
}