import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./ConfirmationModal.css";

const ConfirmationModal = (props) => {
    
    const navigate = useNavigate();

    const handleLinkRedirection = (path) =>{
        if(process.env.NODE_ENV==="development"){
          navigate(`${path}`)
          }
        else if(process.env.NODE_ENV==="production"){
          if(window.location.href === process.env.REACT_APP_PROD_URL){
            navigate(`${path}`)
          }
          else{
            window.location.assign(`${process.env.REACT_APP_PROD_URL}${path}`)
          }
        }
      }

    return <div className="confirmationModalDiv">
        <Modal size="md" centered show={props.showModal } onHide={props.handleClose} className="confirmModalStyles">
            <Modal.Body>
            Cancelling now will lose all previous selections and information.  Do you want to cancel?
            <div className="confirmModalButtonDiv">
                <Button className="confirmationNoButton" onClick={props.handleClose}>No, Go Back </Button>
                <Button onClick={()=>handleLinkRedirection("/home")} className="confirmationYesButton">Yes, Cancel</Button>        
            </div>
            </Modal.Body>
        </ Modal>

    </div>;
};

export default ConfirmationModal;
