import React from "react";
import "./CustomRadioButton.css";

const CustomRadioButton = (props) => {
    
  return <div className={`${props.customerPartner? "customRadioContainerCP": "customRadioContainer"} ${props.alignStyle==="row"? "justifyRow":""}`}>
    {props.items?.map((element)=>{
        return <div key={element} className={props.alignStyle==="row"?"me-2":""}>
          <input type="radio" id={element} value={element} name={props.name} onChange={props.handleChange} checked={props.checked === element}/>
          <label htmlFor={element} key={element}>{element}</label><br/>
        </div>
    })}
  </div>;
};

export default CustomRadioButton;
