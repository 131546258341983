import React, {useEffect} from "react";
import classes from "./WifiExtenders.module.css";
import CustomAccordion from "../../../Components/CustomAccordion/CustomAccordion";
import outdoorExtender from "../../../Assets/Images/wifi-extender-img2.jpg"
import indoorExtender1 from "../../../Assets/Images/wifi-extender-img3.png"
import indoorExtender2 from "../../../Assets/Images/wifi-extender-img4.png"
import Footbar from "../../../Components/Footbar/Footbar";

const WifiExtenders = () => {
    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);
      let data = [
      {title: "1. Select your desired Wi-Fi extender", 
       content: "Select a synKro-recommended Wi-Fi range extender depending on if you’re extending your current home Wi-Fi network indoors or outdoors." },
       {title: "2. Buy it", 
       content: "Tap “Buy it” to complete your purchase using our conveniant direct link. Note: Be sure to have it shipped to the address of your installation." },
       {title: "3. Call us for installation", 
       content: "Once you recieve your Wi-Fi extender, call synKro customer care at (844) 883-1659 to arrange your installation.",
       para2:"One-time installation charges vary from $124.99 to $199.99 depending on complexity of work. Installations are charged to your synKro MyAccount after successful completion of on-site work."
    },

      ]
  return(<>
  <div className={classes.outerContainer}>
    <div className={classes.topContainer}>
        <h1>Wi-Fi Extenders</h1>
        <h5>Does your home or business Wi-Fi network need additional 
            coverage indoors or outdoors? A Wi-Fi range extender might be just what you need. </h5>
    </div>
    <div className={classes.container2}>
        <div className={classes.container2Inner}>
            <h1>synKro makes it easy.</h1>
            {/* <Accordion>
            <Accordion.Item eventKey="0">
                <Accordion.Header>Usage</Accordion.Header>
                <Accordion.Body>
                    <h6>What if I need a customized broadband solution for my business?</h6>
                    <p>The synKro service plan offers a broadband Internet plan suitable for small to medium sized 
                        businesses who depend on reliable and consistent Internet service. Please call 844-883-1659 
                        for more information.
                    </p>
                </Accordion.Body>
            </Accordion.Item>
            </Accordion> */}
            <CustomAccordion data={data} />
      </div>
    </div>
    <div  className={classes.container3}>
        <h1>Select your desired Wi-Fi extender</h1>
        <div className={classes.container3Inner}>
            <div className={classes.container3Col}>
                <img src={outdoorExtender} />
                <div className={classes.container3InnerCol}>
                    <h4>Outdoor Extender</h4>
                    <h6><strong>WAVLINK AC1200 Extender</strong></h6>
                    <ul>
                        <li>synKro-compatible</li>
                        <li>High power performance in long-distance</li>
                        <li>Supports all synKro service plans</li>
                        <li>Weater-Proof</li>
                        <li>Build in lightning protection</li>
                    </ul>
                </div>
                <div className={classes.buyitButton}>
                    <a target="_blank" href="https://www.amazon.com/WAVLINK-Weatherproof-WiFi-Extender-Detachable-Directional/dp/B07X3KXK7Z" rel="nofollow noopener">Buy it</a>
                </div>
            </div>
            <div className={classes.container3Col}>
                <img src={indoorExtender1} />
                <div className={classes.container3InnerCol}>
                <h4>Indoor Extender</h4>
                <h6>eero Extender (1-Pack)</h6>
                <ul>
                    <li>synKro-compatible</li>
                    <li>Perfect for regular homes</li>
                    <li>Dual-band Wi-Fi 5</li>
                    <li>Enough bandwidth for 50+ connected devices and covers up to 1,500 sq.ft.</li>
                </ul>
                </div>
                <div className={classes.buyitButton}>
                    <a target="_blank" href="https://eero.com/shop/eero" rel="nofollow noopener">Buy it</a>
                </div>
            </div>
            <div className={classes.container3Col}>
                <img src={indoorExtender2} />
                <div className={classes.container3InnerCol}>
                <h4>Indoor Extender</h4>
                <h6>eero Extender (3-Pack)</h6>
                <ul>
                    <li>synKro-compatible</li>
                    <li>Perfect for regular homes</li>
                    <li>Dual-band Wi-Fi 5</li>
                    <li>Enough bandwidth for 50+ connected devices and covers up to 1,500 sq.ft.</li>
                </ul>
                </div>
                <div className={classes.buyitButton}>
                    <a target="_blank" href="https://eero.com/shop/eero" rel="nofollow noopener">Buy it</a>
                </div>
            </div>

        </div>
    </div>
    <div className={classes.bottomContainer}>
        <div className={classes.bottomInnerContainer}>
        <h1>What people are saying</h1>
        <p>“I purchased this [Outdoor Extender] to extend my wifi to cover my property. 
            It works great as an AP and reaches all the way to the end of my driveway (~550 ft)..”
        </p>
        <span>— JB</span>
        </div>
    </div>
  </div>
  <Footbar />
  </>
    )
};

export default WifiExtenders;
