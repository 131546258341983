import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import Footbar from "../../../Components/Footbar/Footbar";
import classes from './PrivacyPolicy.module.css';

const PrivacyPolicy = () => {
    useEffect(()=>{
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    },[]);
  return <><Container fluid className={classes.mainContainer}>
    <h1>Privacy policy.</h1>
    <p>This privacy policy discloses our privacy practices and how we utilize the information you share with us here at synKro.</p>
    <p>We are the sole owners of the information collected from you. We only have access to/collect information that you voluntarily give us via email or other direct contact from you. We will not sell or rent this information to anyone.</p> 
    <p>We will use your information to respond to you regarding the reason you contacted us. We will not share your information with any third party outside of our organization without your prior approval.</p>
    <p>Unless you ask us not to, we may contact you via email in the future to tell you about specials, new products or services, or changes to this privacy policy.</p>
    <p>If you feel that we are not abiding by this privacy policy, or have any questions or comments regarding our privacy policy, please contact us by telephone at (844) 883-1659  or by email at info@snyko.us.</p>
  </Container>
  <Footbar />
  </>;
};

export default PrivacyPolicy;
