import React, {useEffect, useState} from 'react';
import {Container, Col} from 'react-bootstrap';
import classes from './AlertBar.module.css';
import Close_icon from '../../Assets/Images/close-icon.png';
import UserServices from "../../Services/UserServices";
import "./AlertBar.css";

const AlertBar = () => {
  const [alertData, setAlertData] = useState();
  const [showAlertBar, setShowAlertBar] = useState();
  let showAnnouncement = localStorage.getItem('showAnnouncement')?JSON.parse(localStorage.getItem('showAnnouncement')):true;
  let announcementId= localStorage.getItem('announcementId')? JSON.parse(localStorage.getItem('announcementId')):"";


  useEffect(()=>{
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    UserServices.getAnnouncements()
    .then((resp)=>{
      if(resp.status===200){
        setAlertData(resp.data);
        if(announcementId !== resp.data?.id){
          localStorage.setItem("announcementId",JSON.stringify(resp.data.id));
          setShowAlertBar(true);
          localStorage.setItem("showAnnouncement","true");
        }
        else if(!showAnnouncement){
          setShowAlertBar(false);
        }
        else{
          setShowAlertBar(true);
        }
      }
      else{
        setAlertData(null);
      }
    })
  },[])
  const handleClose = ()=>{
    localStorage.setItem("showAnnouncement", "false");
    setShowAlertBar(false);
}
  return (
    <>
    {showAlertBar && 
    <Container fluid className="px-0">
        <div className={classes.alertbar}>
            <Col md={11} className={classes.alertbarContent}><a href={alertData?.announcementURL} >{alertData?.announcement}</a></Col>
            <Col md={1} className={classes.closeIconCol}>
              <img src={Close_icon} alt='cross' className={classes.closeIcon} onClick={handleClose}/></Col>
        </div>
    </Container>
    }
    </>
  )
}

export default AlertBar