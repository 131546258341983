import React from "react";
import { Button } from "react-bootstrap";
import classes from "./CustomButtonDarkBg.module.css";

const CustomButtonDarkBg = (props) => {
  return (
    <Button 
        className={classes.buttonStyle} 
        onClick={props.handleClick}>
        {props.buttonText}
    </Button>
    );
};

export default CustomButtonDarkBg;
