import React from 'react';

export const SidebarData = [
  {
    id:1,
    title: 'Account Information',
    path: '/account-info',
    subNav: [
      {
        id: 1.1,
        title: 'My Account Details',
        path: '/account-info/details'
      },
      {
        id: 1.2,
        title: 'Update Password',
        path: '/account-info/update-pwd',
      }
    ]
  },
  {
    id:2,
    title: 'Billing Center',
    path: '/billing',
    subNav: [
      // {
      //   id:2.1,
      //   title: 'Pay Bill',
      //   path: '/billing/pay',
      //   cName: 'sub-nav'
      // },
      {
        id:2.2,
        title: 'Payment Method',
        path: '/billing/method',
        cName: 'sub-nav'
      },
    ]
  },
  {
    id:3,
    title: 'Service Center',
    path: '/service',
    subNav: [
        {
          id:3.1,
          title: 'My synKro Packages',
          path: '/service/packages',
          cName: 'sub-nav'
        },
        {
          id:3.2,
          title: 'Update Package',
          path: '/service/update-package',
          cName: 'sub-nav'
        },
      ]
  },
  {
    id:4,
    title: 'Statement Center',
    path: '/account/statement',
  },
  {
    id:5,
    title: 'Contact Us',
    path: '/account/contact',
  }
];
