import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import Footbar from '../../../Components/Footbar/Footbar';
import classes from './TRDLayout.module.css'

const TRDLayout = () => {
    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);
  return (<>
    <Container className={classes.trdLayoutContainer}>
        <h3 className={classes.trdTitle}>COMPLIANCE STATEMENT AND DISCLOSURES FOR FCC "TRANSPARENCY RULE"</h3>
        <p>LocaLoop, Inc. (the "Company") provides this information in its good faith effort to comply with 
            the disclosures required by the Federal Communications Commission ("FCC") revised "transparency" 
            rules effective on June 11, 2018. With these disclosures we provide our subscribers with the 
            information necessary for them to make informed choices about the purchase and use of the Company's 
            wireless broadband Internet services (the "Company's Services").
        </p>
        <p>For your information, our summary1 of the FCC Transparency Rule states as follows: </p>
        <p className={classes.bulletPoints}>(a) Any person providing broadband Internet access service shall publicly disclose accurate 
            information regarding the network management practices, performance characteristics, and commercial 
            terms of its broadband Internet access services sufficient to enable subscribers to make informed 
            choices regarding the purchase and use of such services and entrepreneurs and other small businesses 
            to develop, market, and maintain Internet offerings. Such disclosure shall be made via a publicly 
            available, easily accessible website or through transmittal to the Commission.
        </p>
        <p className={classes.bulletPoints}>(b) Broadband internet access service is a mass-market retail 
            service by wire or radio that provides the capability to transmit data to and receive data from all 
            or substantially all Internet endpoints, including any capabilities that are incidental to and enable 
            the operation of the communications service, but excluding dial-up Internet access service.
        </p>
        <p className={classes.bulletPoints}>(c) A network management practice is reasonable if it is appropriate 
            and tailored to achieving a legitimate network management purpose, taking into account the particular 
            network architecture and technology of the broadband Internet access service.
        </p>
        <p>The Company reserves the right to update and modify these disclosures and our underlying policies, 
            in a manner consistent with the FCC's directives, including, but not limited to, the Company's terms 
            and conditions, network management practices and performance characteristics. To the extent such 
            changes are made they will be reflected in the links provided below.
        </p>
        <b><i>Commercial Terms</i></b>

        <p>The rates, terms and conditions associated with the Company's Service, including by way of example 
            only, early termination and/or additional network service fees, can be accessed via the 
            following links:
        </p>
        <p>Service Descriptions and Rates can be found here:</p>
        <p className={classes.bulletPoints}>
            <Link  to="/select-location"><span className={classes.bulletLinks}>Services</span></Link>
        </p>
        <p>Subscriber Agreement, Terms and Conditions and Privacy Policy can be found here:</p>
        <p className={classes.bulletPoints}>
            <Link  to="/terms"><span className={classes.bulletLinks}>Terms & Conditions</span></Link>
        </p>
        <p>When changes are made to the rates, terms and conditions of the Company's Service, we will post 
            them on our website using the links referenced above.
        </p>
        <b><i>How to contact us:</i></b>
        <p>If you believe that the Company's Service is not meeting the rates, terms and conditions applicable 
            to what you have ordered, please feel free to contact us so that we can address your concern:
        </p>
        <p>Via Telephone - 844-887-1659</p>
        <p>Via Email - info@synkro.us</p>
        <p>Via Mail - LocaLoop, Inc. 3280 Gorham Ave, Minneapols, MN 55426</p>
        <p>We strongly encourage you to contact us in order to discuss your concerns. If, based on those 
            discussions, there is some remedial action necessary we can work together to implement it.
        </p>
        <b><i>Network Management Practices</i></b>
        <p>In offering the Company's Service, the Company recognizes that, at times, network issues will arise 
            and, during those times, the Company will undertake actions that are appropriate and tailored to 
            achieving a legitimate network management purpose. The Company notes specifically the following 
            network management practices described in the following link:
        </p>
        <p className={classes.bulletPoints}>
            <Link  to="/faq"><span className={classes.bulletLinks}>FAQ "General" tab.</span></Link>
        </p>
        <p>Please note that each of the statements is subject to the Company's "permitted use" policy. These 
            policies may also be bypassed or otherwise changed upon notification to us by lawful authorities via 
            subpoena or court order. Such orders could mandate action specific to one or more subscriber(s) 
            which requires us to take action not in compliance with the items below.
        </p>
        <ol>
            <li><i>Blocking.</i> The Company does not block or otherwise prevent end user customer access to lawful 
                content, applications, services, or non-harmful devices.
            </li>
            <li><i>Throttling.</i> Except where network congestion may occur, the Company strives to avoid any 
                degradation or impairment of access to lawful Internet traffic on the basis of content, 
                application, service, user, or use of a non-harmful device. Where service congestion occurs, 
                the Company Service's speed may be temporarily reduced. See "Congestion Management", below.
            </li>
            <li><i>Affiliated Prioritization.</i> The Company does not engage in any practice that directly or 
                indirectly favors any of its affiliates' traffic over other traffic.
            </li>
            <li><i>Paid Prioritization.</i> The Company does not engage in any practice that directly or indirectly 
                favors some traffic over other traffic in exchange for consideration, monetary or otherwise 
                from the sources of that traffic. However, our subscribers can choose to pay for higher nominal 
                data rate plans as described in our Services description or can choose to subscribe to 
                Accelerated Performance Services<sup>TM</sup> (APS) which facilitate the network's handling of real time 
                data subject to the availability of network capacity. Note that APS does not provide the 
                subscriber a "way around" congestion control during peak use periods.
            </li>
            <li><i>Congestion Management.</i> Due to the nature of wireless services, service congestion may occur 
                during peak hours. The network itself will minimize the impact on all subscribers through its 
                built-in congestion controls which impact all subscribers on a non-discriminatory basis. 
                In layman's terms, when things get busy, everyone has to get in line and is treated equally; 
                there are no 'express lanes' for some subscribers and not others. The Company's congestion 
                management policies are described in its Network Management Policy available in the 
                <Link  to="/faq"><span className={classes.bulletLinks}>FAQ</span></Link>.
            </li>
            <li><i>Application-Specific Behavior.</i> The Company does not:</li>
            a. block or rate-control specific protocols or protocol ports;<br/>
            b. modify protocol fields in ways not prescribed by the protocol standard; or,<br/>
            c. otherwise inhibit or favor certain applications or classes of applications.<br/>
            <li><i>Device Attachment Rules.</i> Provided that an attachment does not cause network harm, including but 
                not limited to interference with the Company's network security measures, the Company does not 
                restrict the types of devices that its subscribers may use and attach to the Company's network 
                nor does it have any approval procedures for devices to connect to the Company's network. 
                Notwithstanding the above, the Company may choose to require that certain types and brands of 
                ancillary equipment, for example Company-managed residential wireless routers, be used by its 
                subscribers as part of its services. Those devices, through the technology built into the device, 
                may allow for more efficient and effective services within the home. You may choose to have any 
                device connected to a managed Wi-Fi device.
            </li>
            <li><i>Security.</i> We monitor the operability and security of our network. Subscribers are encouraged to 
                be responsible and seek out ways to protect their own home and business networks. Such measures 
                include, but are not limited to, firewalls within a wireless router, not attempting to defeat 
                the security of a managed Wi-Fi service for unauthorized use, PC virus protection, etc. 
                If a subscriber is unlawfully compromising the integrity of our network, they will be notified 
                and directed to discontinue such practices at once or face immediate disconnection. We are happy 
                to work with end-users on legitimate special applications or other functions that require us to 
                create specific policies which enhance our normal security measures.
            </li>
        </ol>
        <b><i>Performance</i></b>
        <p><i>Consumer Services</i></p>
        <ol>
        <li><i>Service Description.</i> The descriptions of the Company's Services, including (1) the service 
            technology; (2) expected and actual access speed and latency; and (3) the suitability of the 
            service for real-time applications, can be accessed via the following links:
        </li>
        <p>- <Link  to="/select-location"><span className={classes.bulletLinks}>Services</span></Link> for general information and <br />
            - <Link  to="/faq"><span className={classes.bulletLinks}>FAQ</span></Link> for specific information under the "Coverage and Equipment" tab.
        </p>
        <li><i>Impact of Non-Broadband Internet Access Service Data Services.</i><br/>
            The Company's Service offering does not currently include any non-broadband Internet access service 
            data services.
        </li>
        </ol>
        <sup>1</sup>The full text of the FCC Rules can be found in &nbsp;
         <a href="https://www.gpo.gov/fdsys/pkg/FR-2018-02-22">Federal Register / Vol. 83, No. 36 / Thursday, 
        February 22, 2018</a>
    </Container>
    <Footbar />
    </>
  )
}

export default TRDLayout