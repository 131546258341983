import React, {useState, useEffect} from "react";
import { Button } from "react-bootstrap";
import ConfirmationModal from "../../../Components/ConfirmationModal/ConfirmationModal";
import ContactFooter from "../../../Components/ContactFooter/ContactFooter";
import CustomRadioButton from "../../../Components/CustomRadioButton/CustomRadioButton";
import Footbar from "../../../Components/Footbar/Footbar";
import SignupProgressBar from "../../../Components/SignupProgressBar/SignupProgressBar";
import ACHPayment from "./ACHPayment/ACHPayment";
import CardPayment from "./CardPayment/CardPayment";
import classes from "./PaymentsLayout.module.css";

const PaymentsLayout = () => {
  let percentComplete = 53;
  let prevPercentComplete = 30;
  const [selectedPackage] = useState(sessionStorage.getItem("selectedPackage") ?JSON.parse(sessionStorage.getItem("selectedPackage")):"");
  const [ccBillingDetails] = useState(sessionStorage.getItem("ccBillingDetails")? JSON.parse(sessionStorage.getItem("ccBillingDetails")): "" );
  const [achPaymentDetails] = useState(sessionStorage.getItem("achPaymentDetails")? JSON.parse(sessionStorage.getItem("achPaymentDetails")): "" );
  const [radioSelected, setRadioSelected] = useState();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const radioItems =["Card Payment (Credit)",
  // "Direct Debit (ACH)"
  ];

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    if(ccBillingDetails && Object.keys(ccBillingDetails).length > 0){
      setRadioSelected("Card Payment (Credit)");
    }
    else if(achPaymentDetails?.routingNumber){
      setRadioSelected("Direct Debit (ACH)")
    }
    }, []);

  const handleChange =(e)=>{
    setRadioSelected(e.target.value);
  }
  
  const handleClose = () =>{
    setShowConfirmModal(false);
  }
  return <>
  <div className={classes.outerContainer}>
      <h1 className={classes.createTitle}>Enter Billing Information</h1>
      <p className={classes.packageDetails}>You’re currently signing up for {selectedPackage?.name} at ${selectedPackage.monthlyFee?.toFixed(2)}</p>
      <p className={classes.para2}>Please provide your preferred payment method for your monthly recurring payments.</p>
      <SignupProgressBar percentComplete={percentComplete} prevPercentComplete={prevPercentComplete} active="3"/>
      <div className={classes.selectPaymentDiv}>
        <h6>Payment Method</h6>
        <CustomRadioButton items={radioItems} 
          name="paymentMethod" 
          handleChange={handleChange} 
          checked={radioSelected}
          customerPartner={false}/>
      </div>
      <div className={classes.paymentDetailsDiv}>
      {radioSelected === "Card Payment (Credit)" && <CardPayment/>}
      {radioSelected === "Direct Debit (ACH)" && <ACHPayment />}
      </div>
      {!radioSelected && (
        <div className={classes.buttonContainer}>
          <Button onClick={()=>{setShowConfirmModal(true)}} className={classes.cancelButton}>Cancel</Button>
          <Button className={classes.submitButton} disabled>Next</Button>
        </div>
      )}
      <ConfirmationModal showModal={showConfirmModal} handleClose={handleClose} />
    </div>
    <ContactFooter isDark={false} />
    <Footbar />
    </>
  };

export default PaymentsLayout;
