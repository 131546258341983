import React, { useState, useEffect, useContext } from "react";
import { Button, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AlertBar from "../../../Components/AlertBar/AlertBar";
import CustomerAccountNavBar from "../../../Components/CustomerAccountNavBar/CustomerAccountNavBar";
import ForgotPwdModal from "../../../Components/ForgotPwdModal/ForgotPwdModal";
import UserHomeData from "../../../Context/UserAccountContext";
import LoginServices from "../../../Services/LoginServices";
import { generateCodeVerifier, getCodeChallenge } from "../../../Utils/CodeChallengeGenerator";
import classes from "./CustomerLogin.module.css";
import { PropagateLoader } from "react-spinners";
import EyeIcon from "../../../Assets/SVGIcons/EyeIcon";
import EyeSlashIcon from "../../../Assets/SVGIcons/EyeSlashIcon";
import UserServices from "../../../Services/UserServices";

const CustomerLogin = () => { 
  const navigate = useNavigate();
  const { setUserAuthToken, setUserRefreshToken, setAccountStatus, setUserAccountInfo} = useContext(UserHomeData);
  const {  register, handleSubmit,reset, setError, clearErrors,formState: { errors }} = useForm();
  const [showForgotPwdModal, setShowForgotPwdModal] = useState(false);
  const [showForgotUnameModal, setShowForgotUnameModal] = useState(false);
  const [userName, setUserName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [errorMessage,setErrorMessage] = useState("");
  const [signinClicked, setSigninClicked] = useState(false);
  const [buttonClicked, setButtonClicked]= useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [userNameError, setUserNameError] = useState("");
  const [forgetPwdError, setForgetPwdError] = useState("");
  const [locations, setLocations] = useState("");

    useEffect(()=>{
        getLocations();
        sessionStorage.removeItem("accessToken");
        sessionStorage.removeItem("refreshToken");
        sessionStorage.removeItem("loggedUserInfo");
        sessionStorage.removeItem("accountStatus");
        setUserAccountInfo(null);
    },[])

    const getLocations = () => {
        UserServices.getLocations()
        .then((response)=>{
            setLocations(response.data);
        })
    }

    const handleLogin = async (data) => {
        setErrorMessage("");
        setSigninClicked(true);
        let codeVerifier = generateCodeVerifier();
        let codeChallenge = await getCodeChallenge(codeVerifier);
        let loginData = {
            Username:data.userName,
            Password:data.password,
            ClientId:process.env.REACT_APP_USER_CLIENT_ID,
            RedirectUri:`${window.location.origin}/account-info`,
            CodeChallenge:codeChallenge,
            CodeChallengeMethod:"S256",
            Scope:"openid urn:localoop:account.read urn:localoop:account.write"
        }
       
        LoginServices.customerLogin(loginData)
        .then((resp)=>{
            getAuthCode(resp.data.code,codeVerifier);
        })
        .catch((err)=>{
            setErrorMessage(err.response.data.localizedErrorMessage)
            setSigninClicked(false);
        })
    }

    const getAuthCode = (code, codeVerifier) =>{
        let data = {
            grant_type:"authorization_code",
            code: code,
            redirect_uri:`${window.location.origin}/account-info`,
            code_verifier:codeVerifier,
          }
          let username=process.env.REACT_APP_USER_CLIENT_ID;
          let password = process.env.REACT_APP_USER_CLIENT_SECRET;
          LoginServices.getAuthToken(data,username,password)
          .then((resp)=>{
            setUserAuthToken(resp?.data?.access_token);
            setUserRefreshToken(resp?.data?.refresh_token);
            sessionStorage.setItem("accessToken",resp?.data?.access_token);
            sessionStorage.setItem("refreshToken",resp?.data?.refresh_token);
            LoginServices.getUserInfo()
            .then((response)=>{
                let customerLocation = locations.find((item)=>item.id===response?.data.ispId);
                let userInfo = response?.data;
                userInfo.locationDetails =customerLocation;
                setUserAccountInfo(userInfo);
                getAccountStatus();
                sessionStorage.setItem("loggedUserInfo",JSON.stringify(userInfo));
            })
            .catch((err)=>{
                setSigninClicked(false);
            })
          })
          .catch((err)=>{
            setSigninClicked(false);
          })
    }

    const getAccountStatus = () =>{
        
        LoginServices.getAccountStatus()
        .then((resp)=>{
            setAccountStatus(resp.data);
            sessionStorage.setItem("accountStatus",JSON.stringify(resp.data));
            setSigninClicked(false);
            navigate("/account-info")
        })
        .catch((err)=>{
            setSigninClicked(false);
        })
    }

    const handleForgotUnameSubmit = ()=>{
        const validEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        let data = {
            Email: emailId
        }
        if(emailId && emailId.match(validEmail)){
            setUserNameError("");
            setButtonClicked(true);
            LoginServices.forgetUsername(data)
            .then((resp)=>{
                setButtonClicked(false);
                setEmailId("");
                setShowForgotUnameModal(false);
            })
            .catch((err)=>{
                setButtonClicked(false);
                if(err.response.status===404){
                    setUserNameError("Couldnot find the email address. Please try with a different email address")
                }
                else{
                    setUserNameError("There was an error getting the username. Please try again")
                }
            })
        } 
        else{
            setUserNameError("Please enter a vaild email id to proceed");
        }       
    }
    const handleForgotPwdSubmit = ()=>{
        let data = {
            Username: userName
        }
        if(userName){
            setButtonClicked(true);
            LoginServices.forgetPassword(data)
            .then((resp)=>{
                setButtonClicked(false);
                setUserName("");
                setShowForgotPwdModal(false);
            })
            .catch((err)=>{
                setButtonClicked(false);
            if(err.response?.data?.localizedErrorMessage){
                setForgetPwdError(err.response.data.localizedErrorMessage)
            }
            else{
                setForgetPwdError("There was an error submitting the request, Please try again.")
            }   
            })        }      
        else{
            setForgetPwdError("Please enter a valid user name or email id to proceed");
        }  
    }
    
    const handleClose = ()=>{
        setShowForgotPwdModal(false);
        setShowForgotUnameModal(false);
        setForgetPwdError("");
        setUserNameError("");
        setEmailId("");
        setUserName("");
    }
    const handleEmailChange = (e) => {
        let { value } = e.target;
        const validEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        setEmailId(value);
        if(value.match(validEmail)){
            setUserNameError("");
        }
      }
    

  return <>
  <AlertBar />
  <CustomerAccountNavBar />
  <div className={classes.outerContainer}>
    <div className={classes.loginContainer}>
        <div className={classes.loginHeader}>
            <h6>Login</h6>
        </div>
        <form onSubmit={handleSubmit(handleLogin)}>
        <p className={classes.inputLabels}>Username</p>
        <input type="text" name="userName" 
            placeholder="Username"
            className={classes.inputStyle}
            {...register("userName", {required: true})}
        />
        <p className={classes.inputLabels}>Password</p>
        <InputGroup>
        <input type={passwordShown ? "text" : "password"} 
            name="password" placeholder="Password"
            className={classes.passwordInputStyle}
            {...register("password", {required: true})}
        />
        <InputGroup.Text id="basic-addon1" className={classes.inputGroupStyle}>
        <div className={classes.passwordToggleButton} onClick={()=>{setPasswordShown(!passwordShown)}}>
        {passwordShown ? <EyeIcon width="22" height="24" color="#9EA0A6" /> : 
            <EyeSlashIcon width="22" height="24" color="#9EA0A6" />}
        </div>
        </InputGroup.Text>
        </InputGroup>
        {/* <img src={passwordShown ? viewPassword : hidePassword} 
          className={classes.passwordToggleButton} onClick={()=>{setPasswordShown(!passwordShown)}}/> */}
        {errorMessage.length>0 && <p className={classes.errorMessage}>{errorMessage}</p>}
        <div className={classes.buttonContainer}>
            <Button type='submit' className={classes.loginButton} disabled={signinClicked}>Sign in</Button>
            <p onClick={()=>setShowForgotPwdModal(true)} className={classes.forgotPwdLink}>Forgot password?</p>
        </div>
        <p onClick={()=>setShowForgotUnameModal(true)} className={classes.forgotUsernameLink}>I can’t remember my username</p>
        </form>
    </div>
    <ForgotPwdModal showModal={showForgotUnameModal} handleClose={handleClose}>
        <div className={classes.modalBody}>
            <h5>Forgot Username?</h5>
            <p>Please enter the email address registered to your account in the box below and we will email 
                you your username.
            </p>
            <p>Alternatively, you can contact us at info@synkro.us or (952) 236-7618 to lookup the 
                information for you.
            </p>
            <input type="email" value={emailId} 
                placeholder="Email" 
                className={`${userNameError ? classes.inputError : classes.inputStyle}`}
                onChange={handleEmailChange}/>
            {userNameError && <p className={classes.errorMessage}>{userNameError}</p>}
            <div className={classes.modalButtonContianer}>
                <Button onClick={handleClose} className={classes.modalCancelButton}>Cancel</Button>
                <Button onClick={handleForgotUnameSubmit} className={classes.modalSubmitButton} disabled={buttonClicked}>Submit</Button>
            </div>
        </ div>
    </ForgotPwdModal>
    <ForgotPwdModal showModal={showForgotPwdModal} handleClose={handleClose}>
        <div className={classes.modalBody}>
            <h5>Forgot Password?</h5>
            <p>Please enter your username in the box below and we will send a reset password link to your email.</p>
            <p>If you’ve forgotten your username, please click on the “Username” link on the previous page to 
                retrieve your username.
            </p>
            <p>If your email address is unavailable, please contact us at (952) 236-7618</p>
            <input type="text" value={userName} 
                placeholder="Username" 
                className={`${forgetPwdError ? classes.inputError : classes.inputStyle}`}
                onChange={(e)=>{setUserName(e.target.value); setForgetPwdError("");}}/>
            {forgetPwdError && <p className={classes.errorMessage}>{forgetPwdError}</p> }
            <div className={classes.modalButtonContianer}>
                <Button onClick={handleClose} className={classes.modalCancelButton}>Cancel</Button>
                <Button onClick={handleForgotPwdSubmit} className={classes.modalSubmitButton} disabled={buttonClicked}>Submit</Button>
            </div>
        </ div>        
    </ForgotPwdModal>
    {signinClicked && <div className={classes.loadingDiv}>
        <PropagateLoader color="#E84E3B" size={14}/>
    </div>}
  </div>;
  </>
};

export default CustomerLogin;
