import React, { useEffect } from 'react';
import Footbar from '../../../Components/Footbar/Footbar';
import classes from './ReturnPolicyLayout.module.css';

const ReturnPolicyLayout = () => {
    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);
  return (<>
    <div className={classes.returnPolicyOuterContainer}>
    <div className={classes.returnPolicyInnerContainer}>

        <h2>Refund &amp; Return Policy.</h2>
        <br/>
        <h4>OUR SATISFACTION GUARANTEE</h4>
        <br />
        <p>Love our service, or your money back. No questions asked.</p>
        <p>All synKro services come with a 30-day money-back guarantee! The 30-day period starts when your service is 
            activated and your payment method is charged, not when you registered for service and placed your order.</p>
        <p>Should you, for any reason, wish to terminate your synKro services during your first 30 days of service, call 
            our Customer Service number at 1-844-883-1659 or email us at info@synkro.us and state that you wish to cancel 
            your service.  The customer service representative will take your request -- and we will also ask you if 
            there's anything we can do to make it right!</p>
        <p>If you still wish to cancel service, our scheduling department will contact you to set up a time for the 
            technician to come to your home and remove the outdoor modem with its wiring with minimal impact to your 
            premises. We will pick up the Wi-Fi router and power supply. You or a responsible adult must be home and 
            will be asked to sign a receipt.  We will refund the fees for your pre-paid service.  Your service will be 
            terminated shortly after the refund is issued to the original payment method.</p>
        <p>After the first 30 days of service, you may cancel your service at any time by contacting us at the number or 
            email above.  Upon cancellation, your Internet service will terminate at the end of your pre-paid period.  
            There is no refund on “unused” pre-paid service.  We will schedule a time to pick up the equipment, as 
            described above. </p>
        <p>NOTE: Failure to allow us to retrieve the equipment within the time specified may result in a charge to your 
            credit card on file for the value of the equipment.  Note that this amount can reach $400 or more with 
            additional sales tax applied.</p>
        <p>NOTE:  Regardless of when you cancel, there are no refunds on:</p>
        <p>	•	Any new account set-up fee(s) plus taxes.</p>
        <p>	•	Your installation fee plus taxes.</p>
    </div>
    </div>
    <Footbar />
    </>
  )
}

export default ReturnPolicyLayout



