import React, { useEffect, useState, useContext } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import UserHomeData from "../../../Context/UserAccountContext";
import LoginServices from "../../../Services/LoginServices";
import downloadPdfIcon from "../../../Assets/Images/filetype-pdf.svg";
import classes from './StatementCenter.module.css';
import {convertDatetoDDMMMYYYY} from '../../../Utils/commonUtils';
import './StatementCenter.css';
import { PropagateLoader } from "react-spinners";
import { Button } from "react-bootstrap";

const RemotePagination = ({ data, page, sizePerPage, onTableChange, totalSize, columns }) => (
  <div>
    <BootstrapTable
      remote
      striped
      keyField="id"
      data={ data }
      columns={ columns }
      pagination={ paginationFactory({ page, sizePerPage, totalSize }) }
      onTableChange={ onTableChange }
    />
  </div>
);

const StatementCenter = () => {
    const {setActiveLink, userAccountInfo} = useContext(UserHomeData);
    const [tableData, setTableData] = useState([]);    
    const [currentPage, setCurrentPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(12);
    const [totalSize, setTotalSize] = useState(0);
    const [loading, setLoading] = useState(true);
    const [pdfDownloadLoading, setPdfDownloadLoading] = useState(false);
    const [customerPartner,setCustomerPartner] = useState(false);
    
    useEffect(()=>{
      setActiveLink(4);
      fetchTableData(currentPage,sizePerPage);
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      // setTableData(testData)
    },[])

    useEffect(()=>{
      if(userAccountInfo?.locationDetails?.city?.toLowerCase()==="paris" || userAccountInfo?.locationDetails?.city?.toLowerCase()==="colville"){
        setCustomerPartner(true);
      }
      else{
        setCustomerPartner(false);
      }
    },[userAccountInfo])
    
    const columns = [{
      datField: '',
      text:'',
      formatter: ()=><img src={downloadPdfIcon} alt="download-icon" className={classes.downloadIcon} />,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          let rowDateArr = row.date.split("-")
          let formattedDate = rowDateArr.reverse().join("-");
          getStatements(row,formattedDate);
        }
      }
    },
      {
      dataField: 'date',
      text: 'Date',
    }, {
      dataField: 'description',
      text: 'Description',
      sort: true
    },
    {
      dataField: 'amount',
      text: 'Amount Billed',
      sort: true
    },
    {
      dataField: 'payments',
      text: 'Payment',
      sort: true
    }, {
      dataField: 'tax',
      text: 'Tax',
      sort: true
    },{
      dataField: 'balance',
      text: 'Balance',
      sort: true
    }];

    const fetchTableData = (currPage, pageSize) =>{
      LoginServices.getPaidStatement(currPage-1,pageSize)
      .then((resp)=>{
        let receivedData = resp.data.arr;
        receivedData.map((item)=>{
          item.date=convertDatetoDDMMMYYYY(item.date)
          item.amount=item.amount.toFixed(2)
          item.payments=item.payments.toFixed(2)
          item.tax=item.tax.toFixed(2)
          item.balance=item.balance.toFixed(2)
        })
        setTableData(receivedData);
        setTotalSize(resp.data.count);
        setLoading(false);
      })
      .catch((err)=>{
        setLoading(false);
      })
    }

    const handleTableChange = (type, { page, sizePerPage }) => {
      fetchTableData(page-1,sizePerPage);
      setCurrentPage(page);
      setSizePerPage(sizePerPage);
      // const currentIndex = (page - 1) * sizePerPage;
      // setTimeout(() => {
      //   setCurrentPage(page);
      //   setSizePerPage(sizePerPage);
      //   setTableData(testData.slice(currentIndex, currentIndex + sizePerPage));
      // }, 1000);
    }

    const getStatements = async(rowData, date) =>{
      setPdfDownloadLoading(true);
      if (rowData.type==="invoice" || rowData.type==="payment" || rowData.type==="quote" || rowData.type==="credit" || rowData.type==="debit"){
        try{
          const responseData = await LoginServices.getStatementPdf(rowData.key,rowData.type);
          generatePDF(responseData.data);
          setPdfDownloadLoading(false);
        }
        catch(error){
          setPdfDownloadLoading(false); 
        }
      }
      else {
        try{
          const responseData = await LoginServices.getStatements(date);
          generatePDF(responseData.data);
          setPdfDownloadLoading(false);
        }
        catch(error){
          setPdfDownloadLoading(false);
        }
      }
    }
  
    const generatePDF = (pdfData) => {
      const blob = new Blob([pdfData], { type: 'application/pdf' });
      const link = window.URL.createObjectURL(blob);
      window.open(link);
    }

  return <div className={classes.statementOuterContainer}>
    <div className={`${classes.statementInnerContainer} ${customerPartner ? "statementInnerDivCP" : "statementInnerDiv"}`}>
      <nav className={`${customerPartner ? classes.statementCenterNavCP : classes.statementCenterNav}`}>
        <h2 className={classes.navBarTitle}>My synKro Service Statements</h2>
      </nav>
      {loading ?  <div className={classes.loadingDiv}>
        <PropagateLoader color={`${customerPartner ? "#306AC0": "#E84E3B"}`} size={14}/>
      </div>: 
      <RemotePagination
        data={ tableData }
        columns = {columns}
        page={currentPage}
        sizePerPage={sizePerPage}
        totalSize={totalSize}
        onTableChange={handleTableChange }
      />}
    </div>
    {pdfDownloadLoading && <div className={classes.loadingDiv}>
      <p className={classes.loadingMessage}>Please wait. Generating pdf...</p>
        <PropagateLoader color={`${customerPartner ? "#306AC0": "#E84E3B"}`} size={14}/>
    </div>}
  </div>;
};

export default StatementCenter;
