import React from "react";
import ContactFooter from'../../../Components/ContactFooter/ContactFooter';
import Footbar from "../../../Components/Footbar/Footbar";

const AddressFormLayout = () => {
  return <>
    <div>AddressFormLayout</div>;
    <ContactFooter isDark={false} />
    <Footbar />
  </>
};

export default AddressFormLayout;
