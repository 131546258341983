import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import { Button, Container } from "react-bootstrap";
import ContactFooter from "../../../Components/ContactFooter/ContactFooter";
import StepProgressBar from "../../../Components/StepProgressBar/StepProgressBar";
import classes from './RequestReviewLayout.module.css';
import { convertToTitleCase } from "../../../Utils/commonUtils";
import Footbar from "../../../Components/Footbar/Footbar";

const RequestReviewLayout = () => {
    const [selectedPackage] = useState(JSON.parse(sessionStorage.getItem("selectedPackage")));
    const [enteredAddress] = useState(sessionStorage.getItem("addressDetails")? JSON.parse(sessionStorage.getItem("addressDetails")): "" );

    let percentComplete = 100;
    let prevPercentComplete = 54;

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }, []);

  return <>
    <Container fluid className={classes.outerContainer}>
      <h2 className={classes.signupTitle}>Sign Up for Broadband Internet</h2>
      <p className={classes.para1}>You’re currently signing up for {selectedPackage.name} at ${selectedPackage.monthlyFee?.toFixed(2)}</p>
      <p className={classes.para2}>Please review your order below to ensure all information is correct.</p>
      <StepProgressBar percentComplete={percentComplete} prevPercentComplete={prevPercentComplete} active="3" />
      <h2 className={classes.formTitle}>Review your Request for Service</h2>
      <div className={classes.reviewContainer}>
      <div className={classes.innerContainer1}>
        <div className={classes.innerRow}>
            <div className={classes.leftCol}><span className={classes.sectionTitle}>Address of Service</span></div>
            <Link to={`/signup/edit`}><div className={classes.rightCol}><span className={classes.editLink}>Edit</span></div>
            </Link>
        </div>
        <div className={classes.innerRow}>
            <div className={classes.leftCol}>{enteredAddress.Address2?"Address 1":"Address"}</div>
            <div className={classes.rightCol}>{convertToTitleCase(enteredAddress.Address1)}</div>
        </div>
        {enteredAddress.Address2 && <div className={classes.innerRow}>
            <div className={classes.leftCol}>Address 2</div>
            <div className={classes.rightCol}>{convertToTitleCase(enteredAddress.Address2)}</div>
        </div>
        }
        <div className={classes.innerRow}>
            <div className={classes.leftCol}>City, State</div>
            <div className={classes.rightCol}>{convertToTitleCase(enteredAddress.City)},{enteredAddress.State}</div>
        </div>
        <div className={classes.innerRow}>
            <div className={classes.leftCol}>zip code</div>
            <div className={classes.rightCol}>{enteredAddress.Zip5} {enteredAddress.Zip4}</div>
        </div>
        </div>
        <div className={classes.innerContainer2}>
        <div className={classes.innerRow}>
            <div className={classes.leftCol}><span className={classes.sectionTitle}>Package Details</span></div>
            <Link to="/packages"><div className={classes.rightCol}>
                <span className={classes.editLink}>Edit</span>
            </div></Link>
        </div>
        <div className={classes.innerRow}>
            <div className={classes.leftCol}>{selectedPackage.name}</div>
            <div className={classes.rightCol}>${selectedPackage.fees.find((e)=>e.name==="Monthly Price")?.price?.toFixed(2)}/month</div>
        </div>
        {selectedPackage.fees.find((e)=>e.name==="Managed Residential Wi-Fi") && 
            <div className={classes.innerRow}>
                <div className={classes.leftCol}>Modem Rental Fee</div>
                <div className={classes.rightCol}>${selectedPackage.fees.find((e)=>e.name==="Managed Residential Wi-Fi")?.price?.toFixed(2)}/month</div>
            </div>
        }
        {selectedPackage.fees.find((e)=>e.name==="Modem Rental") &&
            <div className={classes.innerRow}>
                <div className={classes.leftCol}>Managed Wifi Fee</div>
                <div className={classes.rightCol}>${selectedPackage.fees.find((e)=>e.name==="Modem Rental")?.price?.toFixed(2)}/month</div>
            </div>
        }
        <hr />
        <div className={classes.innerRowBold}>
            <div className={classes.leftCol}>Recurring Total</div>
            <div className={classes.rightCol}>${selectedPackage?.monthlyFee?.toFixed(2)}/month</div>
        </div>
         <div className={classes.innerRowBold}>
            <div className={classes.leftCol}>Set-up Fee</div>
            <div className={classes.rightCol}>${selectedPackage?.setupFee?.toFixed(2)}/one time</div>
        </div>
        {/*<div className={classes.innerRow}>
            <div className={classes.leftCol}>Add-on Selected</div>
            <div className={classes.rightCol}>Add-on Cost</div>
        </div> */}
        </div>
        
        <div className={classes.buttonContainer}>
            <Link to="/create-account"><Button className={classes.signupButton}>Sign-up for Internet</Button></Link>
        </div>
      </div>
    </Container>
    <ContactFooter isDark={false} />
    <Footbar />
  </>;
};

export default RequestReviewLayout;
