import React, { useEffect, useState,useContext }  from 'react';
import { useNavigate } from 'react-router-dom';
import {Navbar, Container, Nav, NavDropdown} from 'react-bootstrap';
import Logo from '../../Assets/Images/synKro_Giga_Logo.png';
import lamarWirelessLogo from '../../Assets/Images/lamar_wireless_logo.png';
import adeptLogo from '../../Assets/Images/adept_internet_solution_logo.png';
import MobileLogo from '../../Assets/Images/synkro-logo2.png';
import classes from './NavigationBar.module.css';
import "./NavigationBar.css";
import  SignupData  from '../../Context/PublicUserContext';

const NavigationBar = () => {
  const navigate = useNavigate();
  const { customerPartner } = useContext(SignupData);
  const [customer, setCustomer] = useState();

  useEffect(()=>{
    setCustomer(customerPartner);
  },[customerPartner])

  const handleLinkRedirection = (path) =>{
    if(process.env.NODE_ENV==="development"){
      navigate(`${path}`)
      }
      else if(process.env.NODE_ENV==="production"){
        if(window.location.href === process.env.REACT_APP_PROD_URL){
          navigate(`${path}`)
        }
        else{
          window.location.assign(`${process.env.REACT_APP_PROD_URL}${path}`)
        }
      }
  }

  const handleLogo = () =>{
    switch(customer){
      case "Lamar Wireless":
        return <img src={lamarWirelessLogo} className={classes.brandLogo} alt='logo' />;
      case "Adept Internet Solution":
        return <img src={adeptLogo} className={classes.brandLogo} alt='logo'/>;
      default :
        return <img src={Logo} className={classes.brandLogo} alt='logo'/>;
    }
  }
  
  return (
    <Navbar expand="md" className={classes.navigationBar}>
      <Container fluid className={classes.navbarContainer}>
          <Navbar.Brand onClick={()=>handleLinkRedirection("/home")}>
            {handleLogo()}
            <img src={MobileLogo} className={classes.mobileLogo} alt='logo'/>
          </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className={classes.navMenubar}>
          <NavDropdown title="Services" id="navbarScrollingDropdown" className={classes.navbarLink}>
              <NavDropdown.Item onClick={()=>handleLinkRedirection("/residential")}>
               Residential Internet
              </NavDropdown.Item>
              <NavDropdown.Item onClick={()=>handleLinkRedirection("/business")}>
               Business Internet
              </NavDropdown.Item>
              {/* <NavDropdown.Item onClick={()=>handleLinkRedirection("/wifi-extenders")}>
                Wi-Fi Extenders
              </NavDropdown.Item> */}
            </NavDropdown>
            <p onClick={()=>handleLinkRedirection("/how-it-works")} className={classes.navbarLink2}>How it works</p>
            <p onClick={()=>handleLinkRedirection("/customer-login")} className={classes.navbarLink2}>Login</p>
            {/* <p className={`${!showLoginLink?classes.userNameText:classes.navbarLink}`}>{!showLoginLink? "Welcome, " + userFirstName:"Login"}</p> */}
            {/* <p className={classes.navbarLink}>Login</p> */}
            <p onClick={()=>handleLinkRedirection("/select-location")} className={classes.checkButton}>Check Availability</p>
          </Nav>
        </Navbar.Collapse>
      </Container>
      </Navbar>
  )
}

export default NavigationBar