import React from "react";
import Logo from '../../../Assets/Images/synKro_Giga_Logo.png';
import MobileLogo from '../../../Assets/Images/synkro-logo2.png';
import classes from "./ErrorPageLayout.module.css";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
import listImage from '../../../Assets/Images/synKro-footer-list.png';
import InstagramIcon from '../../../Assets/SVGIcons/InstagramIcon';
import FbIcon from '../../../Assets/SVGIcons/FbIcon';
import ContactFooter from "../../../Components/ContactFooter/ContactFooter";
import Footbar from "../../../Components/Footbar/Footbar";
import { useNavigate } from "react-router-dom";

const ErrorPageLayout = () => {
    const navigate = useNavigate();
    const redirectLogin =async ()=>{
        window.location.assign(`${process.env.REACT_APP_PROD_URL}/customer-login`);
      }

  return <div className={classes.errorOuterContainer}>
    <Navbar expand="md" className={classes.navigationBar}>
        <Container fluid className={classes.navbarContainer}>
            <Navbar.Brand >
                <div className={classes.brandLogoContainer}>
                    <img src={Logo} className={classes.brandLogo} alt='logo'/>
                </div>
                <img src={MobileLogo} className={classes.mobileLogo} alt='logo'/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className={classes.navMenubar}>
                <div className={classes.buttonContainer}>
                <Button className={classes.loginButton} onClick={redirectLogin}>Login</Button>
                </div>
            </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>
    <div className = {classes.container1}>
        <div className={classes.innerContainer1}>
            <h4>Page Not Found</h4>
            <h6>There was an error locating the requested page</h6>
            <Button className={classes.backButtonRed} onClick={()=>window.history.back()}>
                Go Back
            </Button>
        </div>
    </div>
    <p className={classes.para1}>
        Oops, it looks like we could not find the page you had requested. <br />
        Please go back and try again, or Contact Us using the information below.
    </p>
    <div className={classes.backButtonContainer}>
        <Button className={classes.backButtonRed} onClick={()=>window.history.back()}>
            Go Back
        </Button>
    </div>
    <ContactFooter isDark={false} />
    <Container fluid className={classes.footerContainer}>
        <div className={classes.row1}>
          <div className={classes.r1col1}>
            <img src={listImage} alt=''/>
          </div>
          <div className={classes.r1col2}>
            <p onClick={()=>navigate("/faq")}>FAQ</p>
            <p onClick={()=>navigate("/select-location")}>Pricing</p>
            <p onClick={()=>navigate("/contact")}>Contact</p>
            <p onClick={()=>navigate("/cookie-policy")}>Cookie Policy</p>
            <p onClick={()=>navigate("/residential")}>Residential Internet</p>
            <p onClick={()=>navigate("/business")}>Business Internet</p>
          </div>
        </div>
        <hr className={classes.horizontalLine} />
        <div className={classes.row2}>
          <h2 className={classes.subTitle1}>
            YOUR HOMETOWN BROADBAND PROVIDER
          </h2>
          <p className={classes.footerPara}>
            <span className={classes.boldText1}>synKro</span> 
            &nbsp; is an Internet service provider offering superior broadband Internet to small 
            towns and rural communites throughout the U.S. For more information or to request service in your 
            area, contact us by phone or email.</p> 
          <h2 className={classes.subTitle2}>A LocaLoop company</h2>
          <p className={classes.footerPara}>12800 White Water Drive, Ste. 100, Minnetonka, MN, 55343</p>
        </div>
        <div className={classes.logoContainer}>
          <a href="https://www.facebook.com/synkrous" target="_blank" rel="nofollow noopener noreferrer">
            <FbIcon width="24" height="24" color="#FFFFFF" />
          </a>
          <a href="https://www.instagram.com/synkro.us/" target="_blank" rel="nofollow noopener noreferrer">
            <InstagramIcon width="24" height="24" color="#FFFFFF" />
          </a>
        </div>
        <div className={classes.copyrightContainer}>
          <span onClick={()=>navigate("/about")} className={classes.aboutLink}>About</span>
          &nbsp; | © 2022 all rights reserved.
        </div>
        <div className={classes.footerLinks}>
          <p onClick={()=>navigate("/return-policy")}>Return Policy</p> <span>|</span>
          <p onClick={()=>navigate("/terms")}>Terms and Conditions</p> <span>|</span>
          <p onClick={()=>navigate("/trd")}>Transparency Rule</p> <span>|</span>
          <p onClick={()=>navigate("/privacy-policy")}>Privacy Policy</p>
        </div>          
    </Container>
  </div>
  
};

export default ErrorPageLayout;
