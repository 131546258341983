import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import {  Modal, Carousel } from 'react-bootstrap';
import Cookies from 'universal-cookie';
import CookiePopup from '../../../Components/CookiePopup/CookiePopup';
import './HomeLayout.css';
import CustomButtonLightBg from '../../../Components/CustomButtonLightBg/CustomButtonLightBg';
import CustomButtonDarkBg from '../../../Components/CustomButtonDarkBg/CustomButtonDarkBg';
import locationIcon from '../../../Assets/Images/location-icon.png';
import userIcon from '../../../Assets/Images/user-icon.png';
import checkedIcon from '../../../Assets/Images/checked-icon.png';
import  SignupData  from '../../../Context/PublicUserContext';
import Footbar from '../../../Components/Footbar/Footbar';

const testimonials = [{
    message: "The folks at synKro had very little to work with on my end (old trailer house), they did an amazing job. They were very professional and worked very fast. On another note, I have had Suddenlink and AT&T in Kermit and synKro's internet speeds are faster by far. If you want to get what you pay for, they're the way to go.",
    author: "Jonathan",
    location: "Kermit, TX"
  }
]
const HomeLayout = () => {
  const [showModal, setShowModal] = useState(false);
  const {setUserFirstName} = useContext(SignupData);

  useEffect(()=>{ 
    setUserFirstName("");
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    const cookies = new Cookies();
    const storeCookies = localStorage.getItem("enableCookies");
    const userCookie = cookies.get("user"); 
    if(storeCookies === null){
      setShowModal(true);
    }
    if(storeCookies === "true" && userCookie === undefined){
      cookies.set("user","public",[{path:"/"}]);
    }
  },[])

  const handleClose = () =>{
    localStorage.setItem('enableCookies', 'false');
    setShowModal(false)
  }
  const handleModal = () =>{
    setShowModal(false)
  }

  return (
    <>
    <div className='publicHomeContainer'>
      <div className='homeContainer1'>
        <h1>Rural broadband Internet matters</h1>
        <h3>Built for rural communities and focused on your Internet needs.</h3>
        <div className='caButtonContainer'>
        <Link to="/select-location">
          <CustomButtonLightBg buttonText="Check Availability" />
        </Link>
        </div>
      </div>
      <div className='homeContainer2'>
        <h1>No contracts. No data limits. No hidden-fees.</h1>
        <Link to="/select-location">
          <CustomButtonDarkBg buttonText="Check Availability" />
        </Link>
      </div>
      <div className='homeContainer3'>
        <h1>Work. Life. Play.</h1>
        <div className='homeInnerContainer1'>
          <h3>It’s time for reliable, fast and affordable broadband Internet </h3>
        </div>
        <Link to="/select-location">
          <CustomButtonLightBg buttonText="Check Availability" />
        </Link>
      </div>
      <div className='homeContainer4'>
        <h1>Just three steps to get started:</h1>
        <div className='homeContainer4InnerContainer'>
          <div className='homeContainer4Column'>
              <img src={locationIcon} alt="location-icon" className='iconStyle'/>
              <p className='homeContainerInnerpara1'>1</p>
              <p className='homeContainerInnerpara2'>Click “ Get started” to confirm availability at your address.</p>
          </div>
          <div className='homeContainer4Column'>
              <img src={userIcon} alt="location-icon" className='iconStyle'/>
              <p className='homeContainerInnerpara1'>2</p>
              <p className='homeContainerInnerpara2'>Create your FREE account by selecting a service plan.</p>
          </div>
          <div className='homeContainer4Column'>
              <img src={checkedIcon} alt="location-icon" className='iconStyle'/>
              <p className='homeContainerInnerpara1'>3</p>
              <p className='homeContainerInnerpara2'>Receive synKro installation from your local town technicians.</p>
          </div>        
        </div>
        <div className='getStartedButtonContainer'>
        <Link to="/select-location">
          <CustomButtonDarkBg buttonText="Get Started" />
        </Link>
        </div>
      </div>
      <div className='homeContainer5'>
        <h2>What customers are saying</h2>
      <div className='carousalContainer'>
      <Carousel variant="dark">
        {testimonials.map((item,index)=>(
          <Carousel.Item interval={60000} key={index}>
            <Carousel.Caption>
              <h4>"{item.message}"</h4>
              <p>{item.author} | <strong>{item.location}</strong></p>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      
    </Carousel>
    </div>
      </div>

      <Modal size="md" centered show={showModal} onHide={handleClose} className="cookieContainer">
        <CookiePopup handleModal={handleModal}/>
      </ Modal>
      
    </div>
    <Footbar />
    </>
  )
}

export default HomeLayout
