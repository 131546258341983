import React, { useEffect, useContext, useState } from "react";
import classes from "./AccountInfo.module.css";
import UserHomeData from "../../../Context/UserAccountContext";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import LoginServices from "../../../Services/LoginServices";
import { useForm } from "react-hook-form";
import { PropagateLoader } from "react-spinners";
import CustomToast from "../../../Components/CustomToast/CustomToast";
import { extractNumber } from "../../../Utils/commonUtils";
import { stateList } from "../../../Utils/USStates";

const AccountInfo = () => {
  const navigate = useNavigate();
  const { setActiveLink,setShowExpiryPopup, userAccountInfo } = useContext(UserHomeData);
  const {  register, handleSubmit,reset, clearErrors, setError, formState: { errors }} = useForm();
  const [userAccountData, setUserAccountData] = useState();
  const [disableContactButton, setDisableContactButton] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [userEmailId, setUserEmailId] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [zipcode,setZipcode] = useState("");
  const [primaryPhoneNo, setPrimaryPhoneNo] = useState("");
  const [altPhoneNo, setAltPhoneNo] = useState("");
  const [billingStateData, setBillingStateData] = useState("");
  const [customerPartner,setCustomerPartner] = useState(false);

  useEffect(()=>{
    setActiveLink(1.1);
    getAccountData();
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  },[]);

  useEffect(()=>{
    if(userAccountInfo?.locationDetails?.city?.toLowerCase()==="paris" || userAccountInfo?.locationDetails?.city?.toLowerCase()==="colville"){
      setCustomerPartner(true);
    }
    else{
      setCustomerPartner(false);
    }
  },[userAccountInfo])

  useEffect(()=>{
    if(billingStateData.length === 2){
      const verifiedStateCode = stateList.find((item)=>{
        return item.abbreviation.toLowerCase()===billingStateData.toLowerCase()
      })
      if(!verifiedStateCode){
        setError("billingState",{type:'invalidStateCode'})
      }
      else{
        clearErrors("billingState")
      }
    }
  },[billingStateData])

  // useEffect(()=>{
  //   if(showExpiryPopup){
  //     setShowToast(true);
  //     setToastType("error")
  //     setToastMessage("Your session has expired. Please Login.");
  //   }
  // },[showExpiryPopup])


  const getAccountData = async() =>{
    LoginServices.getAccountInfo()
    .then((resp)=>{
      setUserAccountData(resp.data);
      setInitialLoading(false);
      let formData = {
        company: resp.data.mainAddress.company,
        firstName: resp.data.mainFirstName,
        lastName: resp.data.mainLastName,
        primaryPhone:formatPhoneNumber(resp.data.mainPhones[0]),
        secondaryPhone:formatPhoneNumber(resp.data.mainPhones[1]),
        billingFirstName: resp.data.billingFirstName,
        billingLastName: resp.data.billingLastName,
        billingAddress1: resp.data.billingAddress.address1,
        billingAddress2: resp.data.billingAddress.address2,
        billingCity: resp.data.billingAddress.city,
        billingState: resp.data.billingAddress.state,
        billingZip: resp.data.billingAddress.zip5
      }
      setBillingStateData(resp.data.billingAddress.state)
      setZipcode(`${resp.data.billingAddress.zip5}${resp.data.billingAddress.Zip4?"-":""}${resp.data.billingAddress.Zip4?resp.data.billingAddress.Zip4:""}`)
      setUserEmailId(resp.data.email);
      setPrimaryPhoneNo(formatPhoneNumber(resp.data.mainPhones[0]));
      setAltPhoneNo(formatPhoneNumber(resp.data.mainPhones[1]));
      reset(formData);
    })
    .catch((err)=>{
      setInitialLoading(false);
    })
  }

  const handleUpdateInfo = (data) =>{
    setDisableContactButton(true);
    setUpdateLoading(true);
    let accountData = {
      // balance:400,
      mainAddress:{company: data.company},
      billingFirstName: data.billingFirstName,
      billingLastName : data.billingLastName,
      billingAddress:{ 
        address1:data.billingAddress1,
        address2:data.billingAddress2,
        city:data.billingCity,
        state:data.billingState,
        zip5:data.billingZip,
        zip4: null},  
      email:userEmailId, 
      // mainFax:data.fax,
      mainFirstName:data.firstName,
      mainLastName:data.lastName,
      mainPhones:[extractNumber(data.primaryPhone)],
      marketingEmail:userEmailId,
      // status:0,
      technicalEmail:userEmailId,
    }
    if(data.secondaryPhone){
      accountData.mainPhones.push(extractNumber(data.secondaryPhone))
    }
    else if(!data.secondaryPhone && accountData.mainPhones.length>1){
      accountData.mainPhones.pop();
    }
   
    LoginServices.updateAccountInfo(accountData)
    .then((resp)=>{
      getAccountData();
      setDisableContactButton(false);
      setUpdateLoading(false);
      setShowToast(true);
      setToastType("success")
      setToastMessage("Data has been updated successfully!")
    })
    .catch((err)=>{
      setDisableContactButton(false);
      setUpdateLoading(false);
      if(err.response.status === 400){
        setShowToast(true);
        setToastType("error")
        setToastMessage("There was an error updating data.Please try again.")
      }      
    })
  }

  const handleEmailChange = (e) => {
    let { value } = e.target;
    const validEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    setUserEmailId(value);
    if(value.match(validEmail)){
      clearErrors("emailId")
    }
    else{
      setError('emailId',{type:'invalid-email'})
    }

  }

  const closeToast = () => {
    setShowToast(false);
    // if(toastMessage==="Your session has expired. Please Login."){
    //   setShowExpiryPopup(false);
    //   navigate("/customer-login");
    // }
  }

  const zipChangeHandler = (e) =>{
    let { value } = e.target;
    if(value.length === 6){
        value = value.replace(/[^0-9-]/g,'');
    }
    else{
        value = value.replace(/[^0-9]/g,'');
    }
    if(value.length ===5 || value.length === 9){
        clearErrors('zip');
    }
    else{
        setError('zip',{type:'zip-error'})
    }
    
    setZipcode(value.replace(/(\d{5})(\d)/, "$1-$2").substr(0, 10));
  }
  const formatPhoneNumber = (ph) => {
    if(ph){
      let value = ph.replace(/[^0-9()-\s]/g, '');
      let cleaned = ('' + value).replace(/\D/g, '');
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        let formattedNo = ((`(${match[1]}) ${match[2]}-${match[3]}`));
        return formattedNo;
      }
      else{
        return ph;
      }
    }
    else{
      return ph;
    }
    
  }
  const handlePhoneNumber = (e) =>{
    let {value}=e.target;
    value = value.replace(/[^0-9()-\s]/g, '');
    let cleaned = ('' + value).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      let formattedNo = ((`(${match[1]}) ${match[2]}-${match[3]}`))
      setPrimaryPhoneNo(formattedNo);
      clearErrors("primaryPhone")
    }
    else if(value.length<15){
      setPrimaryPhoneNo(value);
      clearErrors("primaryPhone")
    }
  }
  const handleAltPhoneNumber = (e) =>{
    let {value}=e.target;
    value = value.replace(/[^0-9()-\s]/g, '');
    let cleaned = ('' + value).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
    let formattedNo = ((`(${match[1]}) ${match[2]}-${match[3]}`))
    if(formattedNo === primaryPhoneNo){
      setError('secondaryPhone',{type:'primaryPhone-duplicate'})
    }
    else{
      clearErrors("secondaryPhone")
    }
      setAltPhoneNo(formattedNo);
    }
    else if(value.length<15){
      setAltPhoneNo(value);
      clearErrors("secondaryPhone")
    }
  }

  const handleStateValue =(e)=>{
    const {value}=e.target;
    setBillingStateData(value);
  }

  return <div className={classes.accountInfoContainer}>
    <div className={classes.mainContactContainer}>
      <form onSubmit={handleSubmit(handleUpdateInfo)}>
      <div className={`${customerPartner ? classes.mainContactNavbarCP : classes.mainContactNavbar}`}>
        <h2>Main Contact</h2>
        <Button type='submit' className={classes.mainContactButton} disabled={disableContactButton}>
          Save Changes
        </Button>
      </div>
      <div className={classes.row1}>
        <p>Company <span> - optional</span></p>
        <input type="text" placeholder="Company"
          className={errors.company ? classes.addressInputError : classes.addressInput} 
          {...register("company")}/>
      </div>
      <div className={classes.row2}>
        <div className={classes.row2Col}>
          <p>First Name*</p>
          <input type="text" placeholder="First name"
            className={errors.firstName ? classes.addressInputError : classes.addressInput} 
          {...register("firstName", {required: true})}/>
        </div>
        <div className={classes.row2Col}>
          <p>Last Name*</p>
          <input type="text" placeholder="Last name" 
            className={errors.lastName ? classes.addressInputError : classes.addressInput} 
          {...register("lastName", {required: true})}/>
        </div>
      </div>
      <div className={classes.row3}>
        <div className={classes.row3Col}>
          <p>Telephone Number*</p>
          <input type="text" 
            name='phNumber' 
            value = {primaryPhoneNo || ""}
            placeholder='(###) ###-####' 
            className={errors.primaryPhone ? classes.addressInputError : classes.addressInput} 
            {...register("primaryPhone", {required: true,
              pattern:/^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/
            })}
            onChange={handlePhoneNumber}
          />
        </div>
        <div className={classes.row3Col}>
          <p>Alternative Phone <span>- optional</span></p>
          <input type="text" 
            name='secondaryPhone'
            value={altPhoneNo || ""} 
            placeholder='(###) ###-####' 
            className={errors.secondaryPhone ? classes.addressInputError : classes.addressInput} 
            {...register("secondaryPhone", {pattern:/^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/})}  
            onChange={handleAltPhoneNumber}              
          />
          {errors?.secondaryPhone?.type==="primaryPhone-duplicate" && 
            <p className={classes.errorMessage}>Duplicate telephone number, please enter a different phone</p>}
        </div>
      </div>
      <div className={classes.row4}>
        <div className={classes.row4Col}>
          <p>Email*</p>
          <input type="email" name="emailId" placeholder="Users@mail.co" 
            value={userEmailId} 
            className={errors.emailId ? classes.addressInputError : classes.addressInput}
            {...register("emailId", {required: true})}
            onChange={handleEmailChange}/>
        </div>
      </div>
      <hr className={`mx-3 ${classes.horizontalLine}`}/>
      <div className={classes.row5}>
      <div className={classes.row5Col}>
          <p>Billing First Name*</p>
          <input type="text" placeholder="First name"
            className={errors.billingFirstName ? classes.addressInputError : classes.addressInput} 
          {...register("billingFirstName", {required: true})}/>
        </div>
        <div className={classes.row5Col}>
          <p>Billing Last Name*</p>
          <input type="text" placeholder="Last name"
            className={errors.billingLastName ? classes.addressInputError : classes.addressInput} 
          {...register("billingLastName", {required: true})}/>
      </div>
      </div>
      <div className={classes.row6}>
        <p>Billing Address 1* </p>
        <input type="text" placeholder="Address"
          className={errors.billingAddress1 ? classes.addressInputError : classes.addressInput} 
          {...register("billingAddress1", {required: true})}/>
      </div>
      <div className={classes.row7}>
        <p>Billing Address 2 <span> - optional</span></p>
        <input type="text" placeholder="Address"
          className={errors.billingAddress2 ? classes.addressInputError : classes.addressInput} 
          {...register("billingAddress2")}/>
      </div>
      <div className={classes.row8}>
        <div className={classes.row8Col}>
          <p>City*</p>
          <input type="text" placeholder="City" 
            className={errors.billingCity ? classes.addressInputError : classes.addressInput} 
            {...register("billingCity", {required: true})}/>
        </div>
        <div className={classes.row8Col}>
          <p>State*</p>
          <input type="text" placeholder="State" 
            value={billingStateData}
            className={errors.billingState ? classes.addressInputError : classes.addressInput} 
            {...register("billingState", {required: true, minLength: 2, maxLength: 2})}
            onChange={handleStateValue}
            />
            {errors?.billingState && <p className={classes.errorMessage}>Enter a valid state code</p>}
        </div>
        <div className={classes.row8Col}>
          <p>Zip*</p>
          <input type="text" 
            name='zip' 
            value={zipcode || ""} 
            placeholder='##### - ####' 
            className={errors.billingZip ? classes.addressInputError : classes.addressInput} 
            {...register("billingZip", {required: true,
                  pattern:/^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/
            })}
            onChange={zipChangeHandler}/>
        </div>
      </div>
      </form>
      <div className={`${customerPartner ? classes.serviceAddressNavbarCP: classes.serviceAddressNavbar}`}>
        <h2>Service Address</h2>
        <p>Contact Customer Support to update Service Address</p>
        {/* <Button type='submit' className={classes.mainContactButton} disabled={disableContactButton}>
          Save Changes
        </Button> */}
      </div>
      <div className={classes.row6}>
        <p>Service Address 1* </p>
        <input type="text"
          className={classes.addressInput} 
          value={userAccountData?.mainAddress?.address1 || ""}
          disabled/>
      </div>
      {userAccountData?.mainAddress?.address2 && <div className={classes.row7}>
        <p>Service Address 2 </p>
        <input type="text"
          className={classes.addressInput} 
          value={userAccountData?.mainAddress?.address2 ||""}
          disabled/>
      </div>}
      <div className={classes.row8}>
        <div className={classes.row8Col}>
          <p>City*</p>
          <input type="text" placeholder="City" 
            className={classes.addressInput} 
            value={userAccountData?.mainAddress?.city || ""}
            disabled/>
        </div>
        <div className={classes.row8Col}>
          <p>State*</p>
          <input type="text" placeholder="State" 
            className={classes.addressInput} 
            value={userAccountData?.mainAddress?.state || ""}
            disabled/>
        </div>
        <div className={classes.row8Col}>
          <p>Zip*</p>
          <input type="text" 
            name='zip' 
            value={userAccountData?.mainAddress?.zip5 || ""}
            className={classes.addressInput}
            disabled/>
        </div>
      </div>
    </div>
      {initialLoading && <div className={classes.loadingDiv}>
        <PropagateLoader color={`${customerPartner ? "#306AC0": "#E84E3B"}`} size={14}/>
      </div>}
      {showToast && <div className={classes.loadingDiv}>
        <CustomToast 
          show={showToast} 
          delay={toastType==="success" ? 3000: 6000}
          message={toastMessage} 
          handleClose={closeToast} 
          type={toastType} 
        />
      </div>}
      {updateLoading && <div className={classes.loadingDiv}>
        <PropagateLoader color={`${customerPartner ? "#306AC0": "#E84E3B"}`} size={14}/>
    </div>}
  </div>;
};

export default AccountInfo;
