import React from "react";
import { Container } from "react-bootstrap";
import classes from "./SignupProgressBar.module.css";

const SignupProgressBar = ({ percentComplete, prevPercentComplete, active }) => {
    let widthStyle = `${percentComplete}%`;
    return (
      <Container className={classes.progressContainer}>
       <div className={classes.progressLabel}>
          <div className={active==="1" ? classes.activeLabel : classes.indvidualLabels}>Create Account</div>
          <div className={active==="2" ? classes.activeLabelCenter : classes.indvidualLabelsCenter}>Terms & Agreements</div>
          <div className={active==="3" ? classes.activeLabelfinal : classes.indvidualLabelsCenter}>Billing Information</div>
          <div className={active==="4" ? classes.activeLabelfinal : classes.indvidualLabelsCenter}>Submit Order</div>
          <div className={active==="5" ? classes.activeLabelfinal : classes.indvidualLabelsfinal}>Thank you</div>
        </div>
        <div className={classes.progressBarBorder}>
          <div
            className={classes.progressBarInner}
            style={{
              width: widthStyle
            }}
          ></div>
        </div>
      </ Container>
    );
  };

export default SignupProgressBar;
