import React, { useEffect, useContext, useState } from "react";
import { Button } from "react-bootstrap";
import { PropagateLoader } from "react-spinners";
import UserHomeData from "../../../Context/UserAccountContext";
import LoginServices from "../../../Services/LoginServices";
import UserServices from "../../../Services/UserServices";
import { checkValidDate } from "../../../Utils/commonUtils";
import classes from "./UpdatePackage.module.css";

const UpdatePackage = () => {
    const {setActiveLink, userAccountInfo} = useContext(UserHomeData);
    const [availablePackages, setAvailablePackages] = useState([]);
    const [refreshToken] = useState(sessionStorage.getItem("refreshToken"));
    const [loading, setLoading] = useState(true);
    const [updateLoader, setupdateLoader] = useState(false);
    const [requestedPckgId, setRequestedPckgId] = useState("");
    const [currentPackage, setCurrentPackage] = useState({});
    const [updateRequestedData, setupdateRequestedData] = useState([]);
    const [customerPartner,setCustomerPartner] = useState(false);

    useEffect(()=>{
        setActiveLink(3.2);
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        getActivePackage();
    },[])

    useEffect(()=>{
      if(userAccountInfo?.locationDetails?.city?.toLowerCase()==="paris" || userAccountInfo?.locationDetails?.city?.toLowerCase()==="colville"){
        setCustomerPartner(true);
      }
      else{
        setCustomerPartner(false);
      }
    },[userAccountInfo])

    const getActivePackage = () => {
      LoginServices.getCustomerPackages()
      .then((resp)=>{
        getRequestedPackageUpdate(resp.data[0]);
        setCurrentPackage(resp.data[0]);
        // getPackages({"id": 63160})
      })
      .catch((err)=>{
        setLoading(false);        
        setupdateLoader(false);
      })
    }

    const getRequestedPackageUpdate = (activePackageObj) =>{
      LoginServices.getPackageUpgradeRequests()
      .then((resp)=>{
        getPackages(activePackageObj, resp.data);
        setupdateRequestedData(resp.data)
      })
      .catch((err)=>{
        setLoading(false);        
        setupdateLoader(false);
      })
    }

    const getPackages = (activePackage,updateRequested) =>{
      UserServices.selectPackages(userAccountInfo.ispId)
      .then((resp)=>{
        let sortedData = resp.data.sort((a, b) => a.monthlyFee - b.monthlyFee);
        sortedData.forEach((item)=>{
          if(item.packageId===activePackage?.id){
            item.activePackage = true;
          }
          else{
            item.activePackage = false;
          }
        })
        const activeUpdateRequest = updateRequested.filter((item)=>{
            return !checkValidDate(item.cancelDateTime);
        })
        sortedData.forEach((item) => {
          const obj1 = activeUpdateRequest.find((o) => o.requestPackageId === item.packageId);
          if (obj1) {
            item.updateRequested = true;
            item.requestedUpdateObj = obj1;
          }
          else{
            item.updateRequested = false;
            item.requestedUpdateObj = null;
          }
        });
        setAvailablePackages(sortedData);
        if(resp?.data) setLoading(false); 
        setupdateLoader(false);
      })
      .catch((err)=>{
        setLoading(false);        
        setupdateLoader(false);
      })
    }

    const handleUpgradeRequest =(selectedPackage) => {
      setRequestedPckgId(selectedPackage.packageId);
      setupdateLoader(true);
      let updateData = {
        key:currentPackage.key,
        packageId:selectedPackage.packageId
      }
      LoginServices.upgradePackage(updateData)
      .then((resp)=>{
        getActivePackage();
      })
      .catch((err)=>{
        setupdateLoader(false);
      })
    }

  const handleCancelUpgrade = (item) =>{
    setupdateLoader(true);
    let data = {
      key: item?.requestedUpdateObj?.key
    }
    LoginServices.deletePackageUpgradeRequests(data)
    .then((resp)=>{
      getActivePackage();
    })
    .catch((err)=>{
      setupdateLoader(false);
    })
  }
    
  return <div className={classes.updatePckgOuterContainer}>
    <nav className={`${customerPartner? classes.updatePckgNavCP : classes.updatePckgNav}`}>
      <h2 className={classes.navBarTitle}>Update My synKro Package</h2>
    </nav>
    {loading ? <div className={classes.loadingDiv}>
        <PropagateLoader color={`${customerPartner ? "#306AC0": "#E84E3B"}`} size={14}/>
    </div> :
    <div className={classes.updatePckgInnerContainer}>
      <h5 className={classes.packagesHeading}>View & Compare our Packages:</h5>
      <div className={classes.packagesCardContainer}>
        {availablePackages.map((item)=>{
          return <div className={classes.packagesCard} key={item.packageId}>
          <div 
            className={`${classes.packageAmountCircle} 
              ${item.activePackage? classes.activePackageCircle : classes.defaultPackageCircle}
              ${item.updateRequested? classes.requestedPackageCircle:""}`}>
            <p>{item.fees[0].price}/mo</p>
          </div>
          <h4 className={classes.pckgName}>{item.name}</h4>
          <h5 className={classes.pckgDescriptionLabel}>Package Description:</h5>
          <p className={classes.pckgDescription}>{item.description}</p>
          <div className={classes.buttonContainer}>
            <hr className={classes.cardHorizontalRuler} />
            <Button 
              onClick={()=>handleUpgradeRequest(item)}
              className={`${item.activePackage?classes.activeButton : classes.requestButton}`}
              disabled={item.updateRequested || item.activePackage}>
            {item.activePackage? "Current Package!": item.updateRequested? "Package Change Requested!":"Request Package Change"}
            </Button>
            {item.updateRequested && <p onClick={()=>handleCancelUpgrade(item)} className={classes.cancelUpgradeRequest}>
              Cancel Package Change Request
            </p>}
          </div>
        </div>
        })}        
      </div>
    </div>}
    {updateLoader && <div className={classes.loadingDiv}>
        <PropagateLoader color={`${customerPartner ? "#306AC0": "#E84E3B"}`} size={14}/>
    </div> }
  </div>
};

export default UpdatePackage;
