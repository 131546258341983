import React, {useEffect, useState} from "react";
import { Accordion, Row, Button, Table } from "react-bootstrap";
import SignupProgressBar from "../../../Components/SignupProgressBar/SignupProgressBar";
import "./ServiceTermsLayout.css";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../../../Components/ConfirmationModal/ConfirmationModal";
import ContactFooter from "../../../Components/ContactFooter/ContactFooter";
import SuccessCheck from "../../../Assets/SVGIcons/SuccessCheck";
import DefaultCheckSquare from "../../../Assets/SVGIcons/DefaultCheckSquare";
import Footbar from "../../../Components/Footbar/Footbar";

const ServiceTermsLayout = () => {
    let percentComplete = 30;
    let prevPercentComplete = 0;
    const navigate = useNavigate();

    const [selectedPackage] = useState(sessionStorage.getItem("selectedPackage") ?JSON.parse(sessionStorage.getItem("selectedPackage")):"");
    const [termsAgreed] = useState(sessionStorage.getItem("termsAgreed") ?JSON.parse(sessionStorage.getItem("termsAgreed")):"");
    const [isRead1, setIsRead1] = useState(false);
    const [isRead2, setIsRead2] = useState(false);
    const [isRead3, setIsRead3] = useState(false);
    const [checked, setChecked] = useState(false);
    const [disableConfirm, setDisableConfirm] = useState(true);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        if(termsAgreed){
            setIsRead1(true);
            setIsRead2(true);
            setIsRead3(true);
            setChecked(true);
        }
      }, []);
    useEffect(()=>{
        if(isRead1 && isRead2 && isRead3){
            setDisableConfirm(false);
            sessionStorage.setItem("termsAgreed",JSON.stringify(true))
        }
        else{
            setDisableConfirm(true);
        }
    },[isRead1,isRead2,isRead3])

    const handleAccordionClick1 = ()=>{
        setIsRead1(true);
    }
    const handleAccordionClick2 = ()=>{
        setIsRead2(true);
    }
    const handleAccordionClick3 = ()=>{
        setIsRead3(true);
    }
    const handleClose = () =>{
        setShowConfirmModal(false);
    }
    return <> 
        <div className="termsMainContainer">
        <h2 className="signupTitle">Agree to Service Terms & Disclosures</h2>
        <p className="para1">You’re currently signing up for {selectedPackage?.name} at ${selectedPackage.monthlyFee?.toFixed(2)}</p>
        <p className="para2">Before you continue the sign up process we want you to know what to expect, so please 
            take a minute to read through the terms and disclosures below.</p>
        <SignupProgressBar percentComplete={percentComplete} prevPercentComplete={prevPercentComplete} active="2" />
        <div className="termsAccordionContainer">
            <Accordion>
            <Accordion.Item eventKey="0" onClick={handleAccordionClick1}>
                <Accordion.Header>
                    {isRead1 ? <SuccessCheck width="16" height="16" color="#28A745" /> : 
                    <DefaultCheckSquare width="16" height="16" color="#FFFFFF" />}
                    <p className="termsHeader"> Next Steps</p></Accordion.Header>
                <Accordion.Body>
                    <p >Following the registration, you will go to the last step in the online process and provide us 
                        with your billing information. You prepay synKro service every thirty days with the credit card 
                        that you give us in this step — no paper bills to worry about!
                    </p>
                    <p>Your credit card will not be charged until your synKro service is active. However, at this time 
                        we will verify that the card information is correct and has adequate funds available.
                    </p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1" onClick={handleAccordionClick2}>
                <Accordion.Header>{isRead2 ? <SuccessCheck width="16" height="16" color="#28A745" /> : 
                    <DefaultCheckSquare width="16" height="16" color="#FFFFFF" />}
                    <p className="termsHeader">Professional Installation</p></Accordion.Header>
                <Accordion.Body>
                <p>Once you have completed the online process, you will be contacted by a synKro customer service 
                    representative within 1-2 business days to set up an installation appointment. We ask you to stick to the scheduled appointment, but if you must change your 
                    appointment, please call customer service (check on your emailed receipt for your local number) to reschedule your installation. If you are not home when 
                    the technician arrives at the scheduled time, you will be charged a $50 return fee.
                </p>
                <p>To ensure the best broadband Internet user experience, we will determine the best location to 
                    mount the outdoor modem. This may include climbing on your roof.
                </p>
                <p>Once we have identified the best location we will, with your consent, install the modem which 
                    includes running a wire from the modem into your home and connect the managed Wi-Fi router at the 
                    location where the wire enters your home. Other than that, we do not do any wiring inside your 
                    home as all your Wi-Fi enabled devices communicate wirelessly.
                </p>
                <p>The technician will do the necessary testing to ensure that your broadband Internet service works 
                    properly to our synKro standards of service. If our professional installer is unable to establish 
                    service at your home, we will remove the equipment and cancel your synKro account. You will pay nothing.
                </p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2" onClick={handleAccordionClick3}>
                <Accordion.Header>{isRead3 ? <SuccessCheck width="16" height="16" color="#28A745" /> : 
                    <DefaultCheckSquare width="16" height="16" color="#FFFFFF" />}
                    <p className="termsHeader">FCC Mandatory Disclosures</p></Accordion.Header>
                <Accordion.Body>
                    <p>As a provider of Internet services, we are required by the Federal Communications Commission (FCC) 
                        to disclose certain information about our services and network management practices to the general 
                        public and also to ensure that all of our subscribers are aware of the disclosures and their 
                        content. You may access the Federal Regulations for disclosure <a href="https://www.govinfo.gov/app/details/FR-2018-02-22" target="_blank" className="disclosureLink" rel="nofollow noopener">here</a>. When you create an account with us in the next page 
                        in the shopping cart, you will be asked to confirm that you have read and agreed to the disclosures 
                        and our Terms & Conditions.
                    </p>
                    <p>The disclosure forms provide you with our contact information. If you have any questions or 
                        concerns regarding our disclosures, we encourage you to contact us and we will do our best to 
                        address them.
                    </p>
                    <p>By proceeding with the purchase of the service, you are agreeing to the terms stated above.
                    </p>
                </Accordion.Body>
            </Accordion.Item>
            </Accordion>
            <div className="termsButtonContainer">
                <input type="checkbox" value={checked} checked={checked} onChange={()=>{setChecked(!checked)}} disabled={disableConfirm}/> <label>I have read the above Terms & Disclosures</label><br />
                <Button className="termsCancelButton" onClick={()=>{setShowConfirmModal(true)}}>Cancel</Button>
                <Button className="termsNextButton" onClick={()=>{navigate("/payments")}} disabled={!checked}>Next</Button>
            </div>
        </div>
        {<ConfirmationModal showModal={showConfirmModal} handleClose={handleClose}/>}
    </div>
    <ContactFooter isDark={false} />
    <Footbar />
    </>
};

export default ServiceTermsLayout;
