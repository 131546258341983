import { useEffect } from 'react';
import './App.css';
import ApplicationRoutes from './Routes/ApplicationRoutes';
import { initGA,PageView } from "./Utils/gaTracking";

function App() {
  useEffect(()=>{
    initGA(process.env.REACT_APP_GA_TRACKING_ID);
    PageView()
  },[])
  return (     
    <div className="App">
    <ApplicationRoutes />
    
    </div>
  );
}

export default App;
