export const msalConfig = {
    auth: {
      clientId: "343c87c6-5e87-40e0-a327-e074f0798176",
      authority: "https://login.microsoftonline.com/514173e3-a8e1-4284-8d9d-a8f79007ff59/", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      redirectUri: process.env.NODE_ENV === "production" ? process.env.REACT_APP_PROD_AUTH_LOGIN_CALLBACK_URL : "http://localhost:3001/auth/login",
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: [ "api://synkrous/Admin.Config"]
  };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
//   export const graphConfig = {
//       graphMeEndpoint: "Enter_the_Graph_Endpoint_Here/v1.0/me"
//   };
