import React from "react";
import { Modal } from "react-bootstrap";
import "./ForgotPwdModal.css";

const ForgotPwdModal = (props) => {
  return <div className="forgotPwdModalContainer">
    <Modal size="lg" centered show={props.showModal } onHide={props.handleClose} className="forgotPwdModal">
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
            {props.children}        
        </Modal.Body>
    </ Modal>
</div>;
};

export default ForgotPwdModal;
