import React, { useContext, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import classes from './Footbar.module.css';
import listImage from '../../Assets/Images/synKro-footer-list.png';
import cusotmListImage from '../../Assets/Images/synKro-footer-customlist.png';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';
import SignupData  from '../../Context/PublicUserContext';
import InstagramIcon from '../../Assets/SVGIcons/InstagramIcon';
import FbIcon from '../../Assets/SVGIcons/FbIcon';

const Footbar = () => {
  const {customerPartner} = useContext(SignupData)
  const [synkroMainUrlCheck]= useState(window.location.origin === (process.env.REACT_APP_PROD_URL)) 
  const { instance } = useMsal();
  const navigate = useNavigate();

  function handleLogin(instance) {
    instance.loginRedirect(loginRequest).catch(e => {
        console.error(e);
    });
  }

  const handleLinkRedirection = (path) =>{
    if(process.env.NODE_ENV==="development"){
      navigate(`${path}`)
      }
    else if(process.env.NODE_ENV==="production"){
      if(window.location.href === process.env.REACT_APP_PROD_URL){
        navigate(`${path}`)
      }
      else{
        window.location.assign(`${process.env.REACT_APP_PROD_URL}${path}`)
      }
    }
  }

  return (
    <Container fluid className={`${customerPartner ? classes.customFooterContainer: classes.footerContainer}`}>
        <div className={classes.row1}>
          <div className={classes.r1col1}>{customerPartner? <img src={cusotmListImage} alt=""/>:
          <img src={listImage} alt=''/>}</div>
          <div className={classes.r1col2}>
            <p onClick={()=>handleLinkRedirection("/faq")}>FAQ</p>
            <p onClick={()=>handleLinkRedirection("/select-location")}>Pricing</p>
            <p onClick={()=>navigate("/contact")}>Contact</p>
            <p onClick={()=>handleLinkRedirection("/cookie-policy")}>Cookie Policy</p>
            <p onClick={()=>handleLinkRedirection("/residential")}>Residential Internet</p>
            <p onClick={()=>handleLinkRedirection("/business")}>Business Internet</p>
            {synkroMainUrlCheck && <p onClick={() => handleLogin(instance)}>synKro Team Login</p>}
          </div>
        </div>
        <hr className={classes.horizontalLine} />
        <div className={classes.row2}>
          <h2 className={classes.subTitle1}>
            YOUR HOMETOWN BROADBAND PROVIDER
          </h2>
          <p className={classes.footerPara}>
            <span className={classes.boldText1}>{customerPartner? customerPartner :"synKro"}</span> 
            &nbsp; is an Internet service provider offering superior broadband Internet to small 
            towns and rural communites throughout the U.S. For more information or to request service in your 
            area, contact us by phone or email.</p> 
          {!customerPartner && <><h2 className={classes.subTitle2}>A LocaLoop company</h2>
          <p className={classes.footerPara}>12800 White Water Drive, Ste. 100, Minnetonka, MN, 55343</p>
          </>}
        </div>
       { !customerPartner && <><div className={classes.logoContainer}>
          <a href="https://www.facebook.com/synkrous" target="_blank" rel="nofollow noopener noreferrer">
            <FbIcon width="24" height="24" color="#FFFFFF" />
          </a>
          <a href="https://www.instagram.com/synkro.us/" target="_blank" rel="nofollow noopener noreferrer">
            <InstagramIcon width="24" height="24" color="#FFFFFF" />
          </a>
        </div>
        <div className={classes.copyrightContainer}>
          <span onClick={()=>handleLinkRedirection("/about")} className={classes.aboutLink}>About</span>
          &nbsp; | © 2022 all rights reserved.
        </div>
        </>}
        {customerPartner==="Lamar Wireless" && <>
        <div className={classes.customerCopyrightContainer}>
          {customerPartner} &nbsp; | © 2022 all rights reserved.
        </div>
        <p className={classes.addressFooter}>
          2270 NE Loop 286 Paris, TX 75460
        </p>
        </>}
        {customerPartner==="Adept Internet Solution" && <>
        <div className={classes.customerCopyrightContainer}>
          {customerPartner} &nbsp; | © 2022 all rights reserved.
        </div>
        <p className={classes.addressFooter}>
          156 N Main ST Colville, WA 99114
        </p>
        </>}
        <div className={classes.footerLinks}>
          <p onClick={()=>handleLinkRedirection("/return-policy")}>Return Policy</p> <span>|</span>
          <p onClick={()=>handleLinkRedirection("/terms")}>Terms and Conditions</p> <span>|</span>
          <p onClick={()=>handleLinkRedirection("/trd")}>Transparency Rule</p> <span>|</span>
          <p onClick={()=>handleLinkRedirection("/privacy-policy")}>Privacy Policy</p>
        </div>  

        
    </Container>
  )
}

export default Footbar