import React, { useState,useEffect, useContext } from "react";
import classes from "./ContactLayout.module.css";
import CustomButtonDarkBg from "../../../Components/CustomButtonDarkBg/CustomButtonDarkBg";
import ContactImage from "../../../Assets/Images/contact-Image1.jpg";
import ContactModal from "../../../Components/ContactModal/ContactModal";
import services from "../../../Services/UserServices";
import SignupData from "../../../Context/PublicUserContext";
import UserServices from "../../../Services/UserServices";
import { PropagateLoader } from "react-spinners";
import Footbar from "../../../Components/Footbar/Footbar";


const ContactLayout = () => {
    const { customerPartner, setCustomerPartner } = useContext(SignupData);
    const [showModal, setShowModal] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastType, setToastType] = useState();
    const [toastMessage, setToastMessage] = useState();
    const [locationDataLoading,setLocationDataLoading] = useState();
    const [ispId, setIspId] = useState("");
    const currentUrl = window.location.host;

    useEffect(()=>{
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      setLocationDataLoading(true);
      UserServices.getLocations()
      .then((response)=>{
          let locationSelected = response.data;
  // For Dev instance on local -  CHANGETODEPLOY - REMOVE
        // locationSelected = locationSelected.find((item)=>item.city.toLowerCase()==="paris")
          locationSelected = locationSelected.filter((item)=>item.city?.toLowerCase() !== "wink")
          locationSelected = locationSelected.find((item)=>item.multiTenantDomain===currentUrl)
          checkCustomPartner(locationSelected);
          setLocationDataLoading(false);
      })
      .catch((err)=>{
          setLocationDataLoading(false);
      })
    },[])

    const checkCustomPartner = (location) =>{
      if(location.city.toLowerCase() ==="paris"){
        setCustomerPartner("Lamar Wireless");
        setIspId(location.id);
      }
      else if(location.city.toLowerCase() ==="colville"){
        setCustomerPartner("Adept Internet Solution");
        setIspId(location.id);
      }
      else{
        setCustomerPartner(null);
        setIspId(null);
      }
    }

    const submitContactInfo =(data)=>{
      let formattedData = {
          Email: data.mailId,
          Message: data.message,
          Name: data.firstName.trim() +" " + data.lastName.trim(),
      }
      if(ispId){
        formattedData.IspId = ispId;
      }
      services.submitContactDetails(formattedData)
      .then((resp)=>{
          setShowToast(true);
          setToastType("success");
          setToastMessage("Contact information submitted");
          setTimeout(()=>{setShowModal(false)},3000);
      })
      .catch((err)=>{
        setShowToast(true);
        setToastType("error");
        setToastMessage("Error submitting contact information")

      })
    }

    const handleContactModal = () =>{
        setShowModal(true);
    }
    const handleClose = ()=>{
        setShowModal(false);
    }
    const closeToast = () =>{
      setShowToast(false);
  }
  return (<>
  <div className={classes.contactOuterContainer}>
    <div className={classes.innerContainer1}>
        <h1>Questions?</h1>
        <h1>We’re <span className={classes.innerSpan1}>here to help.</span></h1>
        
        <div className={classes.contactDetails}>
        <h5>Phone</h5>
        {customerPartner === "Adept Internet Solution" &&<p>509-684-7897</p>}
        {customerPartner === "Lamar Wireless" && <p>(903) 785-3474</p>}
        {!customerPartner &&<p>(884) 883-1659</p>}
        <h5>Email</h5>
        {customerPartner === "Adept Internet Solution"&& 
          <a href="mailto: Sales@adept4u.com" >Sales@adept4u.com</a>}
        {customerPartner === "Lamar Wireless" &&
            <a href="mailto: lamar.satellite@gmail.com" >lamar.satellite@gmail.com</a>}
        {!customerPartner &&
            <a href="mailto: info@synkro.us" className={classes.synkroEmailLink}>info@synkro.us</a>}
        </div>
        <CustomButtonDarkBg buttonText="Contact us" handleClick={handleContactModal}/>
    </div>
    <div className={classes.innerContainer2}>
        <img src={ContactImage} alt="contactImage" />
    </div>
    {locationDataLoading &&
    <div className={classes.loadingDiv}>
        <PropagateLoader color="#E84E3B" size={14}/>
    </div>}
    <ContactModal 
      showModal={showModal} 
      handleClose={handleClose} 
      submitContactInfo={submitContactInfo} 
      showToast={showToast}
      toastType={toastType}
      toastMessage={toastMessage}
      closeToast={closeToast}
      />
  </div>
  <Footbar />
  </>
  );
};

export default ContactLayout;
