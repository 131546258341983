import React, { useEffect, useContext, useState } from "react";
import UserHomeData from "../../../Context/UserAccountContext";
import classes from "./ContactUsLayout.module.css";


const ContactUsLayout = () => {
    const {setActiveLink} = useContext(UserHomeData);
    const [customerInfo] = useState(JSON.parse(sessionStorage.getItem("loggedUserInfo")));
    const [customerPartner,setCustomerPartner] = useState(false);

    useEffect(()=>{
        setActiveLink(5);
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    },[])

    useEffect(()=>{
      if(customerInfo?.locationDetails?.city?.toLowerCase()==="paris" || customerInfo?.locationDetails?.city?.toLowerCase()==="colville"){
        setCustomerPartner(true);
      }
      else{
        setCustomerPartner(false);
      }
    },[])

    const contactData = () => {
      switch(customerInfo.locationDetails.city.toLowerCase()){
        case "paris":
          return <div className={classes.contactDetailsContainer}>
            <div className={classes.contactDetailsRow}>
              <p className={classes.contactDetailsCol1}>Address:</p> 
              <div className={classes.addressDetailsContainer}>
                <p className={classes.contactDetailsCol2}>2270 NE Loop 286</p>
                <p className={classes.contactDetailsCol2}>Paris, TX 75460</p>
              </div>        
            </div>
            <div className={classes.contactDetailsRow}>
              <p className={classes.contactDetailsCol1}>Phone:</p>
              <p className={classes.contactDetailsCol2}>(903) 785-3474</p>
            </div>
            <div className={classes.contactDetailsRow}>
              <p className={classes.contactDetailsCol1}>Email:</p>
              <p className={classes.contactDetailsCol2}><a href="mailto: lamar.satellite@gmail.com">lamar.satellite@gmail.com</a></p>
            </div>
          </div>;
        case "colville":
          return <div className={classes.contactDetailsContainer}>
            <div className={classes.contactDetailsRow}>
              <p className={classes.contactDetailsCol1}>Address:</p> 
              <div className={classes.addressDetailsContainer}>
                <p className={classes.contactDetailsCol2}>156 N Main ST</p>
                <p className={classes.contactDetailsCol2}>Colville, WA 99114</p>
              </div>        
            </div>
            <div className={classes.contactDetailsRow}>
              <p className={classes.contactDetailsCol1}>Phone:</p>
              <p className={classes.contactDetailsCol2}>509-684-7897</p>
            </div>
            <div className={classes.contactDetailsRow}>
              <p className={classes.contactDetailsCol1}>Email:</p>
              <p className={classes.contactDetailsCol2}><a href="mailto: Sales@adept4u.com">Sales@adept4u.com</a></p>
            </div>
          </div>;
        default :
          return <div className={classes.contactDetailsContainer}>
            <div className={classes.contactDetailsRow}>
              <p className={classes.contactDetailsCol1}>Address:</p> 
              <div className={classes.addressDetailsContainer}>
                <p className={classes.contactDetailsCol2}>12800 Whitewater Drive</p>
                <p className={classes.contactDetailsCol2}> Suite 100</p>
                <p className={classes.contactDetailsCol2}>  Minnetonka, MN 55435</p>
              </div>        
            </div>
            <div className={classes.contactDetailsRow}>
              <p className={classes.contactDetailsCol1}>Phone:</p>
              <p className={classes.contactDetailsCol2}>(952) 236-7618</p>
            </div>
            <div className={classes.contactDetailsRow}>
              <p className={classes.contactDetailsCol1}>Email:</p>
              <p className={classes.contactDetailsCol2}><a href="mailto: info@synkro.us">info@synkro.us</a></p>
            </div>
          </div>;  
      }
    }

  return <div>
    <div className={classes.contactContainer}>
      <div className={`${customerPartner ? classes.contactTitleContainerCP : classes.contactTitleContainer}`}>
        Contact Us
      </div>
      {contactData()}
    </div>
  </div>;
};

export default ContactUsLayout;
