import React from "react";
import { Toast } from "react-bootstrap";
import './CustomToast.css';
import successIcon from "../../Assets/Images/success-toast-icon.png";
import errorIcon from "../../Assets/Images/error-toast-icon.png";

const CustomToast = ({show,delay, handleClose, type, message}) => {
  return (
    <Toast onClose={handleClose} show={show} delay={delay} autohide className="toastContainer">
    <Toast.Header className={`toastHeader ${type==="success" ? "successBg" : "errorBg"}`}>
        <img
        src={type==="success" ? successIcon : errorIcon}
        className="rounded me-2"
        alt=""
        />
        <strong className="me-auto">{type==="success"? "Success!" : "Error"}</strong>
    </Toast.Header>
    <Toast.Body className={`toastBody ${type==="success" ? "successBody" : "errorBody"}`}>
        {type==="success"? message : 
          <>{message}</>}

    </Toast.Body>
    </Toast>
  );
};

export default CustomToast;
