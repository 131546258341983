import React, {useEffect} from 'react';
import Button from 'react-bootstrap/Button'
import classes from './HowItWorkLayout.module.css';
import towerImage from '../../../Assets/Images/synkro-hiw-02.png';
import locationIcon from '../../../Assets/Images/location-icon.png';
import userIcon from '../../../Assets/Images/user-icon.png';
import checkedIcon from '../../../Assets/Images/checked-icon.png';
import { Link } from 'react-router-dom';
import Footbar from '../../../Components/Footbar/Footbar';


const HowItWorkLayout = () => {
    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);
  return <>
  <div>
    <div className={classes.section1}>
        <div className={classes.sec1InnerBlock}>
            <h1 className={classes.block1Title1}>Connecting rural America one home at a time</h1>
            <h3 className={classes.block1Title2}>How synKro’s 5G broadband Internet works.</h3>
        </div>
    </div>
    <div className={classes.section2}>
        <div className={classes.sec2InnerBlock1}>
            <h1 className={classes.block2Title}>Our technology</h1>
            <p>synKro’s proprietary 5G fixed wireless technology brings high-speed broadband Internet to areas 
                that traditional providers find hard to reach. Our service is superior to DSL, satellite, and 
                cellular offerings which can suffer from performance issues due to a lack of speed, latency 
                (delays), weather, and data caps. 
            </p>
            <p>From our powerful radio equipment on your nearby tower, we transmit a strong signal to the modem 
                and router we strategically place in your home. synKro packages a strong, reliable, and consistent 
                signal into an incomparable Internet experience.
            </p>
        </div>
        <div className={classes.sec2InnerBlock2}>
            <img src={towerImage} alt="" className={classes.towerImage}/>
        </div>
    </div>
    <div className={classes.section3}>
        <div className={classes.sec3InnerBlock1}>
            <h1 className={classes.sec3Title}>All it takes is three steps to get started:</h1>
        </div>
        <div className={classes.sec3InnerBlock2}>
            <div className={classes.column}>
                <img src={locationIcon} alt="location-icon" className={classes.iconStyle}/>
                <p className={classes.innerPara1}>1</p>
                <p className={classes.innerPara2}>Confirm Internet coverage at your address.</p>
            </div>
            <div className={classes.column}>
                <img src={userIcon} alt="location-icon" className={classes.iconStyle}/>
                <p className={classes.innerPara1}>2</p>
                <p className={classes.innerPara2}>Create your FREE account by selecting a service plan.</p>
            </div>
            <div className={classes.column}>
                <img src={checkedIcon} alt="location-icon" className={classes.iconStyle}/>
                <p className={classes.innerPara1}>3</p>
                <p className={classes.innerPara2}>Receive synKro installation from your local town technician.</p>
            </div>
        </div>
        <div className={classes.sec3InnerBlock3}>
            <Link to="/select-location">
            <Button className={classes.availabilityButton}>
                Check availability
            </Button>
            </Link>
        </div>
    </div>
  </div>
  <Footbar />
  </>;
};

export default HowItWorkLayout;
