import React from 'react'
import { useEffect } from 'react'
import { Container } from 'react-bootstrap';
import Footbar from '../../../Components/Footbar/Footbar';
import classes from './CareersLayout.module.css'

const CareersLayout = () => {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  return (<>
    <Container className={classes.aboutUsContainer}>
    <h2 >Carrers</h2>
    <hr />
        <p>We at synKro are always looking for ways to contribute to the communities in which we operate. 
            Opportunities for local installers and network support technicians are always available. 
            Other freelancing work may include marketing, sales and customer service help including part time 
            help from local high school students or retirees.
        </p>
        <p>If you are interested in working with the synKro team in your area, email us at <a href="mailto:info@synkro.us">info@synkro.us</a> with 
            your contact info and experience history. NO PHONE CALLS PLEASE.
        </p>
    </Container>
    <Footbar />
    </>
  )
}

export default CareersLayout