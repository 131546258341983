import React, {useState, useEffect, useContext} from 'react';
import { useParams } from "react-router-dom"; 
import classes from './Signup.module.css';
import { Container, Row, Button } from 'react-bootstrap';
import {useForm} from "react-hook-form";
import PropagateLoader from "react-spinners/PropagateLoader"; 
import StepProgressBar from "../../../Components/StepProgressBar/StepProgressBar";
import { Link } from 'react-router-dom';
import userServices from '../../../Services/UserServices';
import ContactFooter from '../../../Components/ContactFooter/ContactFooter';
import SignupData from '../../../Context/PublicUserContext';
import { convertToTitleCase } from '../../../Utils/commonUtils';
import Footbar from '../../../Components/Footbar/Footbar';

const Signup = () => {
    const {  register, handleSubmit, clearErrors, setError,trigger,reset, formState: { errors }} = useForm();
    const initialCityState = { city: "", state: "" };
    const [cityState, setCityState] = useState(initialCityState);
    const [zipcode, setZipcode] = useState("");
    const [formData, setFormData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [locationDataLoading, setLocationDataLoading] = useState(false);
    const [showAddress, setShowAddress] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [selectedLocation] = useState(sessionStorage.getItem("location")? JSON.parse(sessionStorage.getItem("location")): "" );
    const [bypassValidationCheck,setBypassValidationCheck] = useState(false);
    const [showBypassCheck, setShowBypassCheck] = useState(false);
    const [enteredAddress] = useState(sessionStorage.getItem("addressDetails")? JSON.parse(sessionStorage.getItem("addressDetails")): "" );
    const { type } = useParams();
    let percentComplete = 0;
    let prevPercentComplete = 0;
    const {setUserFirstName} = useContext(SignupData);
    
    useEffect(() => {        
        const fetchCityState = async () => {
          try {
            if(zipcode){
                setButtonDisabled(true);
                userServices.getStateFromZip(parseInt(zipcode.substring(0, 5)))
                .then((response)=>{
                    setButtonDisabled(false);
                    if(response.data.state){
                        let formattedCityState = {city: convertToTitleCase(response.data.city),
                        state: response.data.state
                    }
                        setCityState(formattedCityState);
                        trigger("cityState")
                    }
                    else{
                        setCityState({city:"",state:""})
                        trigger("cityState")
                        setError('zip',{type:'zip-error'})
                    }
                })
                .catch((err)=>{
                    setButtonDisabled(false);
                })
                // setCityState({...cityState, city: "Lamar", state: "MO"} )
            }            
          } catch (e) {
            console.log(e);
          }
        };
        if(/^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/.test(zipcode)){
            fetchCityState();
        }        
      }, [zipcode]);


      useEffect(() => {
        if(type === "edit" && enteredAddress){
            let enteredData = {
                address1: enteredAddress.Address1,
                address2: enteredAddress.Address2,
                // zip: enteredAddress.Zip5
            }
            reset(enteredData);
            setZipcode(`${enteredAddress.Zip5}${enteredAddress.Zip4?"-":""}${enteredAddress.Zip4?enteredAddress.Zip4:""}`)
        }
        else if(enteredAddress){
            let enteredData = {
                address1: enteredAddress.Address1,
                address2: enteredAddress.Address2
            }
            reset(enteredData);
            setZipcode(`${enteredAddress.Zip5}${enteredAddress.Zip4?"-":""}${enteredAddress.Zip4?enteredAddress.Zip4:""}`)
        }
        else{
            reset("");
            sessionStorage.setItem("addressDetails","");
            sessionStorage.setItem("selectedPackage","");
            sessionStorage.setItem("customerId","");
            setUserFirstName("");
            sessionStorage.setItem("termsAgreed",JSON.stringify(false))
        }
        
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);

      useEffect(()=>{
        if(bypassValidationCheck){
            let sessionData = {
                Address1:formData.address1 ,
                Address2: formData.address2,
                City: cityState.city,
                State: cityState.state,
                Zip5: parseInt(zipcode.substring(0, 5)),
                Zip4: zipcode.substring(6, 10) ? parseInt(zipcode.substring(6, 10)):""
            }
            sessionStorage.setItem("addressDetails",JSON.stringify(sessionData));
            setShowAddress(true);

        }
        else if(type !== "edit" && !enteredAddress){
            sessionStorage.setItem("addressDetails","");
        }
      },[bypassValidationCheck])

    const onSubmit = data => {
        setIsLoading(true);
        setButtonDisabled(true);
        setShowAddress(false);
        let postData = {
            Address1:data.address1 ,
            Address2: data.address2,
            City: cityState.city,
            State: cityState.state,
            Zip5: parseInt(zipcode.substring(0, 5)),
            Zip4: zipcode.substring(6, 10) ? parseInt(zipcode.substring(6, 10)):""
        }
        userServices.validateAddress(postData)
        .then((response)=>{
            let verifyData = {
                latitude: response.data.latitude,
                longitude: response.data.longitude
            }
            if(response.data.latitude){
                userServices.verifyLocation(selectedLocation.id,verifyData)
                .then((res)=>{
                    setFormData(response.data);
                    let addressData = {
                        ...postData,
                        latitude: response.data.latitude,
                        longitude: response.data.longitude
                    }
                    sessionStorage.setItem("addressDetails",JSON.stringify(addressData));
                    setButtonDisabled(false);
                    setIsLoading(false);
                    setShowAddress(true);
                })
                .catch((err)=>{
                    if(cityState.city.toLowerCase() === "wink"){
                        setShowBypassCheck(true);
                        let winkDataEntered = {
                            address1:data.address1 ,
                            address2: data.address2,
                            city: cityState.city,
                            state: cityState.state,
                            zip5: parseInt(zipcode.substring(0, 5)),
                            zip4: zipcode.substring(6, 10) ? parseInt(zipcode.substring(6, 10)):""  
                        }
                        setFormData(winkDataEntered);
                        loadWinkLocationData()
                    }
                    setError('address1', { type: 'validationError', 
                    message: 'The address provided is outside of the selected Service Location. Please enter different address or select new Service Location.'})
                    window.scrollTo({top: 100, left: 0, behavior: 'smooth'});
                    setButtonDisabled(false);
                    setIsLoading(false);
                })
            }
            else{
                if(cityState.city.toLowerCase() === "wink"){
                    setShowBypassCheck(true);
                    let winkDataEntered = {
                        address1:data.address1 ,
                        address2: data.address2,
                        city: cityState.city,
                        state: cityState.state,
                        zip5: parseInt(zipcode.substring(0, 5)),
                        zip4: zipcode.substring(6, 10) ? parseInt(zipcode.substring(6, 10)):""  
                    }
                    setFormData(winkDataEntered);
                    loadWinkLocationData();
                }
                setError('address1', { type: 'validationError', 
                message: 'Unable to validate address as entered. Please review.'})
                window.scrollTo({top: 100, left: 0, behavior: 'smooth'});
                setButtonDisabled(false);
                setIsLoading(false); 
            }
            
        })
        .catch((err)=>{
            if(cityState.city.toLowerCase() === "wink"){
                setShowBypassCheck(true);
                let winkDataEntered = {
                    address1:data.address1 ,
                    address2: data.address2,
                    city: cityState.city,
                    state: cityState.state,
                    zip5: parseInt(zipcode.substring(0, 5)),
                    zip4: zipcode.substring(6, 10) ? parseInt(zipcode.substring(6, 10)):""  
                }
                setFormData(winkDataEntered);
                loadWinkLocationData();
            }
            setError('address1', { type: 'validationError', 
                message: 'Unable to validate address as entered. Please review.'})
            window.scrollTo({top: 100, left: 0, behavior: 'smooth'});
            setButtonDisabled(false);
            setIsLoading(false);
        }) 
      };
      
    const loadWinkLocationData = () =>{
        userServices.getLocations()
        .then((response)=>{
            let locationSelected = response.data;
            locationSelected = locationSelected.find((item)=>item.city?.toLowerCase() === "wink")
            sessionStorage.setItem("location",JSON.stringify(locationSelected));
        })
    }
    const zipChangeHandler = (e) =>{
        let { value } = e.target;
        if(value.length === 6){
            value = value.replace(/[^0-9-]/g,'');
        }
        else{
            value = value.replace(/[^0-9]/g,'');
        }
        if(value.length ===5 || value.length === 9){
            clearErrors('zip');
        }
        else{
            setError('zip',{type:'zip-error'})
        }
        
        setZipcode(value.replace(/(\d{5})(\d)/, "$1-$2").substr(0, 10));
    }

  return (
    <>
    {locationDataLoading? <div className={classes.signupContainer}>
        <div className={classes.loadingDiv}>
            <PropagateLoader color="#E84E3B" size={14}/>
        </div>
        </div>:
    <Container fluid className={classes.signupContainer}>
        <h2 className={classes.signupTitle}>Sign Up for Broadband Internet</h2>
        <p className={classes.para1}>{selectedLocation?.city}, {selectedLocation?.state} and surrounding area.</p>
        <p className={classes.para2}>Not in {selectedLocation?.city}? <a href='/select-location'>Find your town.</a></p>
        <StepProgressBar percentComplete={percentComplete} prevPercentComplete={prevPercentComplete} active="1" />
        <form onSubmit={handleSubmit(onSubmit)}>
        <Container className={classes.formContainer}>
        <div className={classes.errorWarning}>
            {errors.address1?.type === "validationError" && <p>{errors.address1.message}</p>}
            {errors.address1?.type === 'required' && <p>*Please check fields outlined below*</p>}
        </div>
        <Row>
           <label> Address 1</label>
        </Row>
        <Row>
            <input type="text" 
                name="address1" 
                placeholder='Street Address' 
                className={errors.address1 ? classes.addressInputError : classes.addressInput}
                {...register("address1", {required: true, message:"please check fields outlined below"})}/>
        </Row>
        <Row>
            <label>Address 2</label>
        </Row>
        <Row>
            <input type="text" name="address2" placeholder='Apartment/Unit/Suite Number' className={classes.addressInput}
             {...register("address2")} />
        </Row>
        <Row>
           <label> Zipcode</label>
        </Row>
        <Row>
            <input type="text" 
                name='zip' 
                value={zipcode || ""} 
                placeholder='##### - ####' 
                className={errors.zip ? classes.addressInputError : classes.addressInput} 
                {...register("zip", {required: true,
                     pattern:/^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/
                })}
                onChange={zipChangeHandler}
                  />
        </Row>
        <Row>
           <label> City, State </label>
        </Row>
        <Row>
            <input type="text" 
                name="cityState" disabled 
                placeholder='City, State will prefill based on Zip Entered' 
                value={cityState.city? cityState.city +", "+ cityState.state : ""}
                className={classes.addressInput}
            />
        </Row>
        {showBypassCheck &&
        <div className={`mb-2 ${classes.checkBoxContainer}`}>
            <input type="checkbox" value={bypassValidationCheck} checked={bypassValidationCheck} onChange={()=>{setBypassValidationCheck(!bypassValidationCheck)}}/>
            <label className="ms-2">Address is correct. Use the address above as my service address.</label>
        </div>}
        <Button type='submit' className={classes.submitButton} disabled={buttonDisabled}>Check if you are in the service area</Button>
        </Container>
        </form>
        <div className={classes.validatedAddressOuterContainer}>
        {isLoading? <PropagateLoader
              color="#E84E3B"
              size={14}
            />:
        <>
        {showAddress && 
        <>
        <section>
            <h2 className={classes.para3}>Choose Your Service Address Below or &nbsp;
                <a href="/select-location" className={classes.changeAddressLink}>Try Another Address</a>
            </h2>
        </section>
        <section className={classes.validateAddressContainer}>
            <Link to="/packages">
               <Button className={classes.validateButton}>
                {convertToTitleCase(formData?.address1)} {formData?.address1?",": null} 
                {convertToTitleCase(formData?.address2)} {formData?.address2?",": null} {cityState?.city} {cityState?.state} {formData?.zip}</Button> 
            </Link>
        </section>
        </>
        }
        </>}
        </div>
    </Container>}
    <ContactFooter isDark={false} />
    <Footbar />
    </>
  )
}

export default Signup;