import React, { useEffect, useContext, useState } from "react";
import UserHomeData from "../../../Context/UserAccountContext";
import classes from "./MyPackageDetails.module.css";
import { Link } from "react-router-dom";
import LoginServices from "../../../Services/LoginServices";
import { PropagateLoader } from "react-spinners";

const MyPackageDetails = () => {
    const {setActiveLink,accountStatus,userAccountInfo} = useContext(UserHomeData);
    const [currentPackageInfo, setCurrentPackageInfo] = useState();
    const [loading, setLoading] = useState(true);
    const [customerPartner,setCustomerPartner] = useState(false);

    useEffect(()=>{
        setActiveLink(3.1);
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        getCurrentPackage();
    },[])

    useEffect(()=>{
      if(userAccountInfo?.locationDetails?.city?.toLowerCase()==="paris" || userAccountInfo?.locationDetails?.city?.toLowerCase()==="colville"){
        setCustomerPartner(true);
      }
      else{
        setCustomerPartner(false);
      }
    },[userAccountInfo])

    const getCurrentPackage = () => {
      LoginServices.getCustomerPackages()
      .then((resp)=>{
        setCurrentPackageInfo(resp.data[0]);
        setLoading(false);
      })
      .catch((err)=>{
        setLoading(false);
      })
    }

    const handleAccountStatus = () =>{
      switch(accountStatus.statusString)
      {
        case "paid_up":
        case "past_due":
        case "due":
          return <span className={classes.serviceStatusGreen}>Active</span>;
        case "suspended":
        case "hibernated":
          return <span className={classes.serviceStatusRed}>Deactivated</span>;

        case "prospect":
          return <span className={classes.serviceStatusPending}>Pending Installation</span>;

        default:
          return <span className={classes.serviceStatusGreen}>Active</span>;
      }
    }

  return <div className={classes.pckgDetailsOuterContainer}>
    <nav className={`${customerPartner ? classes.pckgDetailsNavCP : classes.pckgDetailsNav}`}>
      <h2 className={classes.navBarTitle}>My synKro Packages</h2>
    </nav>
    {loading?<div className={classes.loadingDiv}>
        <PropagateLoader color={`${customerPartner? "#306AC0" : "#E84E3B"}`} size={14}/>
      </div>:
    <div className={classes.pckgDetailsInnerContainer}>
      <div className={classes.row1}>
        <h4>Thank you for being a synKro <span className={classes.serviceType}>Internet</span> customer</h4>
        <p>Your service is currently: {handleAccountStatus()}</p>
      </div>
      <div className={classes.row2}>
        <p>You are currently enrolled in our: <span>{currentPackageInfo?.name}</span> for $ <span>{currentPackageInfo?.monthlyFee}</span> /month</p>
      </div>
      <div className={classes.row3}>
        <div className={classes.pckgDescriptionDiv}>
        <h4>Package Description:</h4>
        <p>
          {currentPackageInfo?.description}
        </p>
        </div>
      </div>
      <div className={`${customerPartner? classes.row4CP : classes.row4}`}>
        <h5>Ready for a change? </h5>
        <p>Check out the other packages and optional add-ons we have available to further enhance your 
          broadband experience <Link to="/service/update-package">here</Link>.</p>
      </div>
    </div>}
  </div>;
};

export default MyPackageDetails;
