import axios from 'axios';
import { Buffer } from 'buffer';
import dayjs from 'dayjs';
import jwt_decode from "jwt-decode";
import queryString from 'query-string';

const baseurl = "/api"

const axiosLoginInstance = axios.create({
    baseURL: baseurl
})


axiosLoginInstance.interceptors.request.use(async (request)=>{
    let access_token = sessionStorage.getItem("accessToken");
    request.headers.Authorization=`Bearer ${access_token}`

    const expiry = jwt_decode(access_token);
    const isExpired = dayjs.unix(expiry.exp).diff(dayjs()) <1;
    if(!isExpired) return request;
    try {
        const newData = await refreshAuthToken()
        sessionStorage.setItem("accessToken",newData?.data?.access_token);
        sessionStorage.setItem("refreshToken",newData?.data?.refresh_token);
        request.headers.Authorization=`Bearer ${newData?.data?.access_token}`
    } catch (error) {
       if(error.response.status===400){
            window.location.replace(`${process.env.REACT_APP_PROD_URL}/customer-login`)
        } 
    }      
    return request;
})

axiosLoginInstance.interceptors.response.use(async (response)=>{
      return response;
},async (error)=>{
    if (error.response) {
        // Handle error responses
        if(error.response.status===401){
            window.location.replace(`${process.env.REACT_APP_PROD_URL}/customer-login`)     
        }
        else if(error.response.status===500){
            window.location.assign(`${process.env.REACT_APP_PROD_URL}/error`)     
        }
        return Promise.reject(error);
    } else if (error.request) {
        // Handle network errors
        window.location.assign(`${process.env.REACT_APP_PROD_URL}/error`)
        return Promise.reject(error);
      }
    else{
        return Promise.reject(error);
    }
    
})

const refreshAuthToken = async () =>{
    const username=process.env.REACT_APP_USER_CLIENT_ID;
    const password = process.env.REACT_APP_USER_CLIENT_SECRET;
    const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
    const data = {
        grant_type:"refresh_token",
        refresh_token: sessionStorage.getItem("refreshToken")
    }
    return axios.post(`${baseurl}/Auth/Token`, queryString.stringify(data),{
        headers: {
          'Authorization': `Basic ${token}`
        }})
}

const beginAuthorization = (data) =>{
    return axios.get(`${baseurl}/Auth/Authorize`, { params: data })
}

const customerLogin = (data) =>{
    return axios.post(`${baseurl}/Auth/Login`, data)
}

const getAuthToken = (data, username,password) =>{
    const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
    return axios.post(`${baseurl}/Auth/Token`, queryString.stringify(data),{
        headers: {
          'Authorization': `Basic ${token}`
        }})
}

const customerLogout = (data) =>{
    return axiosLoginInstance.post(`/Auth/Logout`, data)
}

const getUserInfo = () =>{
    return axiosLoginInstance.get(`/Account/UserInfo`)
}

const updatePassword = (data) =>{
    return axiosLoginInstance.put(`/Account/ChangePassword`,data)
}

const getAccountInfo = () =>{
    return axiosLoginInstance.get(`/Account/AccountInformation`)
}

const updateAccountInfo = (data) =>{
    return axiosLoginInstance.put(`/Account/AccountInformation`, data)
}

const getAccountStatus = () =>{
    return axiosLoginInstance.get(`/Account/UserStatus`)
}
const forgetPassword = (data) => {
    return axios.post(`${baseurl}/Account/ForgetPassword`,data)
}
const forgetUsername = (data) => {
    return axios.post(`${baseurl}/Account/ForgetUsername`,data)
}

const resetPassword = (data) =>{
    return axiosLoginInstance.post(`/Account/ResetForgetPassword`,data)
}
const updateAccountData = (data) => {
    return axiosLoginInstance.patch(`/Account/AccountInformation`,data)
}
const getBillingInfo = () => {
    return axiosLoginInstance.get(`/Account/BillingInformation`)
}

const addCreditCard = (data) =>{
    return axiosLoginInstance.post(`/Account/BillingInformation/CreditCard`,data)
}

const updateCreditCard = (data, key) => {
    return axiosLoginInstance.put(`/Account/BillingInformation/CreditCard`,data,{ params: {key:key} })
}

const getCurrentStatement = () => {
    return axiosLoginInstance.get(`/Account/BillingInformation/Statement/Current`)
}

const getPaidStatement = (currPage, limit) => {
    return axiosLoginInstance.get(`/Account/BillingInformation/Statements?offset=${currPage}&limit=${limit}`)
}
const getCustomerPackages = () =>{
    return axiosLoginInstance.get(`/Account/CustomerPackages`)
}
const upgradePackage = (data) => {
    return axiosLoginInstance.put(`/Account/CustomerPackages`,data)
}

const getStatements = async (date) => {
    return axiosLoginInstance.get(`/Account/StatementPDF?startDate=${date}&endDate=${date}`,{
        responseType: "blob"
      })
}

const getStatementPdf = async (key,type) =>{
    return axiosLoginInstance.get(`/Account/PDF?key=${key}&type=${type}`,{
        responseType: "blob"
      })
}

const getPackageUpgradeRequests = () => {
    return axiosLoginInstance.get(`/Account/CustomerPackagesRequests`)
}

const deletePackageUpgradeRequests = (data) => {
    return axiosLoginInstance.delete(`/Account/CustomerPackages`,{ data: data })
}

const LoginServices = {
    axiosLoginInstance,
    beginAuthorization,
    customerLogin,
    getAuthToken,
    // getRefreshToken,
    customerLogout,
    getUserInfo,
    updatePassword,
    getAccountInfo,
    updateAccountInfo,
    getAccountStatus,
    forgetPassword,
    forgetUsername,
    resetPassword,
    updateAccountData,
    getBillingInfo,
    addCreditCard,
    updateCreditCard,
    getCurrentStatement,
    getPaidStatement,
    getCustomerPackages,
    upgradePackage,
    getStatements,
    getStatementPdf,
    getPackageUpgradeRequests,
    deletePackageUpgradeRequests
}

export default LoginServices;
