import axios from "axios";
import React, {createContext, useState, useEffect} from "react";

const UserAccountContext = createContext();


export function UserHomeData({children}){
    
    const acc_token = sessionStorage.getItem("accessToken");
    const ref_token = sessionStorage.getItem("refreshToken");
    const statusFromSession = sessionStorage.getItem("accountStatus")?JSON.parse(sessionStorage.getItem("accountStatus")):"";
    const [accountStatus, setAccountStatus] = useState(statusFromSession);    
    const [activeLink, setActiveLink] = useState("");
    const [userAuthToken, setUserAuthToken] = useState(acc_token);
    const [userRefreshToken, setUserRefreshToken] = useState(ref_token);
    const loggedUserInfo = sessionStorage.getItem("loggedUserInfo")? JSON.parse(sessionStorage.getItem("loggedUserInfo")) : null;
    const [userAccountInfo, setUserAccountInfo] = useState(loggedUserInfo);
    const [showExpiryPopup,setShowExpiryPopup] = useState(false);


    const contextData = {
        activeLink, 
        setActiveLink, 
        userAuthToken, 
        setUserAuthToken,
        userRefreshToken,
        setUserRefreshToken,
        accountStatus,
        setAccountStatus,
        userAccountInfo,
        setUserAccountInfo,
        showExpiryPopup,
        setShowExpiryPopup
    }
    
    return(
        <UserAccountContext.Provider value={contextData}>{children}</UserAccountContext.Provider>
    );
}

export default UserAccountContext;