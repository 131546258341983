import React  from 'react';
import {Link,} from 'react-router-dom';
import {Navbar, Container, Nav, NavDropdown} from 'react-bootstrap';
import Logo from '../../Assets/Images/synKro_Giga_Logo.png';
import MobileLogo from '../../Assets/Images/synkro-logo2.png';
import classes from './AdminNavBar.module.css';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { useIsAuthenticated } from "@azure/msal-react";

const AdminNavBar = () => {
    const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  function handleLogin(instance) {
    instance.loginRedirect(loginRequest).catch(e => {
        console.error(e);
    });
}
function handleLogout(instance) {
  instance.logoutRedirect().catch(e => {
      console.error(e);
  });
}
  return (
    <Navbar expand="md" className={classes.navigationBar}>
      <Container fluid className={classes.navbarContainer}>
        <Link to="#">
          <Navbar.Brand>
            <img src={Logo} className={classes.brandLogo} alt='logo'/>
            <img src={MobileLogo} className={classes.mobileLogo} alt='logo'/>
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className={classes.navMenubar}>
          <p className={classes.welcomeText}>Welcome, HelpEditor Administrator</p>
          {/* <Link to="#"><p className={classes.navbarLink}>My Account</p></Link> 
          <span className={classes.dividerBar}>|</span>
          <Link to="#"><p className={classes.navbarLink}>Help</p></Link> 
          <span className={classes.dividerBar}>|</span> */}
            {isAuthenticated ? <p className={classes.navbarLink} onClick={() => handleLogout(instance)}>Logout</p>:
             <p className={classes.navbarLink} onClick={() => handleLogin(instance)}>Login</p>
            }
          </Nav>
        </Navbar.Collapse>
      </Container>
      </Navbar>
  )
};

export default AdminNavBar;
