import React, {useState} from "react";
import { Accordion, Row, Button, Table } from "react-bootstrap";
import {useForm} from "react-hook-form";
import services from "../../../Services/UserServices";
import CustomToast from "../../../Components/CustomToast/CustomToast";
import "./AnnouncementsLayout.css";
import { useEffect } from "react";
import { convertISOtoDDMMYYYY } from "../../../Utils/commonUtils";
import ReactDatePicker from "react-datepicker";

const AnnouncementsAccordion = () => {
    const {  register, handleSubmit,reset,setFocus, setError, formState: { errors }} = useForm();
    const [showToast, setShowToast] = useState(false);
    const [toastType, setToastType] = useState();
    const [toastMessage, setToastMessage] = useState("");
    const [currentAnnouncement, setCurrentAnnouncement] = useState({});
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [startDateError, setStartDateError] = useState(false);
    const [endDateError, setEndDateError] = useState(false);
    const [dateErrorMessage, setDateErrorMessage] = useState("Please Enter a Valid Start Date / End Date");
    const today = new Date();

    useEffect(()=>{
      services.getAnnouncements()
      .then((response)=>{
        setCurrentAnnouncement(response.data)

      })
    },[toastType])

    useEffect(()=>{
      if(startDate){
        setStartDateError(false);
      }
      if(endDate){
        setEndDateError(false);
      }

    },[startDate, endDate])

    useEffect(()=>{
      
      if(endDate){
        setEndDate();
      }

    },[startDate])



    const onSubmit =(data)=>{
      let formattedStartDate = fixTimezoneOffset(startDate);
      let formattedEndDate = fixTimezoneOffset(endDate);
      if(startDate && endDate){
        let formattedData = {
            AnnouncementURL:data.announcementUrl,
            Announcement: data.announcement,
            StartDate: formattedStartDate,
            EndDate: formattedEndDate
        }
        services.createAnnouncement(formattedData)
        .then((resp)=>{
          setShowToast(true);
          setToastType("success")
          setToastMessage("Your new announcement has been successfully created!")
          reset()
          setStartDate()
          setEndDate()
          setStartDateError(false);
          setEndDateError(false);
        })
        .catch((err)=>{
          if(err.response.data.errorCode==="E:AN-0" || err.response.data.errorCode === "E:AN-1"){
            setEndDateError(true);
            setStartDateError(true);
            setDateErrorMessage(err.response.data.localizedErrorMessage);
            window.scrollTo({top: 100, left: 0, behavior: 'smooth'});
          }
          else{
            setShowToast(true);
            setToastType("error")
            setToastMessage("There was an error creating announcement.Please try again.")
          }
        })
      }
        else if(startDate && !endDate){
          setEndDateError(true);
          window.scrollTo({top: 100, left: 0, behavior: 'smooth'});

        }
        else if(!startDate && endDate){
          setStartDateError(true);
          window.scrollTo({top: 100, left: 0, behavior: 'smooth'});

        }
        else{
          setStartDateError(true);
          setEndDateError(true);
          window.scrollTo({top: 100, left: 0, behavior: 'smooth'});

        }
        
    }
    const closeToast = () =>{
      setShowToast(false);
  }
    const deleteAnnouncement = (current) =>{
      services.deleteAnnouncement(current.id)
      .then((resp)=>{
        setCurrentAnnouncement({})
      })
    }
  const fixTimezoneOffset = (date) => {
    if(!date) return "";
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
  }

  return <div className="announcementaccordianContainer">
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Create New Announcement</Accordion.Header>
        <Accordion.Body>
            <div className="createAnnouncementForm">
            <form onSubmit={handleSubmit(onSubmit)}>
              {(startDateError || endDateError) && 
              <p className="conflictErrorMessage">{dateErrorMessage}</p>}
                <Row className="ms-1">
                    <label>Start Date*</label>
                </Row>
                <Row  className="ms-1">
                  <ReactDatePicker selected={startDate}
                    placeholderText='Select Date' 
                    onChange={(date) => setStartDate(date)} 
                    dateFormat="dd/MM/yyyy" 
                    className={`${startDateError ? "datePickerError":"datePickerStyle"}`}
                    minDate={today}/>
                </Row>
                <Row  className="ms-1">
                    <label>End Date*</label>
                </Row>
                <Row  className="ms-1">
                  <ReactDatePicker selected={endDate}
                    placeholderText='Select Date' 
                    onChange={(date) => setEndDate(date)} 
                    dateFormat="dd/MM/yyyy" 
                    className={`${endDateError ? "datePickerError":"datePickerStyle"}`}
                    disabled={!startDate}
                    minDate={startDate}/>
                </Row>
                <Row  className="ms-1">
                    <label>Announcement Text*</label>
                </Row>
                <Row className="ms-1">
                    <textarea 
                        name="announcement"
                        rows="4"
                        placeholder="Write your message here"
                        className={`${errors.announcement ? "InputError" : ""}`}
                        {...register("announcement", {required: true})}/>
                </Row>
                <Row className="ms-1">
                    <label>Announcement URL</label>
                </Row>
                <Row className="ms-1 mb-2">
                    <input type="text" 
                        name="announcementUrl" 
                        placeholder='Enter URL' 
                        className={`${errors.announcementUrl ? "InputError" : ""}`}
                        {...register("announcementUrl")}/>
                </Row>
                <Row className="ms-1">
                    <CustomToast show={showToast} delay={5000} message={toastMessage} handleClose={closeToast} type={toastType} />
                </Row>
                <div className="createButtonContainer">
                    <Button type='submit' className="createAnnouncement">Create Announcement</Button>
                </div>
            </form>
            </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Current Announcements</Accordion.Header>
        <Accordion.Body>
        <Table bordered responsive="lg">
        <thead>
          <tr>
            <th className="actionColumn">Action:</th>
            <th className="durationColumn">Duration:</th>
            <th className="messageColumn">Message:</th>
            <th className="urlColumn">URL:</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {currentAnnouncement && <>
              {/* <span className="announcementAction">Edit</span> &nbsp;|  */}
            &nbsp;<span className="announcementAction" onClick={()=>deleteAnnouncement(currentAnnouncement)}>Discontinue</span>
            </>}</td>
            <td>{convertISOtoDDMMYYYY(currentAnnouncement?.startDate)} - <br />
            {convertISOtoDDMMYYYY(currentAnnouncement?.endDate)}</td>
            <td>{currentAnnouncement.announcement}</td>
            <td className="urlContent">
              <a href={currentAnnouncement.announcementURL}>
                {currentAnnouncement.announcementURL}
              </a></td>
          </tr>
        </tbody>
        </Table> 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Future Announcements</Accordion.Header>
        <Accordion.Body>
        Future Announcements Coming Soon
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  </div>;
};

export default AnnouncementsAccordion;
