import React, {useState, useEffect, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import {Navbar, Container, Nav, Button} from 'react-bootstrap';
import Logo from '../../Assets/Images/synKro_Giga_Logo.png';
import lamarWirelessLogo from '../../Assets/Images/lamar_wireless_logo.png';
import adeptLogo from '../../Assets/Images/adept_internet_solution_logo.png';
import MobileLogo from '../../Assets/Images/synkro-logo2.png';
import classes from './CustomHomeNavbar.module.css';
import userServices from '../../Services/UserServices';
import { loginUrls } from '../../Utils/loginUrls';
import SignupData  from '../../Context/PublicUserContext';

const CustomHomeNavBar = () => {
    const navigate = useNavigate();
    const { customerPartner } = useContext(SignupData);
    const [confirmPwdShown, setConfirmPwdShown] = useState(false);
    const [loginUrl, setLoginUrl] = useState("");
    const [loading, setLoading] = useState(true);
    const currentUrl = window.location.host;
    const [selectedLocation,setSelectedLocation] = useState("");

    useEffect(()=>{
      userServices.getLocations()
      .then((response)=>{
          let locationSelected = response.data;
  // For Dev instance on local -  CHANGETODEPLOY - REMOVE
          // locationSelected = locationSelected.find((item)=>item?.city?.toLowerCase()==="paris") 
          locationSelected = locationSelected.filter((item)=>item.city?.toLowerCase() !== "wink")
          locationSelected = locationSelected.find((item)=>item.multiTenantDomain===currentUrl)
          setSelectedLocation(locationSelected);
          let filteredLoginUrl = loginUrls.find((item)=>item?.city?.toLowerCase()===locationSelected?.city?.toLowerCase());
          setLoginUrl(filteredLoginUrl.loginUrl);
          setLoading(false);
      })
      .catch((err)=>{
        setLoading(false);
      })
    },[])

    const handleLogo = () =>{
      switch(selectedLocation?.city?.toLowerCase()){
        case "paris":
          return <img src={lamarWirelessLogo} className={classes.brandLogo} alt='logo' />;
        case "colville":
          return <img src={adeptLogo} className={classes.brandLogo} alt='logo'/>;
        default :
          return <img src={Logo} className={classes.brandLogo} alt='logo'/>;
      }
    }

    const handleConfirmPwdView = () => {
        setConfirmPwdShown(!confirmPwdShown);
      }

   

    const redirectLogin =async ()=>{
      // REMOVE BEFORE DEPLOYMENT
      // navigate('/customer-login')
      window.location.assign(`${process.env.REACT_APP_PROD_URL}/customer-login`);
    }
      
  return <>{!loading ? <Navbar expand="md" className={classes.navigationBar}>
  <Container fluid className={classes.navbarContainer}>
      <Navbar.Brand >
        <div className={classes.brandLogoContainer}>{handleLogo()}</div>
        <img src={MobileLogo} className={classes.mobileLogo} alt='logo'/>
      </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className={classes.navMenubar}>
        <div className={classes.buttonContainer}>
          <Button className={classes.myAccountButton} onClick={redirectLogin}>My Account</Button>
          <p className={`${customerPartner? classes.customPara2: classes.para2}`}>
            NOT IN {selectedLocation?.city?.toUpperCase()}? <a href='/select-location'>FIND YOUR TOWN.</a>
          </p>
        </div>
      </Nav>
    </Navbar.Collapse>
  </Container>
  </Navbar> : <div></div>};
  </>
};

export default CustomHomeNavBar;
