import React, { useState, useEffect, useContext } from "react";
import classes from "./UpdatePassword.module.css";
import UserHomeData from "../../../Context/UserAccountContext";
import { Button, InputGroup } from "react-bootstrap";
import LoginServices from "../../../Services/LoginServices";
import CustomToast from "../../../Components/CustomToast/CustomToast";
import { PropagateLoader } from "react-spinners";
import { useForm } from "react-hook-form";
import EyeIcon from "../../../Assets/SVGIcons/EyeIcon";
import EyeSlashIcon from "../../../Assets/SVGIcons/EyeSlashIcon";
import { useNavigate } from "react-router-dom";
import DefaultCheckSquare from "../../../Assets/SVGIcons/DefaultCheckSquare";
import SuccessCheck from "../../../Assets/SVGIcons/SuccessCheck";
import ErrorCheck from "../../../Assets/SVGIcons/ErrorCheck";

const UpdatePassword = () => {
  const {setActiveLink, userAccountInfo} = useContext(UserHomeData);
  const navigate = useNavigate();
  const {  register, handleSubmit,reset, setError,clearErrors, formState: { errors }} = useForm();
  const [enableEdit, setEnableEdit] = useState(false);
  const [currPassword, setCurrPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPwd, setConfirmNewPwd] = useState("");
  const [loggedUserInfo] = useState(sessionStorage.getItem("loggedUserInfo")?JSON.parse(sessionStorage.getItem("loggedUserInfo")):"");
  const [showPopup, setShowPopup] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("")
  const [updateLoading, setUpdateLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPwdShown, setConfirmPwdShown] = useState(false);
  const [currentPwdShown,setCurrentPwdShown] = useState(false);
  const [passwordAccepted,setPasswordAccepted] = useState(false);
  const [confirmPwdAccepted,setConfirmPwdAccepted] = useState(false);
  const [customerPartner,setCustomerPartner] = useState(false);
  const [pwdValidations, setPwdValidations] = useState({
    validation1: false,
    validation2: false,
    validation3: false,
    validation4: false,
    validation5: false,
  });
  const [defaultPwdCheck, setDefaultPwdCheck] = useState({
    validation1: true,
    validation2: true,
    validation3: true,
    validation4: true,
    validation5: true,
  });

  useEffect(()=>{
    setActiveLink(1.2);
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    if(newPassword){
      checkPwdValidation(newPassword);
    }
  },[])

  useEffect(()=>{
    if(userAccountInfo?.locationDetails?.city?.toLowerCase()==="paris" || userAccountInfo?.locationDetails?.city?.toLowerCase()==="colville"){
      setCustomerPartner(true);
    }
    else{
      setCustomerPartner(false);
    }
  },[userAccountInfo])

  useEffect(()=>{
      if(pwdValidations.validation1 && pwdValidations.validation2 && pwdValidations.validation3 && pwdValidations.validation4 && pwdValidations.validation5){
        clearErrors("password1")
        setPasswordAccepted(true);
        if(newPassword === confirmNewPwd){
          setConfirmPwdAccepted(true);
          clearErrors("password2");
        }
        else if(confirmNewPwd && newPassword !== confirmNewPwd){
          setConfirmPwdAccepted(false);
          setError("password2",{type:"pwdCriteriaFailed"});
        }
      }
      else{
        if(newPassword){
          setError("password1",{type:"pwdCriteriaFailed"})
          setPasswordAccepted(false);
        }    
      }
    },[pwdValidations])


  const handleUpdate = (data) =>{
    if(!enableEdit) setEnableEdit(true);
    let updateData = {
      OldPassword: currPassword,
      NewPassword: newPassword
    }
    if(enableEdit && passwordAccepted && confirmPwdAccepted && currPassword){
      setUpdateLoading(true);
      LoginServices.updatePassword(updateData)
      .then((resp)=>{
        setCurrPassword("");
        setNewPassword("");
        setConfirmNewPwd("");
        setUpdateLoading(false);
        setEnableEdit(false);
        setShowPopup(true);
        setToastType("success")
        setToastMessage("Password has been updated successfully!")
      })
      .catch((err)=>{
        if(err.response.statusText==="Unauthorized"){
          setShowPopup(true);
          setToastType("error")
          setToastMessage("Your session has expired. Please Login.");
        }
        else{
          setShowPopup(true);
          setToastType("error")
          setToastMessage("There was an error updating password. Please check the password and try again.")
        }
        setUpdateLoading(false);        
      })
    }    
  }

  const closeToast = () => {
    setShowPopup(false);
    if(toastMessage==="Your session has expired. Please Login."){
      navigate("/customer-login");
    }
  }

  const handleCurrPwdView = () => {
    if(enableEdit){
      setCurrentPwdShown(!currentPwdShown);
    }
  }
  const handlePwdView = () => {
    setPasswordShown(!passwordShown);
  }

  const handleConfirmPwdView = () => {
    setConfirmPwdShown(!confirmPwdShown);
  }

  const handlePwdChange = (e) =>{
    let { value } = e.target;
    setNewPassword(value);
    if(value.length>0){
      setDefaultPwdCheck({
        validation1: false,
        validation2: false,
        validation3: false,
        validation4: false,
        validation5: false,})
    }
    else{
      setDefaultPwdCheck({
        validation1: true,
        validation2: true,
        validation3: true,
        validation4: true,
        validation5: true,
      })
    }
    checkPwdValidation(value);
  }

  const handleConfirmPwdChange = (e) =>{
    let { value } = e.target;
    setConfirmNewPwd(value);
    if(value.length>0 && value===newPassword){
      setConfirmPwdAccepted(true);
      clearErrors("password2");
    }
    else if(value.length>0 && value!==newPassword){
      setConfirmPwdAccepted(false);
      setError("password2",{type:"pwdCriteriaFailed"})
    }
  }

  const checkPwdValidation = (pwd) =>{
    let validationObject={
      validation1: false,
        validation2: false,
        validation3: false,
        validation4: false,
        validation5: false
    }
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if(pwd.length>=8){
      validationObject.validation1= true;
    }
    else{
      validationObject.validation1= false;
    }
    if(/[a-z]/.test(pwd)){
      validationObject.validation3= true;
    }
    else{
      validationObject.validation3= false;
    }
    if(/[A-Z]/.test(pwd)){
      validationObject.validation2= true;
    }
    else{
      validationObject.validation2= false;
    }
    if(/\d/.test(pwd)){
      validationObject.validation4= true;
    }
    else{
      validationObject.validation4= false;
    }
    if(specialChars.test(pwd)) {
      validationObject.validation5= true;
    }
    else{
      validationObject.validation5= false;
    }
    setPwdValidations(validationObject);
  }


  return <>
  <div className={classes.updatePwdContainer}>
    <div className={classes.container1}>
      <form onSubmit={handleSubmit(handleUpdate)}>
      <>
      <div className={`${customerPartner ? classes.headerContainerCP : classes.headerContainer}`}>
        <p>Update Password</p>
        {enableEdit && <Button type="submit" className={classes.editPwdButton}>Save Password</Button>}
        {!enableEdit && <Button onClick={()=>setEnableEdit(true)} className={classes.editPwdButton}>Update Password</Button>}
      </div>
      <div className={classes.pwdDetailsContainer}>
        <div className={classes.column1}>
          <p>Account</p>
          <p>{loggedUserInfo.username}</p>
        </div> 
        <div className={classes.column2}>
          <p>User</p>
          <p>{loggedUserInfo.name}</p>
        </div>
        <div className={classes.column3}>
          <p className={classes.pwdPara1}>{enableEdit ? "Old Password" : "Password"}</p>
          <InputGroup>
          <input type={currentPwdShown && enableEdit ? "text" : "password"} placeholder="Password"
            name="currPassword" value={enableEdit ? currPassword : 'abcdefgh'} 
            className={classes.passwordInputStyle}
            {...register("currPassword", {required: true})}
            onChange={(e)=>setCurrPassword(e.target.value)} disabled={!enableEdit} />
          {enableEdit && 
          <InputGroup.Text id="basic-addon1" className={classes.inputGroupStyle}>
            <div className={classes.passwordToggleButton} onClick={handleCurrPwdView}>
            {currentPwdShown ? <EyeIcon width="22" height="24" color="#9EA0A6" /> : 
              <EyeSlashIcon width="22" height="24" color="#9EA0A6" />}
            </div>
          </InputGroup.Text>}
          </InputGroup>
          {enableEdit && <>
            <p className={classes.pwdPara2}>New Password</p>
            <InputGroup>
            <input type={passwordShown ? "text" : "password"} name="password1" placeholder="Password" 
                value={newPassword || ""} 
                className={`${errors.password1? classes.inputError:classes.passwordInputStyle} ${passwordAccepted? classes.pwdMatchSuccess : classes.passwordInputStyle}`}
                {...register("password1", {required: true})}
                onChange={handlePwdChange}
                />
                <InputGroup.Text id="basic-addon1" className={classes.inputGroupStyle}>
                <div className={classes.passwordToggleButton} onClick={handlePwdView}>
                  {passwordShown ? <EyeIcon width="22" height="24" color="#9EA0A6" /> : 
                    <EyeSlashIcon width="22" height="24" color="#9EA0A6" />}
                </div>
                </InputGroup.Text>
                {(errors?.password1?.type==="pwdCriteriaFailed" && newPassword) && <p className={classes.errorMessage}>Password does not meet requirements</p> }
            </InputGroup>
            <div className={classes.passwordCheckRow}>
              {defaultPwdCheck.validation1 && 
              <DefaultCheckSquare width="16" height="16" color="#212529" /> }
              {(pwdValidations.validation1 ) &&
              <SuccessCheck width="16" height="16" color="#28A745" /> }
              {(!pwdValidations.validation1 && !defaultPwdCheck.validation1) && 
              <ErrorCheck width="16" height="16" color="#DC3545" /> }
              <p>At least 8 characters</p>
            </div>
            <div className={classes.passwordCheckRow}>
              {defaultPwdCheck.validation2 && 
              <DefaultCheckSquare width="16" height="16" color="#212529" /> }
              {pwdValidations.validation2 && 
              <SuccessCheck width="16" height="16" color="#28A745" /> }
              {(!pwdValidations.validation2 && !defaultPwdCheck.validation2) && 
              <ErrorCheck width="16" height="16" color="#DC3545" /> }
              <p>At least one uppercase letter (A-Z)</p>
            </div>
            <div className={classes.passwordCheckRow}>
              {defaultPwdCheck.validation3 && 
              <DefaultCheckSquare width="16" height="16" color="#212529" /> }
              {pwdValidations.validation3 && 
              <SuccessCheck width="16" height="16" color="#28A745" /> }
              {(!pwdValidations.validation3 && !defaultPwdCheck.validation3) && 
              <ErrorCheck width="16" height="16" color="#DC3545" /> }
              <p>At least one lowercase letter (a-z)</p>
            </div>
            <div className={classes.passwordCheckRow}>
              {defaultPwdCheck.validation4 && 
              <DefaultCheckSquare width="16" height="16" color="#212529" /> }
              {pwdValidations.validation4 && 
              <SuccessCheck width="16" height="16" color="#28A745" /> }
              {(!pwdValidations.validation4 && !defaultPwdCheck.validation4) && 
              <ErrorCheck width="16" height="16" color="#DC3545" /> }
              <p>At least one number (0-9)</p>
            </div>
            <div className={classes.passwordCheckRow}>
              {defaultPwdCheck.validation5 && 
              <DefaultCheckSquare width="16" height="16" color="#212529" />}
              {pwdValidations.validation5 && 
              <SuccessCheck width="16" height="16" color="#28A745" /> }
              {(!pwdValidations.validation5 && !defaultPwdCheck.validation5) && 
              <ErrorCheck width="16" height="16" color="#DC3545" /> }
              <p>At least one special character</p>
            </div>

            <p className={classes.pwdPara2}>Verify New Password</p>
            <InputGroup>
            <input type={confirmPwdShown ? "text" : "password"} name="password2" placeholder="Password" 
                value={confirmNewPwd || ""} 
                className={` ${errors.password2? classes.inputError:classes.passwordInputStyle} ${confirmPwdAccepted ? classes.pwdMatchSuccess: classes.passwordInputStyle}`}
                {...register("password2", {required: true})}
                onChange={handleConfirmPwdChange}
                />
              <InputGroup.Text id="basic-addon1" className={classes.inputGroupStyle}>
                <div className={classes.passwordToggleButton} onClick={handleConfirmPwdView}>
                  {confirmPwdShown ? <EyeIcon width="22" height="24" color="#9EA0A6" /> : 
                    <EyeSlashIcon width="22" height="24" color="#9EA0A6" />}
                </div>
              </InputGroup.Text>
            </InputGroup>
            {(errors?.password2?.type==="pwdCriteriaFailed" && confirmNewPwd) && <p className={classes.errorMessage}>Password does not match</p> }

          </>}
        </div> 
      </div>
      </>
      </form>
    </div>
      {showPopup && <div className={classes.toastContainer}>
        <CustomToast 
          show={showPopup} 
          delay={toastType==="success" ? 3000: 6000}
          message={toastMessage} 
          handleClose={closeToast} 
          type={toastType}
        />
        </div>}
      {updateLoading && <div className={classes.loadingDiv}>
        <PropagateLoader color={`${customerPartner ? "#306AC0": "#E84E3B"}`} size={14}/>
    </div>}
  </div>
  </>
};

export default UpdatePassword;
