import React from "react";
import {Tab, Row, Col, Nav} from "react-bootstrap";
import NewLocation from "../NewLocationLayout/NewLocation";
import "./LocationMgmtLayout.css";

const LocationMgmtLayout = () => {
  return <div className="locationMgmtContainer">
    <Tab.Container id="left-tabs-example" defaultActiveKey="new">
      <Row>
        <Col sm={2} className="pe-0">
          <Nav variant="pills" className="flex-column">
            <Nav.Item>
              <Nav.Link eventKey="new">Add New Location</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="current">Current Locations</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="future">Future Locations</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm={10} className="px-0" >
          <Tab.Content>
            <Tab.Pane eventKey="new">
                <NewLocation />
            </Tab.Pane>
            <Tab.Pane eventKey="current">
            Current Locations Feature Coming Soon...
            </Tab.Pane>
            <Tab.Pane eventKey="future">
            Future Locations Feature Coming Soon...
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  </div>;
};

export default LocationMgmtLayout;
