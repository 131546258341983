import React, {useEffect, useState} from "react";
import { Button, Container } from "react-bootstrap";
import ContactFooter from "../../../Components/ContactFooter/ContactFooter";
import SignupProgressBar from "../../../Components/SignupProgressBar/SignupProgressBar";
import classes from "./ThankyouLayout.module.css";
import { maskedNumbers } from "../../../Utils/commonUtils";
import { loginUrls } from "../../../Utils/loginUrls";
import Footbar from "../../../Components/Footbar/Footbar";

const ThankyouLayout = () => {
    const [selectedPackage] = useState(sessionStorage.getItem("selectedPackage")?JSON.parse(sessionStorage.getItem("selectedPackage")):"");
    const [ccBillingDetails] = useState(sessionStorage.getItem("ccBillingDetails")? JSON.parse(sessionStorage.getItem("ccBillingDetails")): "" );
    const [achPaymentDetails] = useState(sessionStorage.getItem("achPaymentDetails")? JSON.parse(sessionStorage.getItem("achPaymentDetails")): "" );
    const [selectedLocation] = useState(sessionStorage.getItem("location")? JSON.parse(sessionStorage.getItem("location")): "" );
    const [username] = useState(sessionStorage.getItem("synkroUsername"));
    const [accountData] = useState(sessionStorage.getItem("accountData")? JSON.parse(sessionStorage.getItem("accountData")): "" );
    const [loginUrl, setLoginUrl] = useState("");
    let percentComplete = 100;
    let prevPercentComplete = 75;

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        let filteredLoginUrl = loginUrls.find((item)=>item?.city?.toLowerCase()===selectedLocation?.city?.toLowerCase());
        setLoginUrl(filteredLoginUrl.loginUrl);
    }, []);
    

  return <>
  <Container fluid className={classes.outerContainer}>
      <h2 className={classes.signupTitle}>Thank you!</h2>
      <p className={classes.para1}>You’ve successfully signed up for Internet Service through synKro!</p>
      <p className={classes.para2}>Welcome to the synKro family! Please find your synKro subscription information below.  
      <span onClick={()=>window.location.assign(`${process.env.REACT_APP_PROD_URL}/customer-login`)}> Please Login to manage your acount.</span></p>
      <SignupProgressBar percentComplete={percentComplete} prevPercentComplete={prevPercentComplete} active="5" />
 
      <div className={classes.reviewContainer}>
        <div className={classes.innerContainer3}>
            <div className={classes.innerRow}>
                <div className={classes.leftCol}><span className={classes.sectionTitle}>synKro Account</span></div>
            </div>
            <div className={classes.innerRow}>
                <div className={classes.leftCol}>Subscriber Name:</div>
                <div className={classes.rightCol1}>{accountData.firstName} {accountData.lastName}</div>
            </div>
            <div className={classes.innerRow}>
                <div className={classes.leftCol}>Customer Registered Email:</div> 
                <div className={classes.rightCol1}>{accountData.emailId}</div>
            </div>
            <div className={classes.innerRow}>
                <div className={classes.leftCol}>synKro Username:</div> 
                <div className={classes.rightCol1}>{username}</div>
            </div>
        </div>
        <div className={classes.innerContainer4}>
            <div className={classes.innerRow}>
                <div><span className={classes.sectionTitle}>Package Details</span></div>
            </div>
            <div className={classes.innerRow}>
                <div className={classes.leftCol}>{selectedPackage.name}</div>
                <div className={classes.rightCol}>${selectedPackage.monthlyFee?.toFixed(2)}</div>
            </div>
            {/* <div className={classes.innerRow}>
                <div className={classes.leftCol}>Add-on Selected</div>
                <div className={classes.rightCol}>Add-on Cost</div>
            </div>
            <div className={classes.innerRow}>
                <div className={classes.leftCol}>Add-on Selected</div>
                <div className={classes.rightCol}>Add-on Cost</div>
            </div> */}
            <div className={classes.innerRow}>
                <div className={classes.leftCol}>Set-up Fee</div>
                <div className={classes.rightCol}>{selectedPackage.setupFee?.toFixed(2)}</div>
            </div>
            <div className={classes.innerRow}>
                <div className={classes.leftCol}>Taxes</div>
                <div className={classes.rightCol}>{selectedPackage.totalTax?.toFixed(2)}</div>
            </div>
            <hr />
            <div className={classes.innerRow}>
                <div className={classes.leftCol}>Total</div>
                <div className={classes.rightCol}>{selectedPackage.totalPrice?.toFixed(2)}</div>
            </div>
        </div>
        {ccBillingDetails?.cardNumber &&
        <div className={classes.innerContainer3}>
            <div className={classes.innerRow}>
                <div><span className={classes.sectionTitle}>Recurring Payment Method</span></div>
            </div>
            <div className={classes.innerRow}>
                <div>{ccBillingDetails.cardHolderName}</div>
            </div>
            <div className={classes.innerRow}>
                <div>{maskedNumbers(ccBillingDetails?.cardNumber)}</div>
            </div>
            <div className={classes.innerRow}>
                <div>Exp.- {ccBillingDetails.cardExpiry}</div>
            </div>
            <div className={classes.innerRow}>
                <div>CVV - {ccBillingDetails.cvv}</div>
            </div>
        </div>}
        {achPaymentDetails?.accountNumber &&
        <div className={classes.innerContainer5}>
            <div className={classes.innerRow}>
                <div className={classes.leftCol}><span className={classes.sectionTitle}>Recurring Payment Method</span></div>
            </div>
            <div className={classes.innerRow}>
                <div className={classes.leftCol}>ABA Routing Number:</div>
                <div className={classes.rightCol}>{achPaymentDetails?.routingNumber}</div>
            </div>
            <div className={classes.innerRow}>
                <div className={classes.leftCol}>Account Number:</div>
                <div className={classes.rightCol}>{maskedNumbers(achPaymentDetails?.accountNumber)}</div>
            </div>
        </div>}
        <div className={classes.buttonContainer}>
            <Button className={classes.submitButton}
                onClick={()=>window.location.assign(`${process.env.REACT_APP_PROD_URL}/customer-login`)}>
                Manage synKro Account
            </Button>
        </div>
      </div>

    </Container>
    <ContactFooter isDark={false} />
    <Footbar />
  </>;
};

export default ThankyouLayout;
