import React, { useState, useEffect, useContext } from "react";
import { Button, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import AlertBar from "../../../Components/AlertBar/AlertBar";
import CustomerAccountNavBar from "../../../Components/CustomerAccountNavBar/CustomerAccountNavBar";
import LoginServices from "../../../Services/LoginServices";
import classes from "./ResetPassword.module.css";
import { PropagateLoader } from "react-spinners";
import CustomToast from "../../../Components/CustomToast/CustomToast";
import EyeIcon from "../../../Assets/SVGIcons/EyeIcon";
import EyeSlashIcon from "../../../Assets/SVGIcons/EyeSlashIcon";
import DefaultCheckSquare from "../../../Assets/SVGIcons/DefaultCheckSquare";
import SuccessCheck from "../../../Assets/SVGIcons/SuccessCheck";
import ErrorCheck from "../../../Assets/SVGIcons/ErrorCheck";

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ResetPassword = () => { 
  const navigate = useNavigate();
  let query = useQuery();
  const resetCode = query.get("code");
  const resetId = query.get("id");
  const {  register, handleSubmit,reset,setFocus, setError, clearErrors,formState: { errors }} = useForm();
  const [signinClicked, setSigninClicked] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPwd, setConfirmNewPwd] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPwdShown, setConfirmPwdShown] = useState(false);
  const [passwordAccepted,setPasswordAccepted] = useState(false);
  const [confirmPwdAccepted,setConfirmPwdAccepted] = useState(false);
  const [pwdValidations, setPwdValidations] = useState({
    validation1: false,
    validation2: false,
    validation3: false,
    validation4: false,
    validation5: false,
  });
  const [defaultPwdCheck, setDefaultPwdCheck] = useState({
    validation1: true,
    validation2: true,
    validation3: true,
    validation4: true,
    validation5: true,
  });

  useEffect(()=>{
    if(pwdValidations.validation1 && pwdValidations.validation2 && pwdValidations.validation3 && pwdValidations.validation4 && pwdValidations.validation5){
      clearErrors("password1")
      setPasswordAccepted(true);
      if(newPassword === confirmNewPwd){
        setConfirmPwdAccepted(true);
        clearErrors("password2");
      }
      else if(confirmNewPwd && newPassword !== confirmNewPwd){
        setConfirmPwdAccepted(false);
        setError("password2",{type:"pwdCriteriaFailed"});
      }
    }
    else{
      if(newPassword){
        setError("password1",{type:"pwdCriteriaFailed"})
        setPasswordAccepted(false);
      }    
    }
  },[pwdValidations])

    const handleLogin = async (data) => {
      if(passwordAccepted && confirmPwdAccepted && (data.password1===data.password2)){
        setSigninClicked(true);
        let resetData = {
          id:resetId,
          code:resetCode,
          password: data.password1
        }
        LoginServices.resetPassword(resetData)
        .then((resp)=>{
          setSigninClicked(false);
          setShowToast(true);
          setToastType("success")
          setToastMessage("Password has been changed successfully!")
          setTimeout(()=>{
            navigate("/customer-login")
          },3000)
        })
        .catch((err)=>{ 
          setSigninClicked(false)
          setShowToast(true);
          setToastType("error")
          setToastMessage("There was an error updating password.Please try again.");
        })
      }
      else if(data.password1!==data.password2){
        setConfirmPwdAccepted(false);
        setError("password2",{type:"pwdCriteriaFailed"});
        setFocus("password2");
      }
    }
    const closeToast = () => {
      setShowToast(false);
      if(toastType==="success"){
        navigate("/customer-login")
      }
    }
    
    const handlePwdChange = (e) =>{
      let { value } = e.target;
      setNewPassword(value);
      if(value.length>0){
        setDefaultPwdCheck({
          validation1: false,
          validation2: false,
          validation3: false,
          validation4: false,
          validation5: false,})
      }
      else{
        setDefaultPwdCheck({
          validation1: true,
          validation2: true,
          validation3: true,
          validation4: true,
          validation5: true,
        })
      }
      checkPwdValidation(value);
    }
  
    const handleConfirmPwdChange = (e) =>{
      let { value } = e.target;
      setConfirmNewPwd(value);
      if(value.length>0 && value===newPassword){
        setConfirmPwdAccepted(true);
        clearErrors("password2");
      }
      else if(value.length>0 && value!==newPassword){
        setConfirmPwdAccepted(false);
        setError("password2",{type:"pwdCriteriaFailed"})
      }
    }
  
    const checkPwdValidation = (pwd) =>{
      let validationObject={
        validation1: false,
          validation2: false,
          validation3: false,
          validation4: false,
          validation5: false
      }
      const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      if(pwd.length>=8){
        validationObject.validation1= true;
      }
      else{
        validationObject.validation1= false;
      }
      if(/[a-z]/.test(pwd)){
        validationObject.validation3= true;
      }
      else{
        validationObject.validation3= false;
      }
      if(/[A-Z]/.test(pwd)){
        validationObject.validation2= true;
      }
      else{
        validationObject.validation2= false;
      }
      if(/\d/.test(pwd)){
        validationObject.validation4= true;
      }
      else{
        validationObject.validation4= false;
      }
      if(specialChars.test(pwd)) {
        validationObject.validation5= true;
      }
      else{
        validationObject.validation5= false;
      }
      setPwdValidations(validationObject);
    }
  

  return <>
  <AlertBar />
  <CustomerAccountNavBar />
  <div className={classes.outerContainer}>
    <div className={classes.loginContainer}>
        <div className={classes.loginHeader}>
            <h6>Reset Password</h6>
        </div>
        <form onSubmit={handleSubmit(handleLogin)}>
        <p className={classes.inputLabels}>New Password</p>
        <InputGroup>
          <input type={passwordShown ? "text" : "password"} name="password1" placeholder="Password" 
            value={newPassword || ""} 
            className={`${classes.passwordInputStyle} ${errors.password1 && classes.inputError} ${passwordAccepted && classes.pwdMatchSuccess}`}
            {...register("password1", {required: true})}
            onChange={handlePwdChange}
          />
          <InputGroup.Text id="basic-addon1" className={classes.inputGroupStyle}>
            <div className={classes.passwordToggleButton} onClick={()=>{setPasswordShown(!passwordShown)}}>
            {passwordShown ? <EyeIcon width="22" height="24" color="#9EA0A6" /> : 
              <EyeSlashIcon width="22" height="24" color="#9EA0A6" />}
            </div> 
          </InputGroup.Text>
        </InputGroup>
        {(errors?.password1?.type==="pwdCriteriaFailed" && newPassword) && <p className={classes.errorMessage}>Password does not meet requirements</p> }

        <div className={classes.passwordCheckRow}>
              {defaultPwdCheck.validation1 && 
              <DefaultCheckSquare width="16" height="16" color="#212529" /> }
              {(pwdValidations.validation1 ) &&
              <SuccessCheck width="16" height="16" color="#28A745" /> }
              {(!pwdValidations.validation1 && !defaultPwdCheck.validation1) && 
              <ErrorCheck width="16" height="16" color="#DC3545" /> }
              <p>At least 8 characters</p>
            </div>
            <div className={classes.passwordCheckRow}>
              {defaultPwdCheck.validation2 && 
              <DefaultCheckSquare width="16" height="16" color="#212529" /> }
              {pwdValidations.validation2 && 
              <SuccessCheck width="16" height="16" color="#28A745" /> }
              {(!pwdValidations.validation2 && !defaultPwdCheck.validation2) && 
              <ErrorCheck width="16" height="16" color="#DC3545" /> }
              <p>At least one uppercase letter (A-Z)</p>
            </div>
            <div className={classes.passwordCheckRow}>
              {defaultPwdCheck.validation3 && 
              <DefaultCheckSquare width="16" height="16" color="#212529" /> }
              {pwdValidations.validation3 && 
              <SuccessCheck width="16" height="16" color="#28A745" /> }
              {(!pwdValidations.validation3 && !defaultPwdCheck.validation3) && 
              <ErrorCheck width="16" height="16" color="#DC3545" /> }
              <p>At least one lowercase letter (a-z)</p>
            </div>
            <div className={classes.passwordCheckRow}>
              {defaultPwdCheck.validation4 && 
              <DefaultCheckSquare width="16" height="16" color="#212529" /> }
              {pwdValidations.validation4 && 
              <SuccessCheck width="16" height="16" color="#28A745" /> }
              {(!pwdValidations.validation4 && !defaultPwdCheck.validation4) && 
              <ErrorCheck width="16" height="16" color="#DC3545" /> }
              <p>At least one number (0-9)</p>
            </div>
            <div className={classes.passwordCheckRow}>
              {defaultPwdCheck.validation5 && 
              <DefaultCheckSquare width="16" height="16" color="#212529" />}
              {pwdValidations.validation5 && 
              <SuccessCheck width="16" height="16" color="#28A745" /> }
              {(!pwdValidations.validation5 && !defaultPwdCheck.validation5) && 
              <ErrorCheck width="16" height="16" color="#DC3545" /> }
              <p>At least one special character</p>
            </div>

        <p className={classes.confirmPwdLabel}>Confirm New Password</p>
        <InputGroup>
        <input type={confirmPwdShown ? "text" : "password"} name="password2" placeholder="Password" 
          value={confirmNewPwd || ""} 
          className={`${classes.passwordInputStyle} ${errors.password2 && classes.inputError} ${confirmPwdAccepted && classes.pwdMatchSuccess}`}
          {...register("password2", {required: true})}
          onChange={handleConfirmPwdChange}
        />
        <InputGroup.Text id="basic-addon1" className={classes.inputGroupStyle}>
          <div className={classes.passwordToggleButton} onClick={()=>{setConfirmPwdShown(!confirmPwdShown)}}>
          {confirmPwdShown ? <EyeIcon width="22" height="24" color="#9EA0A6" /> : 
              <EyeSlashIcon width="22" height="24" color="#9EA0A6" />}
          </div>
        </InputGroup.Text>
        </InputGroup>
        {(errors?.password2?.type==="pwdCriteriaFailed" && confirmNewPwd) && <p className={classes.errorMessage}>Password and Confirm Password Do Not Match</p> }

        <div className={classes.buttonContainer}>
            <Button type='submit' className={classes.loginButton} disabled={signinClicked}>Update Password</Button>
            <p onClick={()=>navigate("/customer-login")} className={classes.forgotPwdLink}>Cancel</p>
        </div>
        </form>
    </div>
    {showToast && <div className={classes.loadingDiv}>
      <CustomToast 
        show={showToast} 
        delay={3000}
        message={toastMessage} 
        handleClose={closeToast} 
        type={toastType} 
      />
    </div>}
       {signinClicked && <div className={classes.loadingDiv}>
        <PropagateLoader color="#E84E3B" size={14}/>
    </div>}
  </div>;
  </>
};

export default ResetPassword;
