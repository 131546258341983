import React from "react";
import { LiveChatWidget } from "@livechat/widget-react";
import { Outlet } from "react-router-dom";
import AlertBar from "../Components/AlertBar/AlertBar";
import  {UserHomeData} from "../Context/UserAccountContext";
import CustomerAccountNavBar from "../Components/CustomerAccountNavBar/CustomerAccountNavBar";

export const UserAccountRoutes = () => {
  return <>
  <AlertBar />
  <CustomerAccountNavBar />
  <Outlet />
  <LiveChatWidget
      license="12250599"
      group="0"
      />
  </>

};

export const LoginContextRoute = () =>{
  return <>
    <UserHomeData >
    <Outlet />
  </ UserHomeData>
  </>
}

