import React, { useState, useContext, useEffect } from 'react';
import { SidebarData } from './SidebarData';
import SubMenu from './SubMenu';
import classes from './SidebarMenu.module.css';
import ClockIcon from '../../Assets/SVGIcons/ClockIcon';
import AttentionIcon from '../../Assets/SVGIcons/AttentionIcon';
import DashSquareIcon from '../../Assets/SVGIcons/DashSquareIcon';
import CheckCircleIcon from '../../Assets/SVGIcons/CheckCircleIcon';
import  UserHomeData from '../../Context/UserAccountContext';

const Sidebar = () => {
  const { accountStatus,activeLink } = useContext(UserHomeData);
  const [sidebar, setSidebar] = useState(true);
  const [subnav, setSubnav] = useState(true);
  const [ activeLinkId, setActiveLinkId] = useState();
  const [ clickedLinkId, setClickedLinkId] = useState("");

  useEffect(()=>{
    setActiveLinkId(Math.floor(activeLink));
  },[activeLink]);

  const showSubnav = (data) => {
    if(activeLinkId===data.id){
        setSubnav(!subnav);
    }
    else if(activeLinkId !== data.id && !subnav){
        setSubnav(!subnav);
        setActiveLinkId(data.id);
    }
    else{
        setActiveLinkId(data.id);
    }
    setClickedLinkId(data.id);
  }
  const handleShrinkMenu = () =>{
    setSubnav(false);
  }
  const showSidebar = () => setSidebar(!sidebar);

  const handleAccountStatus = () =>{
    switch(accountStatus.statusString)
    {
      case "paid_up":
          return <>
            <div className={classes.accountStatus} >
              <CheckCircleIcon width="24" height="24" color="#28A745" />
              <p> Your Account is <span className={classes.successMsg}> Paid Up</span></p>
            </div>
              <p className={classes.titleParagraph}>
                Thank you for being a synKro Customer. Please manage your account below.
              </p>
          </>;
      case "past_due":
        return <>
          <div className={classes.accountStatus} >
            <AttentionIcon width="24" height="24" color="#DC3545" />
            <p> Your Account is <span className={classes.dueMsg}> Past Due</span></p>
          </div>
            <p className={classes.titleParagraph}>
              Please review payment information on file through the <b>Billing Center</b> below.
            </p>
        </>;
      case "due":
        return <>
          <div className={classes.accountStatus} >
            <ClockIcon width="24" height="24" color="#28A745" />
            <p> Your Account is <span className={classes.successMsg}>Due</span></p>
          </div>
            <p className={classes.titleParagraph}>
              You are currently set up on autopay. Your payment will be pulled automatically
            </p>
        </>;
      case "suspended":
        return <>
          <div className={classes.accountStatus} >
            <AttentionIcon width="24" height="24" color="#FF6007" />
            <p> Your Account is <span className={classes.pendingMsg}> Suspended</span></p>
          </div>
            <p className={classes.titleParagraph}>
            Please <b>Contact Us</b> with any questions below.
            </p>
        </>;
      case "hibernated":
        return <>
          <div className={classes.accountStatus} >
            <AttentionIcon width="24" height="24" color="#FF6007" />
            <p> Your Account is <span className={classes.pendingMsg}> Hibernated</span></p>
          </div>
          <p className={classes.titleParagraph}>
            Please <b>Contact Us</b> with any questions below.
          </p>
        </>;
      case "prospect":
        return <>
          <div className={classes.accountStatus} >
            <ClockIcon width="24" height="24" color="#FF6007" />
            <p> Your Account is <span className={classes.pendingMsg}> Prospect</span></p>
          </div>
            <p className={classes.titleParagraph}>
              Please <b>Contact Us</b> with any questions below.
            </p>
        </>;
      default:
        return <>
          <div className={classes.accountStatus} >
            <AttentionIcon width="24" height="24" color="#FF6007" />
            <p> Your Account status is <span className={classes.pendingMsg}> Unknown</span></p>
          </div>
            <p className={classes.titleParagraph}>
              Please <b>Contact Us</b> with any questions below.
            </p>
        </>;
    }
  }

  return (
    <>
        <nav className={classes.sidebarNav}>
          <div className={classes.sidebarWrap}>
            <div className={classes.accountStatusContainer}>
                {handleAccountStatus()}
            </div>
            {SidebarData.map((item, index) => {
              return <SubMenu item={item} key={index} 
              index={index} show={subnav} activeLinkId={activeLinkId} 
              handleToggle={showSubnav} handleShrinkMenu={handleShrinkMenu} />;
            })}
          </div>
        </nav>
    </>
  );
};

export default Sidebar;
