import React,{useEffect} from "react";
import {Link} from "react-router-dom";
import classes from "./BusinessLayout.module.css";
import image1 from "../../../Assets/Images/residentialImage-2.jpg";
import image2 from "../../../Assets/Images/residentialImage-3.jpg";
import image3 from "../../../Assets/Images/residentialImage-4.jpg";
import CustomButtonDarkBg from "../../../Components/CustomButtonDarkBg/CustomButtonDarkBg";
import Footbar from "../../../Components/Footbar/Footbar";

const BusinessLayout = () => {
    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);
  return(<>
  <div className={classes.outerContainer}>
    <div className={classes.topContainer}>
        <h1>Business just got better </h1>
        <h5>We’re passionate about helping you grow and make an impact. </h5>
    </div>
    <div className={classes.middleContainer}>
        <h1 className={classes.centerTitle}>Do more with synKro.</h1>
        <div className={classes.midInnerContainer}>
            <div className={classes.innerColumns}>
                <div className={classes.roundImageContainer}>
                    <img src={image1} />
                </div>
                <div className={classes.imageTitle}>Work</div>
                <div className={classes.imageDescription}>
                    Rely on synKro’s strong 5G network for when you need it most 
                </div>
            </div>
            <div className={classes.innerColumns}>
            <div className={classes.roundImageContainer}>
                <img src={image2} />
            </div>
                <div className={classes.imageTitle}>Life</div>
                <div className={classes.imageDescription}> 
                    synKro’s unlimited service plans keeps you connected with your friends, and family 24/7
                </div>
            </div>
            <div className={classes.innerColumns}>
            <div className={classes.roundImageContainer}>
                <img src={image3} />
            </div>
                <div className={classes.imageTitle}>Play</div>
                <div className={classes.imageDescription}>
                    synKro’s technology and team of local techs allows you stream movies and play games without pause    
                </div>
            </div>
        </div>
    </div>
    <div className={classes.bottomContainer}>
        <h1>Pick a service plan.*</h1>
        <div className={classes.bottomInnerDiv}>
            <div className={classes.bottomInnerCol}>
                <h2>Unlimited BUSINESS</h2>
                <p>Speeds up to <strong>25 Mbps download/5 Mbps upload.</strong> No data limits. Designed for 
                    the small business, utilize cloud-based business applications, upload/download larger files 
                    without the wait, connect your entire team.</p>
            </div>
            <div className={classes.bottomInnerCol}>
                <h2>Unlimited BUSINESS MAGNUM</h2>
                <p>Speeds up to <strong>100 Mbps download/20 Mbps upload.</strong> No data limits. Designed for 
                    the businesses expanded online needs, utilize cloud-based business applications, 
                    upload/download larger files without the wait, connect your entire team.
                </p>
            </div>
        </div>
        <div className={classes.buttonContainer}>
            <Link to="/select-location"><CustomButtonDarkBg buttonText="Check Availability" /></Link>
            <div className={classes.disclaimerText}><p><strong>*Note:</strong> The specific services and their speeds may vary by 
                location. Choose your service address and you will see exactly what synKro services are available to you.</p>
            </div>
        </div>
    </div>
  </div>
  <Footbar />
  </>
)
};

export default BusinessLayout;
