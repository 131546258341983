import React from "react";
import { Container } from "react-bootstrap";
import classes from "./StepProgressBar.module.css";

const StepProgressBar = ({ percentComplete, prevPercentComplete, active }) => {
  let widthStyle = `${percentComplete}%`;
  return (
    <Container className={classes.progressContainer}>
     <div className={classes.progressLabel}>
        <div className={active==="1" ? classes.activeLabel : classes.indvidualLabels}>Validate Address</div>
        <div className={active==="2" ? classes.activeLabelCenter : classes.indvidualLabelsCenter}>Select Package</div>
        <div className={active==="3" ? classes.activeLabelfinal : classes.indvidualLabelsfinal}>Review</div>
      </div>
      <div className={classes.progressBarBorder}>
        <div
          className={classes.progressBarInner}
          style={{
            width: widthStyle
          }}
        ></div>
      </div>
    </ Container>
  );
};

export default StepProgressBar;
