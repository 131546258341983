import React, { useEffect } from "react";
import { Outlet, Navigate } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import AdminNavBar from "../Components/AdminNavBar/AdminNavBar";
import { loginRequest } from "../authConfig";
import axios from 'axios';

const AdminRoutes = () => {
  const isAdmin = useIsAuthenticated();

  const { instance, accounts } = useMsal();
  // const token= "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6IjJaUXBKM1VwYmpBWVhZR2FYRUpsOGxWMFRPSSIsImtpZCI6IjJaUXBKM1VwYmpBWVhZR2FYRUpsOGxWMFRPSSJ9.eyJhdWQiOiJhcGk6Ly9zeW5rcm91cyIsImlzcyI6Imh0dHBzOi8vc3RzLndpbmRvd3MubmV0LzUxNDE3M2UzLWE4ZTEtNDI4NC04ZDlkLWE4Zjc5MDA3ZmY1OS8iLCJpYXQiOjE2NjM4NTYyNDAsIm5iZiI6MTY2Mzg1NjI0MCwiZXhwIjoxNjYzODYwODUzLCJhY3IiOiIxIiwiYWlvIjoiQVRRQXkvOFRBQUFBOXJUTWFVNnBaYnB3OUxMZ1p6ZEpkRGswUFBBcGx2amFiR0hpUGF0cXBuTW85cUtRYm92RFR2bzdObHIraWwraSIsImFtciI6WyJwd2QiXSwiYXBwaWQiOiIzNDNjODdjNi01ZTg3LTQwZTAtYTMyNy1lMDc0ZjA3OTgxNzYiLCJhcHBpZGFjciI6IjEiLCJmYW1pbHlfbmFtZSI6IkpvaG4iLCJnaXZlbl9uYW1lIjoiQWtoaWwiLCJpcGFkZHIiOiIyMDIuMTcwLjIwNy4yMDIiLCJuYW1lIjoiQWtoaWwgSm9obiIsIm9pZCI6IjQ4MDYxOTM3LTM1ZGItNDU3ZS05ZTI4LTIxMGU2NjQ1NDE1NCIsInJoIjoiMC5BVmtBNDNOQlVlR29oRUtObmFqM2tBZl9XY2FIUERTSFh1QkFveWZnZFBCNWdYYWRBRFUuIiwicm9sZXMiOlsiQWRtaW4uUmVhZFdyaXRlIl0sInNjcCI6IkFkbWluLkNvbmZpZyIsInN1YiI6IkdmR1FVYkctVjRsZTNUODJxT1d3aTYzSUJWWG9ZU3B5OUp3blNpdm53NHciLCJ0aWQiOiI1MTQxNzNlMy1hOGUxLTQyODQtOGQ5ZC1hOGY3OTAwN2ZmNTkiLCJ1bmlxdWVfbmFtZSI6IkFraGlsQHN5bmtyb3Vzb3V0bG9vay5vbm1pY3Jvc29mdC5jb20iLCJ1cG4iOiJBa2hpbEBzeW5rcm91c291dGxvb2sub25taWNyb3NvZnQuY29tIiwidXRpIjoiLTZSYWozUklFRU9jcnFBTlFJQVFBQSIsInZlciI6IjEuMCJ9.qRadgHhdCey19yPOwW8A-O8Le7wMvtltGwlIZ0H-rjrvNGoUqGwgxgNlgYAIsBMofkdiYiBq2UFbF2jQzwCINmgVBhCETqOu3OnOlAB-FpVcXDBtSeIf9Py5eVBV5uBy5qK6YKfybc56Ztp4xQapueHNLdGGCUXHq8Zc8euRUxesghnkoH4TKZQeaECtX6f7-ux1hUDYqLrQlszNch8LzGcA14RFm3Z91vwqaj3f4bOgjQnHQDKyru6umqdqvx6mdnd3chPDJKXP3W86lBNh51E760BDUoDx5oK9-B7_r02sfjMpfzPrqi9n9V2fNXgbSJHDgC47ii3LDnYH2-8Usg";

  useEffect(()=>{
    const request = {
        ...loginRequest,
        account: accounts[0]
    };
    instance.acquireTokenSilent(request).then((response) => {
        sessionStorage.setItem("adminAuthToken",response.accessToken);
        axios.defaults.headers.common['Authorization'] = `Bearer ${response.accessToken}`;
    })  
  },[])

  return(
    isAdmin ? 
    <>
    <AdminNavBar />
    <Outlet/> 
    </>
    
    : <Navigate to='/home'/>
  )
}

export default AdminRoutes;

