import React from "react";
import {Tab, Row, Col, Nav} from "react-bootstrap";
import AnnouncementsAccordion from "./AnnouncementsAccordion";

const AnnouncementsLayout = () => {
  return<div className="locationMgmtContainer">
  <Tab.Container id="left-tabs-example" defaultActiveKey="new">
    <Row>
      <Col sm={2} className="pe-0">
        <Nav variant="pills" className="flex-column">
          <Nav.Item>
            <Nav.Link eventKey="new">Announcements</Nav.Link>
          </Nav.Item>
        </Nav>
      </Col>
      <Col sm={10} className="px-0" >
        <Tab.Content>
          <Tab.Pane eventKey="new">
            <AnnouncementsAccordion />
          </Tab.Pane>
        </Tab.Content>
      </Col>
    </Row>
  </Tab.Container>
</div>;;
};

export default AnnouncementsLayout;
