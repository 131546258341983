import React, {useEffect, useState} from "react";
import { Container, Card, Button } from "react-bootstrap";
import { useNavigate} from "react-router-dom"; 
import PropagateLoader from "react-spinners/PropagateLoader";
import StepProgressBar from "../../../Components/StepProgressBar/StepProgressBar";
import classes from './PackagesLayout.module.css';
import ContactFooter from "../../../Components/ContactFooter/ContactFooter";
import UserServices from "../../../Services/UserServices";
import { EventTracking } from "../../../Utils/gaTracking";
import TooltipComponent from "../../../Components/TooltipComponent/TooltipComponent";
import tooltipIcon from "../../../Assets/Images/icon-wrapper.png";
import Footbar from "../../../Components/Footbar/Footbar";

const PackagesLayout = () => {
  const navigate = useNavigate();
  const [selectedLocation] = useState(JSON.parse(sessionStorage.getItem("location")));
  const packageSelected = sessionStorage.getItem("selectedPackage");
  const [selectedPackage] = useState(packageSelected? JSON.parse(packageSelected):"");
  const [isLoading, setIsLoading] = useState(true);

  const [packages, setPackages] = useState([]);
    let percentComplete = 50;
    let prevPercentComplete = 0;

    useEffect(() => {
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);
    useEffect(()=>{
        UserServices.selectPackages(selectedLocation.id)
        .then((resp)=>{
          let receivedData = resp.data;
          receivedData.forEach(element => {
            element.selected=false;
          });
          if(selectedPackage){
            let temporaryarray=receivedData;
            let objIndex = receivedData.findIndex((obj => obj.packageId === selectedPackage.packageId));
            if (objIndex !== -1) {
              temporaryarray[objIndex].selected = true;
            }      
            setPackages(temporaryarray);
          }
          else{
            setPackages(receivedData);
          }
          setIsLoading(false);
        })
      
    },[])

    const handleSelection = (item) =>{
      sessionStorage.setItem("selectedPackage",JSON.stringify(item));
      navigate("/review");
      let objIndex = packages.findIndex((obj => obj.packageId === item.packageId));
      if (objIndex !== -1) {
        let temporaryarray = packages.slice();
        temporaryarray[objIndex].selected = !item.selected;
        setPackages(temporaryarray);
      }
    EventTracking(item.packageId, "Selected Package", item.name);

    }
    
    
  return (
    <>
    <Container fluid className={classes.outerContainer}>
        <h2 className={classes.signupTitle}>Sign Up for Broadband Internet</h2>
        <p className={classes.para1}>{selectedLocation.city}, {selectedLocation.state} and surrounding area.</p>
        <p className={classes.para2}>Not in {selectedLocation.city}? <a href='/select-location'>Find your town.</a></p>
        <StepProgressBar percentComplete={percentComplete} prevPercentComplete={prevPercentComplete} active="2" />
      <Container fluid className={classes.formContainer}>
        <h2 className={classes.formTitle}>Select Broadband Internet Package</h2>
        <div className={classes.cardContainer}>
          {isLoading? <PropagateLoader
              color="#E84E3B"
              size={16}
            />:
          <>
          {packages.map((item, index)=>{
            return(
            <Card className={classes.cardStyle} key={item.packageId}>
            <Card.Body className={classes.cardBodyStyle}>
              <div className={classes.cardTitleContainer}>
                <h5 className={classes.cardTitle}>{item.name}</h5>
                <p className={classes.cardTitleFee}>${item.monthlyFee.toFixed(2)}/Month</p>
              </div>
              <div className={classes.descriptionContainer}>
                <p>{item.description}</p>             
              </div>
              <div className={classes.additionalFeeDiv}>
                  <p className={classes.additionalFeeDetails}>Package Fee</p>
                  <p className={classes.additionalFeeAmount}>${item.fees.find((e)=>e.name==="Monthly Price")?.price?.toFixed(2)} /month</p>
                </div> 
                {item.fees.find((e)=>e.name==="Managed Residential Wi-Fi") && 
                  <div className={classes.additionalFeeDiv}>
                    <p className={classes.additionalFeeDetails}>Managed Wifi <TooltipComponent id="tool-tip-1" message="Our cloud managed Wi-Fi takes the worry about setup and privacy of the Wi-Fi network at your home.">
                      <img src={tooltipIcon} /></TooltipComponent></p>
                    <p className={classes.additionalFeeAmount}>${item.fees.find((e)=>e.name==="Managed Residential Wi-Fi")?.price?.toFixed(2)} /month</p>
                  </div> 
                }
                {item.fees.find((e)=>e.name==="Modem Rental") &&
                  <div className={classes.additionalFeeDiv}>
                    <p className={classes.additionalFeeDetails}>Modem Rental <TooltipComponent id="tool-tip-2" message="Equiptment Rental Recurring">
                      <img src={tooltipIcon} /></TooltipComponent></p>
                    <p className={classes.additionalFeeAmount}>${item.fees.find((e)=>e.name==="Modem Rental")?.price?.toFixed(2)} /month</p>
                  </div> 
                }
                {item.fees.find((e)=>e.name==="Setup Fee") &&
                  <div className={classes.additionalFeeDiv} >
                    <p className={classes.additionalFeeDetails}>Set-up Fee</p>
                    <p className={classes.additionalFeeAmount}>${item.fees.find((e)=>e.name==="Setup Fee")?.price?.toFixed(2)} /one-time</p>
                  </div>  
                }
              <div className={classes.buttonContainer}>
                <Button onClick={()=>handleSelection(item)} className={classes.selectButton}>
                  {item.selected? "Package Selected!" : "Select Package"}
                </Button>
              </div>
            </Card.Body>
          </Card>)
          })
          } 
          </>}                
        </div>
        </Container>
    </Container>
    <ContactFooter isDark={false} />
    <Footbar />
    </>
  );
};

export default PackagesLayout;
