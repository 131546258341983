import React,{ useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import CustomButtonDarkBg from "../CustomButtonDarkBg/CustomButtonDarkBg";
import classes from "./ContactModal.module.css";
import CustomToast from "../CustomToast/CustomToast";
import { useEffect } from "react";

const ContactModal = (props) => {
    const {  register, handleSubmit,reset, formState: { errors }} = useForm();

    useEffect(()=>{
        if(props.toastType==="success" || !props.showModal){
            reset();
        }
    },[props])    
    
    
    return <div>
        <Modal size="lg" centered show={props.showModal} onHide={props.handleClose} className={classes.modalStyles}>
        <Modal.Header closeButton>
            <Modal.Title>Contact us (www.synkro.us)</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <form onSubmit={handleSubmit(props.submitContactInfo)}> 
            <Row className={classes.formRow}>
                <Col sm={10} md={6} lg={6} className={classes.row1Column}>
                <label>Name*</label><br />
                <input type="text" 
                    name="firstName" 
                    className={errors.firstName ? classes.addressInputError : ""}
                    {...register("firstName", {required: true})}/> <br />
                <label>First Name</label>
                </Col>
                <Col sm={10} md={6} lg={6} className={classes.row1Column}>
                <label> </label><br />
                <input type="text" 
                    name="lastName"
                    {...register("lastName")} /> <br />
                <label>Last Name</label>
                </Col>
            </Row>
            <Row className={classes.formRow}>
                <Col sm={10} md={12} lg={12} className={classes.FullColumn}><label>Email*</label><br />
                <input type="email" 
                    name="mailId" 
                    className={errors.mailId ? classes.addressInputError : ""}
                    {...register("mailId", {required: true})}/>
                </Col>
            </Row>
            <Row className={classes.formRow}>
                <Col sm={10} md={12} lg={12} className={classes.FullColumn}><label>Message*</label><br />
                <textarea 
                    rows={3}
                    name="message" 
                    className={errors.message ? classes.addressInputError : ""}
                    {...register("message", {required: true})}/>
                </Col>
            </Row>
            <Row className={classes.toastRow}>
            <CustomToast 
                show={props.showToast} 
                delay={3000} 
                message={props.toastMessage} 
                handleClose={props.closeToast} 
                type={props.toastType} />
            </Row>
            <Row className={classes.formRow}>
                <CustomButtonDarkBg buttonText="Submit" handleClick={handleSubmit(props.submitContactInfo)} />
            </Row>
            </form>
        </Modal.Body>
        </ Modal>
  </div>;
};

export default ContactModal;
