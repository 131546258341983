import React, {useState, useEffect} from "react";
import { Button, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import checkImage from "../../../../Assets/Images/Read_Check.png";
import ConfirmationModal from "../../../../Components/ConfirmationModal/ConfirmationModal";
import { isValidRoutingNumber } from "../../../../Utils/commonUtils";
import classes from "./ACHPayment.module.css";


const ACHPayment = () => {
    const {  register, handleSubmit,reset, setError,clearErrors, formState: { errors }} = useForm();
    const navigate = useNavigate();

    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [routingNumber, setRoutingNumber] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [achPaymentDetails] = useState(sessionStorage.getItem("achPaymentDetails")? JSON.parse(sessionStorage.getItem("achPaymentDetails")): "" );

    useEffect(()=>{
        if(achPaymentDetails.routingNumber){
            let data ={
                routingNumber:achPaymentDetails.routingNumber,
                accountNumber:achPaymentDetails.accountNumber
            }
            setRoutingNumber(achPaymentDetails.routingNumber)
            setAccountNumber(achPaymentDetails.accountNumber);
            reset(data);
        }
    },[])

    const submitCardPayment = (data) =>{
        sessionStorage.setItem("achPaymentDetails",JSON.stringify(data));
        sessionStorage.setItem("ccBillingDetails","")
        navigate("/review-order");
    }
    const handleClose = () =>{
        setShowConfirmModal(false);
    }

    const handleRoutingNumber = (e) =>{
        let {value} = e.target;
        value = (value.replace(/[^0-9]/g,'')).substr(0, 9);
        setRoutingNumber(value);
        if(value.length===9 && isValidRoutingNumber(value)){
            clearErrors("routingNumber")
        }
        else{
            setError("routingNumber",{type:"invalidRoutingNo"})
        }
    }
    const handleAccountNumber = (e) => {
        let {value} = e.target;
        value = (value.replace(/[^0-9]/g,'')).substr(0, 20);
        setAccountNumber(value);
        if(value.length>=4){
            clearErrors("accountNumber");
        }
    }

  return <div className={classes.achPaymentContainer}>
    <h4>How to read your check:</h4>
    <div className={classes.checkImageDiv}>
        <img src={checkImage} alt="check-img" />
    </div>
    <form onSubmit={handleSubmit(submitCardPayment)}>
    <div className={classes.errorWarning}>
            {errors && Object.keys(errors).length > 0 && <p>*Please check fields outlined below*</p>}
        </div>
    <Row className="mb-2 mt-4">
        <label>ABA Routing Number*</label>
    </Row>
    <Row className="mb-2">
        <input type="text" name="routingNumber" placeholder="#########" 
        value={routingNumber}
        className={errors.routingNumber ? classes.inputError : classes.inputStyle}
        {...register("routingNumber", {required: true})}
        onChange={handleRoutingNumber}/>   
        {errors?.routingNumber?.type==="invalidAbaNumber" && 
        <p className={classes.errorMessage}>{errors.cardNumber.message}</p>}                                   
    </Row>
    <Row className="mb-2 mt-4">
        <label>Account Number *</label>
    </Row>
    <Row className="mb-2">
    <input type="text" name="accountNumber" placeholder="###############" 
        value={accountNumber}
        className={errors.accountNumber ? classes.inputError : classes.inputStyle}
        {...register("accountNumber", {required: true})}
        onChange={handleAccountNumber}/>                                              
    </Row>
    <div className={classes.buttonContainer}>
        <Button onClick={()=>{setShowConfirmModal(true)}} className={classes.cancelButton}>Cancel</Button>
        <Button type="submit" className={classes.submitButton}>Next</Button>
    </div>
    </form>
    <ConfirmationModal showModal={showConfirmModal} handleClose={handleClose} />
        
  </div>;
};

export default ACHPayment;
