import React from "react";
import { Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Cookies from 'universal-cookie';
import classes from './CookiePopup.module.css';

const CookiePopup = ({handleModal}) => {
    const cookies = new Cookies();
    const acceptCookieHandler = ()=>{
        localStorage.setItem('enableCookies', 'true')
        const userCookie = cookies.get("user");
        if(!userCookie){
            cookies.set("user","public",[{path:"/"}]);
        }
        handleModal();
    }
    const rejectCookieHandler = () => {
        localStorage.setItem('enableCookies', 'false')
        cookies.remove("user"); 
        handleModal();
    }
  
    return (
    <Card className={classes.cardStyles}>
    <Card.Body className={classes.cardBody}>
        <Card.Title className={classes.cardTitle}>SynKro Cookie Policy</Card.Title>
        <Card.Text className={classes.cardText}>We use cookies to optimize our website and services. 
            To review our policies please visit our <Link  to="/cookie-policy"> <span className={classes.cookiePolicyLink}>Cookie Policy</span></Link>.
        </Card.Text>
        <div className={classes.buttonContainer}>
        <Button className={classes.denyButton} onClick={rejectCookieHandler}>Deny All Cookies</Button>
        <Button className={classes.acceptButton} onClick={acceptCookieHandler}>Accept All Cookies</Button>
        </div>        
    </Card.Body>
    
  </Card>);
};

export default CookiePopup;
