import React from "react";
import { Outlet, Navigate } from 'react-router-dom';
import { useIsAuthenticated } from "@azure/msal-react";
import AlertBar from "../Components/AlertBar/AlertBar";
import Footbar from "../Components/Footbar/Footbar";
import NavigationBar from "../Components/Navbar/NavigationBar";
import {SignupData} from "../Context/PublicUserContext";
import { LiveChatWidget } from "@livechat/widget-react";
import CustomHomeNavBar from "../Components/CustomHomeNavBar/CustomHomeNavBar";

const PublicRoutes = () => {
  // const [showAlertBar, setShowAlertBar] = useState();
  const isAdmin = useIsAuthenticated(); 
  const homeUrl = (window.location.origin === (process.env.REACT_APP_PROD_URL) || window.location.origin === "https://www.synkro.us");
  const currentPath = window.location.pathname;
  const homePath = currentPath==="/" || currentPath==="/home"

  return(
    !isAdmin ? 
    <>
    <SignupData>    
    <AlertBar />
    {(!homeUrl && homePath) ? <CustomHomeNavBar />: <NavigationBar />}
    <Outlet/>
    </SignupData>
    <LiveChatWidget
      license="12250599"
      group="0"
      />
    </> :
    <Navigate to='/admin'/>
    
  )
};

export default PublicRoutes;
