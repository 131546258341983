import React, {useEffect, useState} from "react";
import { Accordion } from "react-bootstrap";
import classes from "./FaqLayout.module.css";
import ContactModal from "../../../Components/ContactModal/ContactModal";
import CustomButtonDarkBg from "../../../Components/CustomButtonDarkBg/CustomButtonDarkBg";
import services from "../../../Services/UserServices";
import Footbar from "../../../Components/Footbar/Footbar";

const FaqLayout = () => {
    const [showModal, setShowModal] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastType, setToastType] = useState();
    const [toastMessage, setToastMessage] = useState();

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);

    const handleContactModal = () =>{
        setShowModal(true);
    }
    const submitContactInfo =(data)=>{
        let formattedData = {
            Email: data.mailId,
            Message: data.message,
            Name: data.firstName.trim() +" " + data.lastName.trim(),
        }
        services.submitContactDetails(formattedData)
        .then((resp)=>{
            setShowToast(true);
            setToastType("success");
            setToastMessage("Contact information submitted");
            setTimeout(()=>{setShowModal(false)},3000);
        })
        .catch((err)=>{
            setShowToast(true);
            setToastType("error");
            setToastMessage("Error submitting contact information")

        })
    }

    const handleClose = ()=>{
        setShowModal(false);
    }
    const closeToast = () =>{
        setShowToast(false);
    }

  return <>
  <div className={classes.faqOuterContainer}>
    <h1 className={classes.faqTitle}>Frequently Asked Questions</h1>
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Usage</Accordion.Header>
        <Accordion.Body>
            <h6>Do I have to sign a contract to sign up for synKro service?</h6>
            <p>No, synKro is an on-demand service and has no binding contracts and no hidden fees. We fully 
                disclose what you are buying when you are buying it and how much it costs. Check out our Return 
                Policy - 30 day refund guarantee of the service charge from the day of activation of your synKro 
                service!
            </p>
            <h6>How do I pay?</h6>
            <p>You pre-pay monthly for your service with a credit card that is associated with your synKro 
                account. As a synKro subscriber you can check your current account status by logging in to your 
                account where you can see your services, your payment history and the next billing date.
            </p>
            <h6>What are the data usage limits for synKro Services?</h6>
            <p>There are no limits! SynKro offers unlimited Internet service - one flat monthly fee for all the 
                broadband Internet you can use! Also, check out our Accelerated Performance Services<sup>TM</sup>!
            </p>
            <h6>What are Accelerated Performance Services<sup>TM</sup> (APS)?</h6>
            <p>Speed (bandwidth) is just one factor in your broadband Internet experience. If you are like most 
                people, you might be doing many things on the web at the same time: checking email, browsing the 
                web, playing a game, streaming a song or video, etc. APS allows you to take better control of 
                how the synKro network handles your data. By signing up for an APS, you are asking the synKro 
                Artificial Intelligence (AI) of the network to optimize your video, game, voice or download so 
                that it goes as smooth as possible maximizing your user experience and therefore satisfaction 
                as a customer.<br />
                <span>Note: APS may not be available in all areas. </span>
            </p>
            <h6>What is Managed Wi-Fi service?</h6>
            <p>In today’s connected home, Wi-Fi has become the wireless way most people connect their computers, 
                tablets, smartphone and Smart Home devices to the Internet. Since the quality of our services are 
                only as good as the weakest link, we want for you to have the best possible service. To do that, 
                we provide you with a commercial grade dual band Wi-Fi router which will be a perfect match to 
                our wide area wireless signal to the tower and the Internet. We are remotely monitoring managing 
                the connection 24 hours a day and in the event you have any issues with your Internet experience, 
                just call our 24 hour customer service line at 844-883-1659. A technician will look at the entire 
                connection from the Internet to your home and quickly determine what is going on.
            </p>
            <h6>What if I need a customized broadband solution for my business?</h6>
            <p>The synKro service plan offers a broadband Internet plan suitable for small to medium sized 
                businesses who depend on reliable and consistent Internet service. Please call 844-883-1659 
                for more information.
            </p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Setup and Security</Accordion.Header>
        <Accordion.Body>
            <h6>How do I get equipment installed and set up?</h6>
            <p>The synKro service requires an outdoor modem to be placed on the outside of your home facing the radio 
            tower that broadcasts the signals to your home. The antenna must be aimed precisely towards the radio 
            tower and be clear of any obstacles. We offer a professional installation package to you at a nominal 
            cost and it’s fully guaranteed — you pay nothing until everything is working correctly.
            </p>
            <h6>How secure is it to surf on the synKro network?</h6>
            <p>Your synKro connection is very secure. We use 5G state-of-the-art security protocols that help protect 
                your connection to the Internet. These are activated automatically and always operating.
            </p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Coverage and Equipment</Accordion.Header>
        <Accordion.Body>
            <h6>How do I know if I am in the synKro coverage area?</h6>
            <p>Find your town on our “Check Availability” page. You will see a red “Buy Now!” button on the home page then click on "Shop" for the service you wish to buy, put in your address in the box and follow the directions.
            </p>
            <p>If you are in the coverage area you will see what services are available for that location.
            </p>
            <h6>Can I expand my Fixed broadband Internet service to cover my entire home/business?</h6>
            <p>Yes you can! There are third party wireless extenders which are plugged into wall outlets in the areas of your home where your Wi-Fi connection may not reach. These extenders pick up the signal from our router and amplify it so your whole home or business is covered.
            </p>
            <h6>What types of devices can I use on the synKro network?</h6>
            <p>With our managed Wi-Fi router connected to the outdoor modem via the Ethernet cable, you can use any type of Wi-Fi enabled device including computers, smart phones, iPhones, Android devices, tablets, Smart Home devices and more.
            </p>
            <h6>Can I move my outdoor modem to a new location?</h6>
            <p>If the move is to another point on the same dwelling, the answer is generally yes. The modem must remain pointed in the exact same direction as it originally was without obstructions between it and the horizon.
            <br />If you are moving to a new address, please call us to arrange assistance with the move and get you back online as soon as possible. There will be an installation charge (profession or self) for any new installations, but you will be able to keep your same outdoor modem and Wi-Fi router.
            </p>
            <h6>Is your service affected by the weather?</h6>
            <p>Weather has no effect on our synKro 5G service.</p>

            <h6>We go to a warmer climate during the winter. Can I suspend my service?</h6>
            <p>Yes you can! We offer a vacation package that suspends your service but keeps your account active 
                for a low nominal charge. Just call us at 844-883-1659 to arrange a vacation package. There are 
                no other service charge or hidden fees and you can apply any existing prepaid active service to 
                the vacation service charge. During this time, your modem should be turned off. You will be 
                unable to use the Internet until you call us and reactivate your service.
            </p>
            <h6>Does your service still work during a power outage?</h6>
            <p>The synKro network is built to the same high standards as other commercial service providers such 
                as cellular and telephone networks. We have our own battery and standby power sources to keep the 
                network running.
            <br/>We do not supply a backup power source for the outdoor modem at your home. If you use an 
                Uninterruptible Power Supply (UPS) for your computer system, be sure to also have the synKro 
                managed Wi-Fi router power adapter plugged into the UPS. This will ensure that your outdoor 
                modem is also protected, because the managed Wi-Fi router feeds the power to the outdoor modem 
                over the same cable that passes the data connection. Your Internet connection should be good to 
                go if the UPS is active. Our outdoor modem + Wi-Fi router draws about 40 watts.
            </p>
            <h6>Disclosure on Service Descriptions</h6>
            <p>All services (Unlimited Premium, Unlimited Premium Plus, Unlimited Premium Magnum and Magnum 
                Business) are delivered via the Cambium PMP family of proprietary technology radio products from 
                Cambium Networks. (Formerly known as Motorola Canopy.) The networks we use are configured by the 
                synKro cloud intelligence to match the nominal performance associated with the service you buy. 
                When network loading increases, the radio's built in congestion management technology attempts to maintain 
                the throughput for all active connections at the same relative level and is independent of the type of data 
                in use. The impact on the connection's speed will be a decrease in both the down and up directions and 
                not necessarily in direct proportion to the stated "up to" speeds.<br/>
                The radio network operates with a nominal baseline latency of less than 10 milliseconds (msec) 
                for the radio link itself ("the last mile network"). As the network is loaded with uses, latency 
                will increase as the radio attempts to maintain its overall performance. The net effect is that 
                latency will increase for all subscribers by approximately like amounts, up to several times the 
                baseline latency. We use our advanced AI techniques and proprietary software technology to 
                automatically monitor and manage latency to the extent possible. Typical values for latency 
                when measured from the endpoint in the home to the Internet can be expected to be in the 40 - 65 
                millisecond (ms) range for conventional web browsers, but cannot be guaranteed since many parts 
                of the Internet backbone connection back to the Internet site(s) in use at the time is out of our 
                control.<br/>
                The radio network we use operates in radio spectrum shared by others. While we automatically 
                monitor performance and work to minimize the impact of other users on the Internet experience of 
                our subscribers, interference may arise at any time and is generally out of our control.<br/>
                All the preceding information is accurate when the radio network is behaving normally and radio 
                conditions are favorable. By radio conditions, we specifically mean that the radio path between 
                our network and the subscriber locations is unobstructed and there is minimal noise present in 
                the form of interference. Obstructions in the path and noise will degrade the radio performance. 
                Such degradation may not impede the overall subscriber experience as the radio will use various 
                technical means to mitigate such conditions until the point where the connection begins to exhibit
                 increasingly poor performance as the conditions degrade past the point where the corrective 
                techniques are unable to compensate. When that happens, the effective speed of the connection 
                will rapidly decrease and the latency will increase dramatically until the connection becomes unusable.
            </p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>Troubleshooting</Accordion.Header>
        <Accordion.Body>
            <h6>I can't connect to the synKro network at all.</h6>
            <p>If you have an outdoor modem, try these troubleshooting steps:
                Make sure your synKro device is powered on by having the managed Wi-Fi router plugged in and turned on since the Wi-Fi router provides the power to the outdoor antenna modem at the AC power adaptor.
                Make sure that the cable coming from the modem on the outside of your house is attached to the managed Wi-Fi router inside the house/building.
                Make sure that you have active synKro service. Sometimes, it’s easy to forget to update your expiration date on your credit card. If you have access to another Internet connection, log in to your MyAccount and make a payment or call us at 844-883-1659 and we will help you solve the problem.
                If none of this fixes your problem - call us!
            </p>
            <h6>My synKro service is slower than normal, what should I do?</h6>
            <p>There are many factors that may impact your connection speed, including type of browser or operating 
                system, overall network traffic, time of day, additional background applications already running 
                (e.g. streaming media, downloading large files).
            </p>
            <p>If you are experiencing consistently slower connection speeds, make sure that all of your connection and security software is up to date.
            </p>
            <p>Run the standard maintenance programs for your PC, including scanning for viruses, spyware and malware. To keep your PC running at its best, we recommend that you perform this maintenance every 30 days.
            </p>
            If the conditions persist, especially outside of the peak busy hours (usually between 7 PM and midnight), call us during the day to make it easier to diagnose any issues.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>Email account</Accordion.Header>
        <Accordion.Body>
            <h6>How can I get a synKro email address?</h6>
            <p>SynKro does not provide email addresses. We recommend that you get a free email address from 
                Google, Yahoo, Facebook, etc.
            </p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header>General</Accordion.Header>
        <Accordion.Body>
            <h6>How do I get my bill?</h6>
            <p>You will not get a traditional bill, instead you will see your charges when you log in to your 
                account and click on the "My Services" tab. You will be charged for all your services the 
                same date every month. The date is determined by the date that your synKro service was first 
                activated — not when it was ordered.
            </p>
            <h6>I would like to get a receipt for my charges</h6>
            <p>In the MyAccount page, click on the “Receipt” box and a receipt will be sent to you by email.</p>
            <h6>How do I cancel my subscription?</h6>
            <p>Just call us at 844-883-1659 and we will cancel your service. For details on what happens when 
                you cancel your subscription, refer to the information contained in the link “Return Policy”, 
                visible at the bottom of this page.
            </p>
        </Accordion.Body>
      </Accordion.Item>
      </Accordion>
      <div className={classes.buttonContainer}>
      <CustomButtonDarkBg buttonText="Contact us" handleClick={handleContactModal}/>
      </div>     

      <ContactModal 
        showModal={showModal} 
        handleClose={handleClose} 
        submitContactInfo={submitContactInfo} 
        showToast={showToast}
        toastType={toastType}
        toastMessage={toastMessage} 
        closeToast={closeToast} />
  </div>
  <Footbar />
  </>;
};

export default FaqLayout;
