import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import PersonSquare from '../../Assets/SVGIcons/PersonSquare';
import CreditCardIcon from '../../Assets/SVGIcons/CreditCardIcon';
import PackageIcon from '../../Assets/SVGIcons/PackageIcon';
import FileIcon from '../../Assets/SVGIcons/FileIcon';
import EmailIcon from '../../Assets/SVGIcons/EmailIcon';
import classes from './SidebarMenu.module.css';
import UserHomeData  from '../../Context/UserAccountContext';
import ChevronDownIcon from '../../Assets/SVGIcons/ChevronDownIcon';
import ChevronUpIcon from '../../Assets/SVGIcons/ChevronUpIcon';

const SubMenu = (props) => {
    let {item} = props;
    const { activeLink, userAccountInfo } = useContext(UserHomeData);
    const [customerPartner,setCustomerPartner] = useState(false);

    useEffect(()=>{
      if(userAccountInfo?.locationDetails?.city?.toLowerCase()==="paris" || userAccountInfo?.locationDetails?.city?.toLowerCase()==="colville"){
        setCustomerPartner(true);
      }
      else{
        setCustomerPartner(false);
      }
    },[userAccountInfo])

    const handleView = (item) => {
        props.handleToggle(item);
    }
    const handleShrinkMenu = ()=>{
        props.handleShrinkMenu()
    }

    const showIcon = (data) =>{
        switch(data.id) {
            case 1:
                return <PersonSquare width="24" height="24" color="#DEE2E6" />;
            case 2:
                return <CreditCardIcon width="24" height="24" color="#DEE2E6"/>;
            case 3:
                return <PackageIcon width="24" height="24" color="#DEE2E6" />;
            case 4:
                return <FileIcon width="24" height="24" color="#DEE2E6" />;
            case 5:
                return <EmailIcon width="24" height="24" color="#DEE2E6" />;
            default:
                return <PersonSquare width="24" height="24" color="#DEE2E6" />;
        }
    }

  return (
    <>
      {customerPartner ? <Link to={item.subNav ? "#" : item.path} onClick={item.subNav ? ()=>handleView(item): handleShrinkMenu}
        className={`${item.id === activeLink ? classes.activeSidebarLinkCP : classes.sidebarLinkCP}`}>
        <div>
          {showIcon(item)}
          <span className={classes.sidebarLabel}>
            {item.title}
            </span>
        </div>
        <div>
          {(item.subNav && props.show && props.index === props.activeLinkId-1)
            ? <ChevronUpIcon width= "16" height="16" color="#DEE2E6" />
            : item.subNav
            ? <ChevronDownIcon width= "16" height="16" color="#DEE2E6" />
            : null}
        </div>
      </ Link> :
      <Link to={item.subNav ? "#" : item.path} onClick={item.subNav ? ()=>handleView(item): handleShrinkMenu}
        className={`${item.id === activeLink ? classes.activeSidebarLink : classes.sidebarLink}`}>
        <div>
          {showIcon(item)}
          <span className={classes.sidebarLabel}>
            {item.title}
            </span>
        </div>
        <div>
          {(item.subNav && props.show && props.index === props.activeLinkId-1)
            ? <ChevronUpIcon width= "16" height="16" color="#DEE2E6" />
            : item.subNav
            ? <ChevronDownIcon width= "16" height="16" color="#DEE2E6" />
            : null}
        </div>
      </ Link>}
      {( props.show && props.index === props.activeLinkId-1) &&
        item?.subNav?.map((item, index) => {
          return (<>
            {customerPartner ? 
            <Link to={item.path} key={index} className={`${activeLink===item.id?classes.activeDropdownLinkCP:classes.dropdownLinkCP}`}>
              <span>{item.title}</span>
            </Link>:
            <Link to={item.path} key={index} className={`${activeLink===item.id?classes.activeDropdownLink:classes.dropdownLink}`}>
              <span>{item.title}</span>
            </Link>}
          </>);
        })}
    </>
  );
};

export default SubMenu;
