import React, {useState, useEffect} from "react";
import { Button, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import classes from "./CardPayment.module.css";
import { luhnCheck, expiryDateCheck } from "../../../../Utils/commonUtils";
import ConfirmationModal from "../../../../Components/ConfirmationModal/ConfirmationModal";
import {stateList} from "../../../../Utils/USStates";
import { useNavigate } from "react-router-dom";


const CardPayment = (props) => {
  const {  register, handleSubmit,reset, setError,clearErrors, formState: { errors }} = useForm();
  const navigate = useNavigate();

  const [checked, setChecked] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [cardHolderName, setCardHolderName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvv, setCvv] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [disableInput, setDisableInput] = useState(false);
  const [enteredAddress] = useState(sessionStorage.getItem("addressDetails")? JSON.parse(sessionStorage.getItem("addressDetails")): "" );
  const [ccBillingDetails] = useState(sessionStorage.getItem("ccBillingDetails")? JSON.parse(sessionStorage.getItem("ccBillingDetails")): "" );

  useEffect(()=>{
    if(checked){
        setDisableInput(true);
    }
    else{
        setDisableInput(false)
    }
    if(checked){
        let zipCombined = enteredAddress.Zip5+(enteredAddress.Zip4?"-"+enteredAddress.Zip4:"");
        let data = {
            address1: enteredAddress.Address1,
            address2: enteredAddress.Address2,
            city: enteredAddress.City,
            state: enteredAddress.State,
            zipcode: zipCombined,
            cardHolderName: cardHolderName,
            cardNumber: cardNumber,
            cardExpiry: expiryDate,
            cvv: cvv
        }
        reset(data);
        setZipcode(`${enteredAddress.Zip5}${enteredAddress.Zip4?"-":""}${enteredAddress.Zip4?enteredAddress.Zip4:""}`)
    }
    else if(!checked && ccBillingDetails.address1){
        let data = {
            address1: ccBillingDetails.address1,
            address2: ccBillingDetails.address2,
            city: ccBillingDetails.city,
            state: ccBillingDetails.state,
            zipcode: zipcode,
            cardHolderName: cardHolderName,
            cardNumber: cardNumber,
            cardExpiry: expiryDate,
            cvv: cvv
        }
        reset(data);
        setZipcode(ccBillingDetails.zipcode);
    }
    else{
        let data = {
            address1: "",
            address2: "",
            city: "",
            state: "",
            zipcode: "",
            cardHolderName: cardHolderName,
            cardNumber: cardNumber,
            cardExpiry: expiryDate,
            cvv: cvv
            
        }
        reset(data);
        setZipcode("")     
    }
    if(cardHolderName){
        validateCardHolderName(cardHolderName);
    }
    if(cardNumber){
        validateCardNumber(cardNumber);
    }
    if(expiryDate){
        validateCardExpiry(expiryDate);
    }
    
  },[checked]);

  useEffect(()=>{
    if(ccBillingDetails){
        reset(ccBillingDetails);
        setChecked(ccBillingDetails.billingAddressCheckbox);
        setCardHolderName(ccBillingDetails.cardHolderName);
        setCardNumber(ccBillingDetails.cardNumber);
        setExpiryDate(ccBillingDetails.cardExpiry);
        setCvv(ccBillingDetails.cvv);
        setZipcode(ccBillingDetails.zipcode);
    }
  },[])
  
  const submitCardPayment = (data) =>{
    data = {...data,
    billingAddressCheckbox: checked,
    zip5: parseInt(zipcode.substring(0, 5)),
    zip4: parseInt(zipcode.substring(6, 10))
    }
    if(cardNumber.length<=16 || expiryDate.length<5 || cvv.length <3){
        if(cardNumber.length<=16){
            setError("cardNumber",{ type: 'invalidCard', 
            message: 'Please enter a valid Card Number'})
        }
        if(expiryDate.length<5){
            setError('cardExpiry',{type:'expiry-error', 
            message: 'Please enter a valid expiry date'})
        }
        if(cvv.length<3){
            setError('cvv',{type:'cvv-error', 
            message: 'Please enter a valid CVV'});
        }
    window.scrollTo({top: 300, left: 0, behavior: 'smooth'});

    }
    else{
        sessionStorage.setItem("ccBillingDetails",JSON.stringify(data));
        sessionStorage.setItem("achPaymentDetails","");
        navigate("/review-order");
    }    
  }

  const cc_format = (value) => {
    let v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
    let matches = v.match(/\d{4,16}/g);
    let match = matches && matches[0] || ''
    let parts = []

    for (let i=0,  len=match.length; i<len; i+=4) {
        parts.push(match.substring(i, i+4))
    }

    if (parts.length) {
        return parts.join(' ')
    } else {
        return value
    }
}
  const validate_amexCard = (value) => {
    value = (value.replace(/(\d{4})(\d{6})(\d)/, "$1 $2 $3").substr(0, 17));
    return value;
  }

  const handleCardHolderNameInput = (e) =>{
    let {value} = e.target;
    setCardHolderName(value);
    validateCardHolderName(value);
  }

  const handleCardNoInput = (e) =>{
    let {value} = e.target;
    value = (value.replace(/[^0-9]/g,''));
    let x = value;
    x=(x+'').split('').splice(0,2).join('')
    if(x === "34" || x === "37"){
        if(value.length >=15){
            value = validate_amexCard(value);
        }
    }
    else{
        value = cc_format(value);
    }
    setCardNumber(value);
    validateCardNumber(value);
    
  }
  
  const handleExpiryInput = (e) =>{
    let {value} = e.target;
    
    if(value.length === 3){
        value = value.replace(/[^0-9/]/g,'');
    }
    else{
        value = value.replace(/[^0-9]/g,'');
    }  
    value=value.replace(/(\d{2})(\d)/, "$1/$2").substr(0, 5);
    validateCardExpiry(value);
    setExpiryDate(value);
  }
  
  const handleCvvInput = (e) =>{
    let {value} = e.target;
    value = (value.replace(/[^0-9]/g,'')).substr(0,4)
    validateCVV(value);
    setCvv(value);    
  }

  const validateCardHolderName = (value) => {
    if(value.length<1){
        setError("cardHolderName",{type:"required"})
    }
    else{
        clearErrors("cardHolderName")
    }
  }
  const validateCardNumber = (value)=>{
    if(luhnCheck(value) && value.length>=17){
        clearErrors("cardNumber")
    }
    else if(value.length>16 && !luhnCheck(value)){
        setError("cardNumber",{ type: 'invalidCard', 
        message: 'Please enter a valid Card Number'})
    }
    else if(value.length<=16){
        clearErrors("cardNumber")
    }
  }

  const validateCardExpiry = (value) =>{
    if(value.length >=5 && expiryDateCheck(value) ){
        clearErrors('cardExpiry');
    }
    else if(value.length >=5 && !expiryDateCheck(value)){
        setError('cardExpiry',{type:'expiry-error', 
        message: 'Please enter a valid expiry date'})
    }  
    else{
        clearErrors('cardExpiry');
    }
  }

  const validateCVV = (value) =>{
    clearErrors("cvv");

  }
  const zipChangeHandler = (e) =>{
    let { value } = e.target;
    if(value.length === 6){
        value = value.replace(/[^0-9-]/g,'');
    }
    else{
        value = value.replace(/[^0-9]/g,'');
    }
    if(value.length ===5 || value.length >= 9){
        clearErrors('zipcode');
    }
    else{
        setError('zipcode',{type:'zip-error'})
    }
    
    setZipcode(value.replace(/(\d{5})(\d)/, "$1-$2").substr(0, 10));
    }

  const handleClose = () =>{
    setShowConfirmModal(false);
  }
  return <div className={classes.cardPaymentContainer}>
    <form onSubmit={handleSubmit(submitCardPayment)}>
    <div className={classes.errorWarning}>
            {errors && Object.keys(errors).length > 0 && <p>*Please check fields outlined below*</p>}
        </div>
    <Row className="mb-2 mt-4">
        <label>Cardholder Name*</label>
    </Row>
    <Row className="mb-2">
        <input type="text" name="cardHolderName" value={cardHolderName} placeholder="Cardholder Name" 
        className={errors.cardHolderName ? classes.inputError : classes.inputStyle}
        {...register("cardHolderName", {required: true})}
        onChange={handleCardHolderNameInput}/>                                      
    </Row>
    <Row className="mb-2 mt-4">
        <label>Card Number *</label>
    </Row>
    <Row className="mb-2">
        <input type="text" value={cardNumber} name="cardNumber" placeholder="################" 
        className={errors.cardNumber ? classes.inputError : classes.inputStyle}
        {...register("cardNumber", {required: true})}
        onChange={handleCardNoInput}/> 
        {errors?.cardNumber?.type==="invalidCard" && 
              <p className={classes.errorMessage}>{errors.cardNumber.message}</p>}                                     
    </Row>
    <Row className="mb-2 mt-4">
        <div className={classes.expiryCvvContainer}>
            <div className={classes.expiryDiv}>
                <label>Expiration Date (MM/YY) *</label>
            </div>
            <div className={classes.cvvDiv}>
                <label>CVV *</label>
            </div>
        </div>
    </Row>
    <Row className="mb-2 ps-0 pe-0">
    <div className={classes.expiryCvvContainer}>
        <div className={classes.expiryDiv}>
            <input type="text" name="cardExpiry" placeholder="MM/YY" 
                value={expiryDate}
                className={errors.cardExpiry ? classes.inputErrorexpiry : classes.cardExpiry}
                {...register("cardExpiry", {required: true})}
                onChange={handleExpiryInput}/> 
            {errors?.cardExpiry?.type==="expiry-error" && 
            <p className={classes.errorMessage}>{errors.cardExpiry.message}</p>}
        </div>
        <div className={classes.cvvDiv}>
            <input type="text" name="cvv" placeholder="CVV" 
                value={cvv}
                className={errors.cvv ? classes.inputErrorcvv : classes.cvvInput}
                {...register("cvv", {required: true})}
                onChange={handleCvvInput}/>
        </div>
    </div>
    </Row>
    <Row className="mb-2 mt-4">
        <h6 className={classes.billingTitle}>Billing Address</h6>
    </Row>
    <div className={`mb-2 ${classes.checkBoxContainer}`}>
        <input type="checkbox" value={checked} checked={checked} onChange={()=>{setChecked(!checked)}}/>
        <label className="ms-2">Billing Address Matches <strong>Service Address</strong></label>
    </div>
    <Row className="mb-2 mt-4">
        <label>Address 1*</label>
    </Row>
    <Row className="mb-2">
        <input type="text" name="address1" placeholder="Street Address" 
        className={errors.address1 ? classes.inputError : classes.inputStyle}
        {...register("address1", {required: true})}
        disabled={disableInput}/>                                      
    </Row>
    <Row className="mb-2 mt-4">
        <label>Address 2</label>
    </Row>
    <Row className="mb-2">
        <input type="text" name="address2" placeholder="Apartment/Unit/Suite Number" 
        className={errors.address2 ? classes.inputError : classes.inputStyle}
        {...register("address2")}
        disabled={disableInput}/>                                      
    </Row>
    <Row className="mb-2 mt-4">
        <label>City*</label>
    </Row>
    <Row className="mb-2">
        <input type="text" name="city" placeholder="Enter City" 
        className={errors.city ? classes.inputError : classes.inputStyle}
        {...register("city", {required: true})}
        disabled={disableInput}/>                                      
    </Row>
    <Row className="mb-2 mt-4">
        <label>State*</label>
    </Row>
    <Row className="mb-2">
        <select id="state" {...register("state", {required: true})} 
            className={errors.state ? classes.inputError : classes.inputStyle} disabled={disableInput}>
            <option value=""> Select State </option>
            {stateList.map((item, index)=><option value={item.abbreviation} key={index}>{item.name}</option>)}                        
        </select>                                     
    </Row>
    <Row className="mb-2 mt-4">
        <label>Zipcode*</label>
    </Row>
    <Row className="mb-2">  
        <input type="text" 
            name='zipcode' 
            value={zipcode || ""} 
            placeholder='##### - ####' 
            className={errors.zipcode ? classes.inputError : classes.inputStyle}
            {...register("zipcode", {required: true,
                    pattern:/^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/
            })}
            onChange={zipChangeHandler}
            disabled={disableInput}
        />                                  
    </Row>
    <div className={classes.buttonContainer}>
        <Button onClick={()=>{setShowConfirmModal(true)}} className={classes.cancelButton}>Cancel</Button>
        <Button type="submit" className={classes.submitButton}>Next</Button>
    </div>
    </form>
    <ConfirmationModal showModal={showConfirmModal} handleClose={handleClose} />
  </div>;
};

export default CardPayment;
