import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AboutUsLayout from '../Layouts/PublicLayout/AboutUsLayout/AboutUsLayout';
import AddonLayout from '../Layouts/SignupForPackageLayout/AddonLayout/AddonLayout'
import AddressFormLayout from '../Layouts/SignupForPackageLayout/AddressFormLayout/AddressFormLayout';
import AdminHomeLayout from '../Layouts/Admin/AdminHomeLayout/AdminHomeLayout';
import BusinessLayout from '../Layouts/PublicLayout/BusinessLayout/BusinessLayout';
import CareersLayout from '../Layouts/PublicLayout/CareersLayout/CareersLayout';
import ContactInfoLayout from '../Layouts/SignupForPackageLayout/ContactInfoLayout/ContactInfoLayout';
import ContactLayout from '../Layouts/PublicLayout/ContactLayout/ContactLayout';
import FaqLayout from '../Layouts/PublicLayout/FaqLayout/FaqLayout';
import HomeLayout from '../Layouts/PublicLayout/HomeLayout/HomeLayout';
import HowItWorkLayout from '../Layouts/PublicLayout/HowItWorksLayout/HowItWorkLayout';
import PackagesLayout from '../Layouts/SignupForPackageLayout/PackagesLayout/PackagesLayout';
import RequestReviewLayout from '../Layouts/SignupForPackageLayout/RequestReviewLayout/RequestReviewLayout';
import ResidentialLayout from '../Layouts/PublicLayout/ResidentialLayout/ResidentialLayout';
import ReturnPolicyLayout from '../Layouts/PublicLayout/ReturnPolicyLayout/ReturnPolicyLayout';
import SelectLocality from '../Layouts/SignupForPackageLayout/SelectLocalityLayout/SelectLocality';
import Signup from '../Layouts/SignupForPackageLayout/SignupLayout/Signup';
import TermsNConditions from '../Layouts/PublicLayout/TermsNConditionsLayout/TermsNConditions';
import TRDLayout from '../Layouts/PublicLayout/TRDLayout/TRDLayout';
import WifiExtenders from '../Layouts/PublicLayout/WifiExtenders/WifiExtenders';
import AdminRoutes from './AdminRoutes';
import PublicRoutes from './PublicRoutes';
import PrivacyPolicy from '../Layouts/PublicLayout/PrivacyPolicyLayout/PrivacyPolicy';
import AdminAuthLayout from '../Layouts/Admin/AdminAuthLayout/AdminAuthLayout';
import CreateAccount from '../Layouts/SignupForPackageLayout/CreateAccountLayout/CreateAccount';
import ServiceTermsLayout from '../Layouts/SignupForPackageLayout/ServiceTermsLayout/ServiceTermsLayout';
import PaymentsLayout from '../Layouts/SignupForPackageLayout/PaymentsLayout/PaymentsLayout';
import ReviewOrderLayout from '../Layouts/SignupForPackageLayout/ReviewOrderLayout/ReviewOrderLayout';
import ThankyouLayout from '../Layouts/SignupForPackageLayout/ThankyouLayout/ThankyouLayout';
import CustomHomeLayout from '../Layouts/PublicLayout/CustomHomeLayout/CustomHomeLayout';
import CookiePolicy from '../Layouts/PublicLayout/CookiePolicyLayout/CookiePolicy';
import CustomerLogin from '../Layouts/CustomerAccount/CustomerLogin/CustomerLogin';
import LoggedUserRoutes from './LoggedUserRoutes/LoggedUserRoutes';
import AccountInfo from '../Layouts/CustomerAccount/AccountInfo/AccountInfo';
import UpdatePassword from '../Layouts/CustomerAccount/UpdatePassword/UpdatePassword';
import PayBillLayout from '../Layouts/CustomerAccount/PayBillLayout/PayBillLayout';
import PaymentMethod from '../Layouts/CustomerAccount/PaymentMethod/PaymentMethod';
import MyPackageDetails from '../Layouts/CustomerAccount/MyPackageDetails/MyPackageDetails';
import UpdatePackage from '../Layouts/CustomerAccount/UpdatePackage/UpdatePackage';
import StatementCenter from '../Layouts/CustomerAccount/StatementCenter/StatementCenter';
import ContactUsLayout from '../Layouts/CustomerAccount/ContactUsLayout/ContactUsLayout';
import { LoginContextRoute } from './UserAccountRoutes';
import ResetPassword from '../Layouts/CustomerAccount/ResetPassword/ResetPassword';
import ErrorPageLayout from '../Layouts/PublicLayout/ErrorPageLayout/ErrorPageLayout';


const ApplicationRoutes = () => {
    const homeUrl = (window.location.origin === (process.env.REACT_APP_PROD_URL) || window.location.origin === "https://www.synkro.us")
    return (
        <div>
            <div>
                <Routes >
                <Route element={<LoginContextRoute/>}>
                    <Route path="/customer-login" element={<CustomerLogin />} />
                    <Route path="/password-reset" element={<ResetPassword />} />
                </Route>
                <Route element={<AdminRoutes/>}>
                    <Route path="/admin" element={<AdminHomeLayout />} />
                </Route>
                <Route element={<LoggedUserRoutes/>}>
                    <Route path="/account-info/" element = {<AccountInfo /> } />
                    <Route path="/account-info/details" element = {<AccountInfo /> } />
                    <Route path="/account-info/update-pwd" element = {<UpdatePassword /> } />
                    <Route path="/billing" element = {<PayBillLayout /> } />
                    <Route path="/billing/pay" element = {<PayBillLayout /> } />
                    <Route path="/billing/method" element = {<PaymentMethod /> } />
                    <Route path="/service" element = {<MyPackageDetails /> } />
                    <Route path="/service/packages" element = {<MyPackageDetails /> } />
                    <Route path="/service/update-package" element = {<UpdatePackage /> } />
                    <Route path="/account/statement" element = {<StatementCenter /> } />
                    <Route path="/account/contact" element = {<ContactUsLayout /> } />
                </Route>
                <Route element={<PublicRoutes/>}>
                    {homeUrl ?<Route path="/home" element={<HomeLayout/>} /> :
                    <Route path="/home"  element={<CustomHomeLayout/>} />}
                    <Route path="/residential" element={<ResidentialLayout/>}/>
                    <Route path="/business" element={<BusinessLayout/>}/>
                    <Route path="/wifi-extenders" element={<WifiExtenders/>}/>
                    <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                    <Route path="/about" element={<AboutUsLayout />} />
                    <Route path="/return-policy" element={<ReturnPolicyLayout />} />
                    <Route path="/careers" element={<CareersLayout/>} />
                    <Route path="/terms" element={<TermsNConditions/>} />
                    <Route path="/trd" element={<TRDLayout/>} />
                    <Route path="/how-it-works" element={<HowItWorkLayout/>} />
                    <Route path="/contact" element={<ContactLayout />} />
                    <Route path="/faq" element={<FaqLayout />} />
                    <Route path="/select-location" element={<SelectLocality/>} />
                    <Route path="/signup/:type" element={<Signup/>} />
                    <Route path="/packages" element={<PackagesLayout />} />
                    <Route path="/address" element={<AddressFormLayout/>} />
                    <Route path="/addon" element={<AddonLayout/>}/>
                    <Route path="/contact-info" element={<ContactInfoLayout/>}/>
                    <Route path="/review" element={<RequestReviewLayout/>}/>
                    <Route path="/create-account" element={<CreateAccount />} />
                    <Route path="/service-terms" element={<ServiceTermsLayout />} />
                    <Route path="/payments" element={<PaymentsLayout/>} />
                    <Route path="/review-order" element={<ReviewOrderLayout/>} />
                    <Route path="/thankyou" element={<ThankyouLayout/>} />
                    <Route path="/cookie-policy" element={<CookiePolicy/>} />
                    {homeUrl ? <Route path="/"  element={<HomeLayout/>} /> : 
                    <Route path="/"  element={<CustomHomeLayout/>} />}
                    
                </Route>
                    <Route path="/auth/login" element={<AdminAuthLayout />} />
                    <Route path="/error" element={<ErrorPageLayout />} />
                </Routes>
            </div>
        </div>
    )
}

export default ApplicationRoutes;