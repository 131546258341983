import React , {useState, useEffect} from "react";
import { Button, Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ConfirmationModal from "../../../Components/ConfirmationModal/ConfirmationModal";
import ContactFooter from "../../../Components/ContactFooter/ContactFooter";
import SignupProgressBar from "../../../Components/SignupProgressBar/SignupProgressBar";
import classes from "./ReviewOrderLayout.module.css";
import { maskedNumbers } from "../../../Utils/commonUtils";
import userServices from "../../../Services/UserServices";
import CustomToast from "../../../Components/CustomToast/CustomToast";
import { PropagateLoader } from "react-spinners";
import { convertToTitleCase } from "../../../Utils/commonUtils";
import { accountCreationErrors } from "../../../Utils/ErrorList";
import Footbar from "../../../Components/Footbar/Footbar";

const ReviewOrderLayout = () => {
    const navigate = useNavigate();

    const [selectedPackage] = useState(sessionStorage.getItem("selectedPackage")?JSON.parse(sessionStorage.getItem("selectedPackage")):"");
    const [enteredAddress] = useState(sessionStorage.getItem("addressDetails")? JSON.parse(sessionStorage.getItem("addressDetails")): "" );
    const [ccBillingDetails] = useState(sessionStorage.getItem("ccBillingDetails")? JSON.parse(sessionStorage.getItem("ccBillingDetails")): "" );
    const [achPaymentDetails] = useState(sessionStorage.getItem("achPaymentDetails")? JSON.parse(sessionStorage.getItem("achPaymentDetails")): "" );
    const [accountData] = useState(sessionStorage.getItem("accountData")? JSON.parse(sessionStorage.getItem("accountData")): "" );
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [location] = useState(sessionStorage.getItem("location") !== undefined?JSON.parse(sessionStorage.getItem("location")): "")
    const [showToast, setShowToast] = useState(false);
    const [toastType, setToastType] = useState()
    const [toastMessage, setToastMessage] = useState("");
    const [disableSubmitButton, setDisableSubmitButton] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    let percentComplete = 75;
    let prevPercentComplete = 53;

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    const handleCreateAccount = () =>{
        setDisableSubmitButton(true);
        setShowLoader(true);
        let userData = {
            IspId:location.id,
            UserAccount:{
                Email:accountData.emailId,
                PhoneNumber:parseInt(accountData.phNo.replace(/[^0-9/]/g,'')),
                AlternatePhone:parseInt(accountData.phNo.replace(/[^0-9/]/g,'')),
                Password:accountData.password,
                FirstName:accountData.firstName,            
                LastName:accountData.lastName,
            },
            UserAddress: {
                Address1:enteredAddress.Address1,
                Address2: enteredAddress.Address2,
                City:enteredAddress.City,
                State:enteredAddress.State,
                Zip5:enteredAddress.Zip5,
                Zip4:enteredAddress.Zip4,
            },
            BillingAddress: {
                Address1:ccBillingDetails.address1,
                Address2:ccBillingDetails.address2,
                City:ccBillingDetails.city,
                State:ccBillingDetails.state,
                Zip5:ccBillingDetails.zip5,
                Zip4:ccBillingDetails.zip4,
            },              
            PackageId:parseInt(selectedPackage.packageId),
            BillingInfo: achPaymentDetails.accountNumber ? {
                Ach:{
                    RoutingNumber:parseInt(achPaymentDetails.routingNumber),
                    AccountNumber:parseInt(achPaymentDetails.accountNumber)
                }}:
                {CreditCard: {
                    CreditCardNumber: ccBillingDetails.cardNumber,
                    CVV: ccBillingDetails.cvv,
                    ExpirationDate:ccBillingDetails.cardExpiry
                }}
            }
        userServices.createAccount(userData)
        .then((response)=>{
                sessionStorage.setItem("synkroUsername",response.data.username)
                navigate("/thankyou");
                setShowLoader(false);            
        })
        .catch((err)=>{
            setDisableSubmitButton(false);
            setShowLoader(false);
            let errorCategory = accountCreationErrors.find((obj => obj.serverMessage === err.response.data.localizedErrorMessage));
            if(!errorCategory){
                errorCategory = accountCreationErrors.find((obj => obj.code === err.response.data.errorCode));
            }
            if(errorCategory){
                window.scrollTo({top: 250, left: 0, behavior: 'smooth'});
                setShowToast(true)
                setToastType("error")
                setToastMessage(errorCategory.message)
            }
            else{
                window.scrollTo({top: 250, left: 0, behavior: 'smooth'});
                setShowToast(true)
                setToastType("error")
                setToastMessage("There was an error creating your account. Please try again.")
            }
        })

    }
    const handleClose = () =>{
        setShowConfirmModal(false);
    }

    const closeToast = () =>{
        setShowToast(false);
    }

    return <>
    {showLoader && <div className={classes.loadingDiv}>
    <PropagateLoader
        color="#E84E3B"
        size={16}
    />
    </div>}
    <Container fluid className={classes.outerContainer}>
      <h2 className={classes.signupTitle}>Review & Submit Order</h2>
      <p className={classes.para1}>You’re currently signing up for {selectedPackage.name} at ${selectedPackage.monthlyFee?.toFixed(2)}</p>
      <p className={classes.para2}>Please review your billing and payment details below.</p>
      <SignupProgressBar percentComplete={percentComplete} prevPercentComplete={prevPercentComplete} active="4" />
      {/* <h2 className={classes.formTitle}>Review your Request for Service</h2> */}
      <div className={classes.reviewContainer}>
        <div className={classes.toastContainer}><CustomToast show={showToast} delay={10000} handleClose={closeToast} 
            type={toastType} message={toastMessage} />
        </div>        
        <div className={classes.innerContainer1}>
            <div className={classes.innerRow}>
                <div className={classes.leftCol}><span className={classes.sectionTitle}>Contact Information</span></div>
                <Link to="/contact-info"><div className={classes.rightCol}>
                    <span className={classes.editLink}>Edit</span>
                </div></Link>
            </div>
            <div className={classes.innerRow}>
                <div>{convertToTitleCase(accountData.firstName) + " " + convertToTitleCase(accountData.lastName)}</div>
            </div>
            <div className={classes.innerRow}>
                <div>{accountData.phNo}</div>
            </div>
            <div className={classes.innerRow}>
                <div>{accountData.emailId}</div>
            </div>
        </div>
        <div className={classes.innerContainer2}>
        <div className={classes.innerRow}>
            <div className={classes.leftCol}><span className={classes.sectionTitle}>Service Address</span></div>
        </div>
        <div className={classes.innerRow}>
            <div>{convertToTitleCase(enteredAddress.Address1)}</div>
        </div>
        <div className={classes.innerRow}>
            <div>{convertToTitleCase(enteredAddress.Address2)}</div>
        </div>
        <div className={classes.innerRow}>
            <div>{convertToTitleCase(enteredAddress.City)},{enteredAddress.State}</div>
        </div>
        <div className={classes.innerRow}>
            <div>{enteredAddress.Zip5} {enteredAddress.Zip4}</div>
        </div>
        </div>
        {ccBillingDetails?.cardNumber && 
        <div className={classes.innerContainer3}>
            <div className={classes.innerRow}>
                <div className={classes.leftCol}><span className={classes.sectionTitle}>Billing Address</span></div>
                <Link to="/payments"><div className={classes.rightCol}><span className={classes.editLink}>Edit</span></div>
                </Link>
            </div>
            <div className={classes.innerRow}>
                <div>{convertToTitleCase(ccBillingDetails.address1)}</div>
            </div>
            <div className={classes.innerRow}>
                <div>{convertToTitleCase(ccBillingDetails.address2)}</div>
            </div>
            <div className={classes.innerRow}>
                <div>{convertToTitleCase(ccBillingDetails.city)},{ccBillingDetails.state}</div>
            </div>
            <div className={classes.innerRow}>
                <div>{ccBillingDetails.zip5} {ccBillingDetails.zip4}</div>
            </div>
        </div>}
        {ccBillingDetails?.cardNumber &&
        <div className={classes.innerContainer3}>
            <div className={classes.innerRow}>
                <div className={classes.leftCol}><span className={classes.sectionTitle}>Payment Method</span></div>
                <Link to="/payments"><div className={classes.rightCol}><span className={classes.editLink}>Edit</span></div>
                </Link>
            </div>
            <div className={classes.innerRow}>
                <div>{convertToTitleCase(ccBillingDetails.cardHolderName)}</div>
            </div>
            <div className={classes.innerRow}>
                <div>{maskedNumbers(ccBillingDetails.cardNumber)}</div>
            </div>
            <div className={classes.innerRow}>
                <div>Exp.- {ccBillingDetails.cardExpiry}</div>
            </div>
            <div className={classes.innerRow}>
                <div>CVV - {ccBillingDetails.cvv}</div>
            </div>
        </div>}
        {achPaymentDetails?.accountNumber &&
        <div className={classes.innerContainer5}>
            <div className={classes.innerRow}>
                <div className={classes.leftCol}><span className={classes.sectionTitle}>Payment Method</span></div>
                <Link to="/payments"><div className={classes.rightCol}><span className={classes.editLink}>Edit</span></div>
                </Link>
            </div>
            <div className={classes.innerRow}>
                <div className={classes.leftCol}>ABA Routing Number:</div>
                <div className={classes.rightCol}>{achPaymentDetails?.routingNumber}</div>
            </div>
            <div className={classes.innerRow}>
                <div className={classes.leftCol}>Account Number:</div>
                <div className={classes.rightCol}>{maskedNumbers(achPaymentDetails?.accountNumber)}</div>
            </div>
        </div>}
        <div className={classes.innerContainer4}>
            <div className={classes.innerRow}>
                <div className={classes.leftCol}><span className={classes.sectionTitle}>Payment Details</span></div>
            </div>
            <div className={classes.innerRow}>
            <div className={classes.leftCol}>{selectedPackage.name}</div>
            <div className={classes.rightCol}>${selectedPackage.fees.find((e)=>e.name==="Monthly Price")?.price?.toFixed(2)}/month</div>
        </div>
        {selectedPackage.fees.find((e)=>e.name==="Managed Residential Wi-Fi") && 
            <div className={classes.innerRow}>
                <div className={classes.leftCol}>Modem Rental Fee</div>
                <div className={classes.rightCol}>${selectedPackage.fees.find((e)=>e.name==="Managed Residential Wi-Fi")?.price?.toFixed(2)}/month</div>
            </div>
        }
        {selectedPackage.fees.find((e)=>e.name==="Modem Rental") &&
            <div className={classes.innerRow}>
                <div className={classes.leftCol}>Managed Wifi Fee</div>
                <div className={classes.rightCol}>${selectedPackage.fees.find((e)=>e.name==="Modem Rental")?.price?.toFixed(2)}/month</div>
            </div>
        }
        <hr />
        <div className={classes.innerRow}>
            <div className={classes.leftCol}><strong>Recurring Total</strong></div>
            <div className={classes.rightCol}>${selectedPackage?.monthlyFee?.toFixed(2)}</div>
        </div>
        <div className={classes.innerRow}>
            <div className={classes.leftCol}>Set-up Fee</div>
            <div className={classes.rightCol}>${selectedPackage?.setupFee?.toFixed(2)}</div>
        </div>
        <div className={classes.innerRow}>
            <div className={classes.leftCol}>Taxes</div>
            <div className={classes.rightCol}>${selectedPackage.totalTax?.toFixed(2)}</div>
        </div>
        <hr/>
        <div className={classes.innerRowBold}>
            <div className={classes.leftCol}>Total Upon Installation</div>
            <div className={classes.rightCol}>${selectedPackage.totalPrice?.toFixed(2)}</div>
        </div>
        </div>
        <div className={classes.innerContainer5}>
            <p>
                By clicking Submit Order, I understand that I am authorizing synKro to charge my payment method on 
                file for:
            </p>
            <ul>
                <li>The <span className={classes.boldText}>Total Upon Installation </span> following the successful installation of my synKro Internet Service.</li>
                <li>The <span className={classes.boldText}>Recurring Total </span> (plus taxes) each following month for my monthly service fees.</li>
            </ul>
        </div>
        <div className={classes.buttonContainer}>
            <Button onClick={()=>{setShowConfirmModal(true)}} className={classes.cancelButton}>Cancel</Button>
            <Button onClick={handleCreateAccount} className={classes.submitButton} disabled={disableSubmitButton}>Submit Order</Button>
        </div>
      </div>
    <ConfirmationModal showModal={showConfirmModal} handleClose={handleClose} />

    </Container>
    <ContactFooter isDark={false} />
    <Footbar />
  </>;
};

export default ReviewOrderLayout;
