export const accountCreationErrors = [{
        code: "E:G-Null",
        serverMessage:"User address could not be resolved to latitude and longitude.",
        message: "An error occurred while validating the service address provided. Please check the address and try again. If the issue persists please contact us at (844) 883-1659."
    },
    {
        code: "E:G-Null",
        serverMessage:"Billing address could not be resolved to latitude and longitude.",
        message: "An error occurred while validating the billing address provided. Please check the address and try again. If the issue persists please contact us at (844) 883-1659."
    },
    {
        code:"E:G-Null",
        serverMessage:"Email is null or whitespace.",
        message:"Please check that a valid email address has been provided and try again."
    },
    {
        code:"E:G-Null",
        serverMessage:"Phone number is null.",
        message:"Please check that a phone number has been provided and try again."

    },
    {
        code:"E:G-Null",
        serverMessage:"Password is null.",
        message:"Please check that a password has been entered and try again."
    },
    {
        code:"E:AC-0",
        serverMessage:"Password did not meet requirements.",
        message:"Password provided does not meet synKro's secure password requirements."
    },
    {
        code:"E:AC-1",
        errorMessage:"Phone number could not be validated to be a part of the North American Numbering Plan.",
        message: "Phone number should be 10 digits. Please review and try again"
    },
    {
        code:"E:G-Email",
        serverMessage:"Email address was not validated to meet the requirements of RFC822, RFC821, RFC2822, RFC2821, RFC5322, nor RFC5321.",
        message: "There was an error with the email address provided. Please review and try again."
    },
    {
        code:"E:AC-3",
        serverMessage:"Account already exists.",
        message: "We are showing an account already exists with the email address provided. Please enter a different email address or login above."
    },
    {
        code:"E:AC-2",
        serverMessage:"Could not create account.",
        message: "An unknown error has occurred while creating an account. Please contact us at (844) 883-1659"
    },
    {
        code: "E:G-Unkown",
        serverMessage:"Unknown error occured while attaching billing info.",
        message: "Server error. Please try again later."
    },
    {
        code: "E:BL-2",
        serverMessage:"Credit card CVV was not the correct length or had invalid characters.",
        message: "Please enter a valid CVV"
    },
    {
        code: "E:BL-1",
        serverMessage:"Credit card expiration date was not the correct length or had invalid characters.",
        message: "Please enter a valid expiration as MM/YY"
    },
    {
        code: "E:BL-3",
        serverMessage:"Bank routing number did not pass test.",
        message: "Please Enter a valid Routing Number"
    },
    {
        code: "E:BL-4",
        serverMessage:"Bank account number was either longer than 20 characters or not made entirely of digits.",
        message: "Please Enter a valid Account Number"
    }
    ]

    export const announcementErrors = [{
        code: "E:G-Null",
        serverMessage: "Announcement text was null.",
        message:""
    }]

