import React, {useEffect,useState, useContext} from "react";
import { Container, Row, Button } from "react-bootstrap";
import {useForm} from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ContactFooter from "../../../Components/ContactFooter/ContactFooter";
import SignupProgressBar from "../../../Components/SignupProgressBar/SignupProgressBar";
import classes from "./ContactInfoLayout.module.css";
import  SignupData  from "../../../Context/PublicUserContext";
import Footbar from "../../../Components/Footbar/Footbar";

const ContactInfoLayout = () => {
  const navigate = useNavigate();
  const {setUserFirstName} = useContext(SignupData);
  const {  register, handleSubmit,reset, setError, clearErrors,formState: { errors }} = useForm();
  const [selectedPackage] = useState(sessionStorage.getItem("selectedPackage") ?JSON.parse(sessionStorage.getItem("selectedPackage")):"");
  const [accountData] = useState(sessionStorage.getItem("accountData")? JSON.parse(sessionStorage.getItem("accountData")): "" );
  const [phoneNo, setPhoneNo] = useState("");
  const [altPhoneNo,setAltPhoneNo] = useState("");

  let percentComplete = 0;
  let prevPercentComplete = 0;

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  useEffect(()=>{
    if(accountData){
      let data = {
        firstName: accountData.firstName,
        lastName : accountData.lastName,
      }
      setPhoneNo(accountData.phNo);
      setAltPhoneNo(accountData.alternatePhNo)
      reset(data);
    }
  },[])

  const onSubmit = (data)=>{
    if(phoneNo.length<14 || (altPhoneNo?.length !==0 && altPhoneNo?.length<14)){
      if(phoneNo.length<14){
        setError("phNumber",{type:'ph-error'});
      }
      if(altPhoneNo?.length !==0 && altPhoneNo?.length<14){
        setError("altphNumber",{type:'altph-error'});
      }
    }
    else{
      sessionStorage.setItem("accountData", JSON.stringify({...accountData,firstName:data.firstName,lastName:data.lastName, phNo: phoneNo, alternatePhNo:altPhoneNo }))
      setUserFirstName(data.firstName);
      navigate("/service-terms");
    }
    
  }

    const handlePhoneNumber = (e) =>{
      let {value}=e.target;
      value = value.replace(/[^0-9()-\s]/g, '');
      let cleaned = ('' + value).replace(/\D/g, '');
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        let formattedNo = ((`(${match[1]}) ${match[2]}-${match[3]}`))
        setPhoneNo(formattedNo);
        clearErrors("phNumber")
      }
      else if(value.length<15){
        setPhoneNo(value);
        clearErrors("phNumber")
      }
    }
    const handleAltPhoneNumber = (e) =>{
      let {value}=e.target;
      value = value.replace(/[^0-9()-\s]/g, '');
      let cleaned = ('' + value).replace(/\D/g, '');
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
      let formattedNo = ((`(${match[1]}) ${match[2]}-${match[3]}`))
        setAltPhoneNo(formattedNo);
        clearErrors("altphNumber")
      }
      else if(value.length<15){
        setAltPhoneNo(value);
        clearErrors("phNumber")
      }
    }
   
  return <>
    <Container fluid className={classes.outerContainer}>
      <h2 className={classes.signupTitle}>Sign Up for Broadband Internet</h2>
      <p className={classes.para1}>You’re currently signing up for {selectedPackage.name} at ${selectedPackage.monthlyFee?.toFixed(2)}</p>
      <SignupProgressBar percentComplete={percentComplete} prevPercentComplete={prevPercentComplete} active="1" />
      <h2 className={classes.formTitle}>Please Provide Us Your Contact Information</h2>
      
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container className={classes.formContainer}>
          {<div className={classes.errorWarning}>
            {(errors.firstName || errors.lastName || errors.phNumber) && <p>*please check fields outlined below*</p>}
          </div>}
          <h5>Contact Information</h5>            
        <Row>
           <label> First Name*</label>
        </Row>
        <Row>
            <input type="text" 
                name="firstName" 
                placeholder='Enter Name' 
                className={errors.firstName ? classes.addressInputError : classes.addressInput}
                {...register("firstName", {required: true})}/>
        </Row>
        <Row>
            <label>Last Name*</label>
        </Row>
        <Row>
            <input type="text" name="lastName" placeholder='Enter Name' 
              className={errors.lastName ? classes.addressInputError : classes.addressInput}
             {...register("lastName", {required: true})} />
        </Row>
        <Row>
           <label> Mobile Number*</label>
        </Row>
        <Row>
            <input type="text" 
                name='phNumber' 
                value = {phoneNo || ""}
                placeholder='(###) ###-####' 
                className={errors.phNumber ? classes.addressInputError : classes.addressInput} 
                {...register("phNumber", {required: true,
                     pattern:/^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/
                })}
                onChange={handlePhoneNumber}
              />
        </Row>
        <Row>
           <label> Alternate Mobile Number</label>
        </Row>
        <Row>
            <input type="text" 
                name='altphNumber'
                value={altPhoneNo || ""} 
                placeholder='(###) ###-####' 
                className={errors.altphNumber ? classes.addressInputError : classes.addressInput} 
                {...register("altphNumber", {pattern:/^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/})}  
                onChange={handleAltPhoneNumber}              
              />
        </Row>
        <Button type='submit' className={classes.nextButton}>Next</Button>
        </Container>
        </form>
    </Container>    
    <ContactFooter isDark={false} />
    <Footbar />
  </>;
};

export default ContactInfoLayout;
