import React,{useState} from "react";
import { Accordion, Card, useAccordionButton } from "react-bootstrap";
import "./CustomAccordion.css";


const CustomAccordion = (props) => {
  const [activeKey, setActiveKey] = useState(0);

    const  CustomToggle = ({ children, eventKey, handleClick }) => {
        const decoratedOnClick = useAccordionButton(eventKey, () => {
          handleClick();
        });
      
        return (
          <div className={`card-header ${eventKey===2 && activeKey !== eventKey ? "finalRow" : ""}`} type="button" onClick={decoratedOnClick}>
            {children}
          </div>
        );
      }
  return <div className="customAccordion">
    <Accordion defaultActiveKey={0} activeKey={activeKey}>
        {props.data.map((item, index) => (
          <div key={index}>
            <CustomToggle
              as={Card.Header}
              eventKey={index}
              handleClick={() => {
                if (activeKey === index) {
                  setActiveKey(null);
                } else {
                  setActiveKey(index);
                }
              }}
            >
              {item.title}
              <span className="toggleIcon">
                {activeKey === index ? "-" : "+"} 
              </span>              
            </CustomToggle>
            <Accordion.Collapse eventKey={index}>
              <><p>{item.content}</p>              
              {item.para2 && <span>{item.para2}</span>}
              </>
            </Accordion.Collapse>
        </div>
        ))}
      </Accordion>
  </div>;
};

export default CustomAccordion;

