import React, { useContext } from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import classes from './ContactFooter.module.css';

const ContactFooter = ({isDark, customerPartner}) => {
  return (
    <Container fluid  className={isDark? classes['is-dark']: classes['is-light']}>
        <Row className={classes.contactFooterRow1}>
            <Row><Col className={classes.contactFooterCol1}>Questions?</Col></Row>
            <Row><Col className={classes.contactFooterCol2}>We're Here to Help.</Col></Row>
        </Row>
        <Row className={classes.contactFooterRow2}>
            <Col sm={1} md={3}>Phone
                <div className={classes.phoneNumberContainer}>
                    {customerPartner === "Adept Internet Solution" &&"509-684-7897"}
                    {customerPartner === "Lamar Wireless" &&"(903) 785-3474"}
                    {!customerPartner &&"(884) 883-1659"}
                </div>
            </Col>
            <Col sm={1} md={3}>Email
                <div className={classes.emailContainer}>
                {customerPartner === "Adept Internet Solution" && 
                    <a href="mailto: Sales@adept4u.com" >Sales@adept4u.com</a>}
                {customerPartner === "Lamar Wireless" &&
                    <a href="mailto: lamar.satellite@gmail.com" >lamar.satellite@gmail.com</a>}
                {!customerPartner &&
                    <a href="mailto: info@synkro.us" className={classes.synkroEmailLink}>info@synkro.us</a>}
                </div>
            </Col>
        </Row>
        {!customerPartner && <Row className={classes.contactFooterRow3}>
            <Col className={isDark? classes['contactButton-Bg']: classes['contactButton-Lg']}>
                <Link to="/contact">Contact Us</Link>
            </Col>
        </Row>}
    </Container>
  )
}

export default ContactFooter