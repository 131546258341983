import React, {useState} from "react";
import {Button, Row, } from "react-bootstrap";
import { useForm } from "react-hook-form";
import CustomRadioButton from "../../../Components/CustomRadioButton/CustomRadioButton";
import classes from "./NewLocation.module.css";
import services from "../../../Services/UserServices";
import CustomToast from "../../../Components/CustomToast/CustomToast";
import {stateList} from "../../../Utils/USStates";

const NewLocation = () => { 
    const {  register, handleSubmit,reset, formState: { errors }} = useForm();
    const radioItems =["Radius","Shape Upload"];
    const [radioSelected, setRadioSelected] = useState();
    const [show, setShow] = useState(false);
    const [toastType, setToastType] = useState();
    const [toastMessage, setToastMessage] = useState("");

    const handleChange =(e)=>{
        setRadioSelected(e.target.value);
    }
    const onSubmit = data => {
        let sData = {
            City : data.city,
            State : data.state,
            VispIspId : data.ispId,
            VispPortalUrl : "",
            Radius : data.radius,
            FutureLocation : false,
            Latitude: data.latitude,
            Longitude : data.longitude
        }
        
        services.newCircularServiceLocation(sData)
        .then((resp)=>{
            setToastType("success")
            setShow(true);
            setToastMessage("Your new location has been successfully added!")
            reset()
        })
        .catch((err)=>{
            setToastType("error")
            setShow(true);
            setToastMessage("There was an error adding your location. Please try again.")
        })
    }
    const closeToast = () =>{
        setShow(false);
    }

  return <div className={classes.newLocationContainer}>
    <h3>Add New Service Location</h3>
    <p>Do you want to add location by Radius or by Shape Upload?</p>
    <CustomRadioButton items={radioItems} 
        name="locationType" 
        handleChange={handleChange} 
        checked={radioSelected}
        customerPartner={false}/>
    {radioSelected ==="Radius" && (
        <div className={classes.formContainer1}>
            <form onSubmit={handleSubmit(onSubmit)}>
            {(errors.ispId || errors.city || errors.state || errors.longitude || errors.latitude || errors.radius)
              && <p className={classes.errorMessage}>*Please check fields outlined below*</p>}

                <Row className="mb-1 mt-2">
                    <label>ISP ID*</label>
                </Row>
                <Row className="mb-2">
                    <input type="text" name="ispId" placeholder="Enter ISP ID" 
                    className={errors.ispId ? classes.inputError : ""}
                    {...register("ispId", {required: true})}/>                                      
                </Row>
                <Row className="mb-1">
                    <label>Location*</label>
                </Row>
                <Row className={classes.locationRow}>
                    <input type="text" name="city" placeholder="Enter City" 
                        className={errors.city ? classes.inputError : ""}
                        {...register("city", {required: true})} />
                    <select id="state" {...register("state", {required: true})} 
                        className={errors.state ? classes.inputError : ""}>
                        <option value=""> Select State </option>
                        {stateList.map((item, index)=><option value={item.abbreviation} key={index}>{item.name}</option>)}                        
                    </select>
                </Row>
                <Row className="mb-1 mt-2">
                    <label>Enter Longitude*</label>
                </Row>
                <Row className="mb-2">
                    <input type="number" step="0.000001" name="longitude" placeholder="Enter Longitude" 
                        className={errors.longitude ? classes.inputError : ""}
                    {...register("longitude", {required: true, min: -180, max: -60} )}
                    onWheel={(e) => e.target.blur()}/>                                      
                </Row>
                {(errors.longitude?.type==="min" || errors.longitude?.type==="max") && <p className={classes.errorMessageInline}>
                    Please enter a valid longitude between -180 and -60</p>}
                <Row className="mb-1 mt-2">
                    <label>Enter Latitude*</label>
                </Row>
                <Row className="mb-2">
                    <input type="number" step="0.000001" name="latitude" placeholder="Enter Latitude" 
                        className={errors.latitude ? classes.inputError : ""}
                        {...register("latitude", {required: true, min: -90, max: 90} )}
                        onWheel={(e) => e.target.blur()} />                                      
                </Row>
                {(errors.latitude?.type==="min" || errors.latitude?.type==="max") && <p className={classes.errorMessageInline}>
                    Please enter a valid latitude between -90 and 90</p>}
                <Row className="mb-1 mt-2">
                    <label>Enter Radius(kms)*</label>
                </Row>
                <Row className="mb-2">
                    <input type="number" step="0.01" name="radius" placeholder="Enter Radius" 
                        className={errors.radius ? classes.inputError : ""}
                        {...register("radius",{required: true, min: 0, max: 20} )} 
                        onWheel={(e) => e.target.blur()}/>                                      
                </Row>
                {(errors.radius?.type==="min" || errors.radius?.type==="max") && <p className={classes.errorMessageInline}>
                    Please enter a valid radius between 0 and 20</p>}
                <Row>
                    <CustomToast show={show} delay={3000} handleClose={closeToast} type={toastType} message={toastMessage} />
                </Row>
                <div className={classes.butonContainer}>
                    <Button type='submit' className={classes.createButton}>Create Service Location</Button>
                </div>
            </form>
        </div>
    )}
    {radioSelected ==="Shape Upload" && (
        <form>
            Shape Upload Feature Coming Soon...
        </form>
    )}
  </div>;
};

export default NewLocation;
