import React,{ useEffect,useState } from "react";
import {Link} from "react-router-dom";
import { Button, Card, Container } from "react-bootstrap";
import StepProgressBar from "../../../Components/StepProgressBar/StepProgressBar";
import classes from "./AddonLayout.module.css";
import ContactFooter from "../../../Components/ContactFooter/ContactFooter";
import Footbar from "../../../Components/Footbar/Footbar";


const AddonLayout = () => {
  const [selectedLocation] = useState(JSON.parse(sessionStorage.getItem("location")));
  const [selectedPackage] = useState(sessionStorage.getItem("selectedPackage") ?JSON.parse(sessionStorage.getItem("selectedPackage")):"");

  const [addOns, setAddOns] = useState([]);
    let percentComplete = 20;
    let prevPercentComplete = 0;

    let addOnsArr = [
        {
            addon_Id: 63155,
            name: "Unlimited",
            description: "",
            fee: 29.99,
        },
        {
            addon_Id: 63156,
            name: "Unlimited",
            description: "",
            fee: 29.99,
        },
        {
            addon_Id: 63157,
            name: "Unlimited",
            description: "",
            fee: 29.99,
        },
        {
            addon_Id: 63158,
            name: "Unlimited",
            description: "",
            fee: 29.99,
        }
    ]

    useEffect(() => {
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      setAddOns(addOnsArr);
    }, []);
  return (
  <>
  <Container fluid className={classes.outerContainer}>
      <h2 className={classes.signupTitle}>Sign Up for Broadband Internet</h2>
      <p className={classes.para1}>You’re currently signing up for {selectedPackage.name} at ${selectedPackage.monthlyFee.toFixed(2)}</p>
      <p className={classes.para2}>Not in {selectedLocation.city}? <a href='/select-location'>Find your town.</a></p>
      <StepProgressBar percentComplete={percentComplete} prevPercentComplete={prevPercentComplete} active="2" />
      <Container fluid className={classes.formContainer}>
      <h2 className={classes.formTitle}>Select Broadband Internet Add-Ons</h2>
      <div className={classes.cardContainer}>
          {addOns.map((item)=>{
            return(
            <Card className={classes.cardStyle}>
            <Card.Body className={classes.cardBodyStyle}>
              <div className={classes.cardTitleContainer}>
                <h5 className={classes.cardTitle}>{item.name}</h5>
                <p className={classes.cardTitleFee}>${item.fee.toFixed(2)}/Month</p>
              </div>
              <div className={classes.descriptionContainer}>
                {/* {item.description} */}
                <p>
                    [Add_On_Description_Goes_Here] [Add_On_Description_Goes_Here] [Add_On_Description_Goes_Here]  
                </p>
                <p>[Extra_Description_Line_Here]</p>
                <p>
                    <input type="checkbox" /> Add this to my Broadband Service!
                </p>
              </div>
              {/* <div className={classes.buttonContainer}>
                
              </div> */}
            </Card.Body>
          </Card>)
          })
          }     
        </div>
        <Link to="/contact-info">
            <Button className={classes.selectButton}>
                Next
            </Button>
        </Link>
    </Container>
    </Container>
    <ContactFooter isDark={false} />
    <Footbar />
    </>)
};

export default AddonLayout;
