import React, { useEffect, useContext } from "react";
import UserHomeData from "../../../Context/UserAccountContext";
import classes from "./PayBillLayout.module.css";

const PayBillLayout = () => {
    const {setActiveLink} = useContext(UserHomeData);

    useEffect(()=>{
        setActiveLink(2.1);
    },[])
  return <div>PayBillLayout</div>;
};

export default PayBillLayout;
