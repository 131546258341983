import React from 'react'
import { useEffect } from 'react'
import classes from './AboutUsLayout.module.css'
import CustomButtonDarkBg from '../../../Components/CustomButtonDarkBg/CustomButtonDarkBg'
import { Link } from 'react-router-dom'
import Footbar from '../../../Components/Footbar/Footbar'

const AboutUsLayout = () => {
    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);
  return (<>
    <div className={classes.aboutUsContainer}>
        <div className={classes.aboutInnerDiv1}>
            <h1>We're here to serve you</h1>
            <p>We understand the importance of staying connected and the role we, our network, and our employees 
                play in keeping our customers and rural communities connected during this critical time. As 
                communities across our country take steps to slow and contain this outbreak, our subscribers can 
                have peace of mind knowing that we are prepared. Our broadband Internet services will continue to 
                enable families to work from home, attend school from home, and perform typical day-to-day 
                activities – whether that’s calling a loved one, watching a movie, video conferencing, social 
                media, online shopping, or any other form of communication or service that you rely on synKro to 
                provide.</p>
        </div>
        <div className={classes.aboutInnerDiv2}>
            <div className={classes.aboutInnerDiv2Col1}>
                <h3>It started with a dream.</h3>
            </div>
            <div className={classes.aboutInnerDiv2Col2}>
                <p>But first, really, it was a question. Why is Internet service so poor in rural areas and small towns? 
                    Seriously, why?
                </p>
                <p>It’s not because of a lack of talk about creating better access. You can find endless articles 
                    discussing the need to develop broadband Internet service and appeals to make investments and 
                    rollout improvements. However, the reality was that, despite all the clamor, very little was 
                    actually getting done.
                </p>
                <p className={classes.boldText}>You Deserve Better</p>
                <p>Today, broadband Internet is no longer a luxury, it is a necessity. It is said that technology 
                    is the great equalizer. Having equal access to technology resources is not only important to 
                    the economic well-being of your area it is critical to the strength of our nation.
                </p>
                <p className={classes.boldText}>The Answer is Ingenuity</p>
                <p className={classes.lastPara}>We at synKro, being who we are, are not much for talk and greatly prefer action. We believed 
                    that there must be a better way to deliver affordable and fast Internet service to smaller 
                    communities. So, we rolled up our sleeves and invented a way. 
                    <span className={classes.boldText}>The result is our patented technology that brings the full 
                    power of the Internet to small towns and rural areas across the U.S.</span>
                </p>
                <Link to="/select-location">
                    <CustomButtonDarkBg buttonText="Check Availability" />
                </Link>

            </div>
        </div>

            <div className={classes.aboutInnerDiv3}>
                <div className={classes.aboutInnerDiv3Col1}>
                </div>
                <div className={classes.aboutInnerDiv3Col2}>
                    <h2>Our technology.</h2>
                    <p>Our company is built to serve the unique Internet needs of rural areas and small towns 
                        across the U.S. Our proprietary 5G fixed wireless technology allows us to bring high-speed 
                        broadband Internet to areas that traditional providers find hard to reach. Our service is 
                        superior to DSL, satellite, and cellular offerings which can suffer from performance issues 
                        due to a lack of speed, latency (delays), weather, and data caps. With <i>synKro</i>, experience 
                        superior service without limits and <i>Internet You Can Use.</i>
                    </p>
                    <p><i>synKro</i> is powered by <i>LocaLoop</i>, our U.S. based parent company. <i>LocaLoop</i> combines 
                        its secure cloud-based software technology and robust industry-leading network 
                        hardware into an innovative service that is convenient, reliable, and puts you in control.
                    </p>
                </div>

            </div>       
    </div>
    <Footbar />
    </>
  )
}

export default AboutUsLayout