import React,{useEffect} from "react";
import {Link} from "react-router-dom";
import classes from "./ResidentialLayout.module.css";
import image1 from "../../../Assets/Images/residentialImage-2.jpg";
import image2 from "../../../Assets/Images/residentialImage-3.jpg";
import image3 from "../../../Assets/Images/residentialImage-4.jpg";
import CustomButtonDarkBg from "../../../Components/CustomButtonDarkBg/CustomButtonDarkBg";
import Footbar from "../../../Components/Footbar/Footbar";

const ResidentialLayout = () => {
    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);
  return(<>
  <div className={classes.outerContainer}>
    <div className={classes.topContainer}>
        <h1>Living connected</h1>
        <h5>We’re passionate about helping to connect you and your neighbors. </h5>
    </div>
    <div className={classes.middleContainer}>
        <h1 className={classes.centerTitle}>Do more with synKro.</h1>
        <div className={classes.midInnerContainer}>
            <div className={classes.innerColumns}>
                <div className={classes.roundImageContainer}>
                    <img src={image1} alt="work"/>
                </div>
                <div className={classes.imageTitle}>Work</div>
                <div className={classes.imageDescription}>
                    Rely on synKro’s strong 5G network for when you need it most 
                </div>
            </div>
            <div className={classes.innerColumns}>
            <div className={classes.roundImageContainer}>
                <img src={image2} alt="life"/>
            </div>
                <div className={classes.imageTitle}>Life</div>
                <div className={classes.imageDescription}> 
                    synKro’s unlimited service plans keeps you connected with your friends, and family 24/7
                </div>
            </div>
            <div className={classes.innerColumns}>
            <div className={classes.roundImageContainer}>
                <img src={image3} alt="play"/>
            </div>
                <div className={classes.imageTitle}>Play</div>
                <div className={classes.imageDescription}>
                    synKro’s technology and team of local techs allows you stream movies and play games without pause    
                </div>
            </div>
        </div>
    </div>
    <div className={classes.bottomContainer}>
        <h1>Pick a service plan.*</h1>
        <div className={classes.bottomInnerDiv}>
            <div className={classes.bottomInnerCol}>
                <h2>Unlimited</h2>
                <p>Speeds up to <strong>10 Mbps download/2 Mbps upload.</strong> No data limits. Ideal for individuals with 
                    moderate needs. Stream videos with Netflix, browse the web, send emails and interact on 
                    social media.</p>
            </div>
            <div className={classes.bottomInnerCol}>
                <h2>Unlimited PLUS</h2>
                <p>Speeds up to <strong>25 Mbps download/3 Mbps upload.</strong> No data limits. Our most popular plan is ideal 
                    for families who stream videos like Netflix and Hulu, play online games such as Minecraft, 
                    and need to connect many devices simultaneously.
                </p>
            </div>
            <div className={classes.bottomInnerCol}>
                <h2>Unlimited MAGNUM</h2>
                <p>Speeds up to <strong>100 Mbps download/10 Mbps upload.</strong> No data limits. Ideal for the highly connected 
                    family. Stream videos such as Netflix on a multitude of devices, download/upload larger files, 
                    play multiplayer online games in real time such as Fortnite and Battlefield.
                </p>
            </div>
        </div>
        <div className={classes.buttonContainer}>
        <div><Link to="/select-location"><CustomButtonDarkBg buttonText="Check Availability" /></Link></div>
        <div className={classes.disclaimerText}><p><strong>*Note:</strong> The specific services and their speeds may vary by 
        location. Choose your service address and you will see exactly what synKro services are available to you.</p></div>
        </div>
    </div>
  </div>
  <Footbar />
  </>
)
};

export default ResidentialLayout;
