import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import Footbar from "../../../Components/Footbar/Footbar";
import classes from './CookiePolicy.module.css';

const CookiePolicy = () => {
    useEffect(()=>{
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    },[]);
  return <>
    <Container fluid className={classes.mainContainer}>
    <h1>Cookie policy</h1>
    <p>A cookie is a small text file sent to your browser from most websites you visit. This is a way for the 
      site to remember information about your visit, for example which language you prefer to use and additional 
      settings. It facilitates the next visit, and the site becomes more useful to our customers. NOTE! If you 
      choose to completely disable cookies via your browser settings the site may not function properly.
    </p>
    <p>This site uses cookies to keep track of which customer type you have (eg residential or business) and the 
      locale(s) you have selected. No personal information, such as an e-mail address nor names are saved in 
      cookies.
    </p>
    <p>Google Analytics may be used to analyze how the website is visited, all information collected is anonymous 
      and is used to understand and improve your visit to the website.
    </p>
    <h5>Do Not Track Disclosures</h5>
    <p>Do Not Track (DNT) is a privacy option that a user can set in certain web browsers. When a user turns on 
      the Do Not Track signal, the browser will send a message to websites requesting them not to track the user. 
      We do not respond to Do Not Track browser settings or signals. NOTE! Whether or not you have turned on the 
      Do Not Track signal, we may deploy cookies and other technologies (Google Analytics) on our websites. We 
      have no control over third parties’ (eg Google Analytics, Facebook and Twitter) use of or tracking 
      collecting personally identifiable information even if such third party is accessed through our website.
    </p>
    </Container>
    <Footbar />
   </>;
};

export default CookiePolicy;
