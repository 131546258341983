import React, { useEffect, useState, useContext } from "react";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { PropagateLoader } from "react-spinners";
import CustomRadioButton from "../../../Components/CustomRadioButton/CustomRadioButton";
import CustomToast from "../../../Components/CustomToast/CustomToast";
import UserHomeData from "../../../Context/UserAccountContext";
import LoginServices from "../../../Services/LoginServices";
import { getLastNdigits, luhnCheck, maskedNumbers } from "../../../Utils/commonUtils";
import classes from "./PaymentMethod.module.css";
import "./PaymentMethodCheckbox.css";

const PaymentMethod = () => {
    const {setActiveLink, userRefreshToken, userAccountInfo} = useContext(UserHomeData);
    const {  register, handleSubmit,reset, clearErrors, setError, formState: { errors }} = useForm();
    const [defaultState, setDefaultState] = useState(true);
    const [paymentOperation, setPaymentOperation] = useState("");
    const [paymentTypeSelected,setPaymentTypeSelected] = useState("Credit/Debit Card");
    const [paymentInfo, setPaymentInfo] = useState("");
    const [cardNumber,setCardNumber] = useState("");
    const [autoPayStatus, setAutoPayStatus] = useState(true);
    const [consentCheck, setConsentCheck] = useState(false);
    const [customerPartner,setCustomerPartner] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastType, setToastType] = useState("");
    const paymentOperations = ["Update Current Payment Method"]
    const [loading, setLoading] = useState(true);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const currentYear = new Date().getFullYear();

    useEffect(()=>{
        setActiveLink(2.2);
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        getPaymentInfo();             
    },[])

    useEffect(()=>{
      if(userAccountInfo?.locationDetails?.city?.toLowerCase()==="paris" || userAccountInfo?.locationDetails?.city?.toLowerCase()==="colville"){
        setCustomerPartner(true);
      }
      else{
        setCustomerPartner(false);
      }
    },[userAccountInfo])

    const getPaymentInfo = () => {
      LoginServices.getBillingInfo()
      .then((resp)=>{
        setLoading(false);
        setPaymentInfo(resp.data);
        setAutoPayStatus(resp.data.autoBill);
        let formData = {
          // cardHolderName: resp.data.creditCards[0].name,
          cardNumber: maskedNumbers(resp.data.creditCards[0].number),
          cvv: "###",
          expiryMonth: resp.data.creditCards[0].expirationMonth<10 ? '0'+resp.data.creditCards[0].expirationMonth : resp.data.creditCards[0].expirationMonth,
          expiryYear: getLastNdigits(resp.data.creditCards[0].expirationYear, 2)
        }
        if(paymentOperation !=="update"){
          reset(formData);
        }
      })
      .catch((err)=>{
        setLoading(false);
      })
    }

    const handleUpdateButton = () => {
      let emptyFormData = {
        // cardHolderName: "",
        cardNumber: "",
        cvv: "",
        expiryMonth: "",
        expiryYear: ""
      }
      setPaymentOperation("update");
      reset(emptyFormData);
      setDefaultState(false);
    }

    const handleUpdatePayment=(data)=>{
      setLoadingUpdate(true);
      let cardData = {
        Number: data.cardNumber,
        ExpirationDate: `${data.expiryMonth}/${getLastNdigits(data.expiryYear, 2)}`,
        cvv:data.cvv,
        Default: false
      }
      if(paymentInfo.creditCards[0]){
        LoginServices.updateCreditCard(cardData, paymentInfo.creditCards[0]?.key)
        .then((resp)=>{
          setDefaultState(true);
          setPaymentOperation("");
          setLoadingUpdate(false);
          setShowToast(true);
          setToastType("success");
          setToastMessage("Data has been updated successfully!");
        })
        .catch((err)=>{
          setLoadingUpdate(false);
          if(err.response.status === 400 || err.response.status === 500 || err.response.status === 404){
            setShowToast(true);
            setToastType("error")
            setToastMessage("There was an error updating data.Please try again.")
          }
        })
      }
      else{
        LoginServices.addCreditCard(cardData)
        .then((resp)=>{
          setDefaultState(true);
          setPaymentOperation("");
          setLoadingUpdate(false);
          setShowToast(true);
          setToastType("success");
          setToastMessage("Data has been updated successfully!");
        })
        .catch((err)=>{
          setLoadingUpdate(false);
          if(err.response.status === 400 || err.response.status === 404){
            setShowToast(true);
            setToastType("error")
            setToastMessage("There was an error updating data.Please try again.")
          }
        })
      }      
    }

    // const handleRadioButton =(e)=>{
    //   setPaymentOperationSelected(e.target.value);
    //   if(e.target.value==="Update Current Payment Method"){
    //     setPaymentOperation("update");
    //   }
    //   else if(e.target.value==="Add New Payment Method"){
    //     setPaymentOperation("add");
    //   }
    // }
    const handlePaymentType = (e) =>{
      setPaymentTypeSelected(e.target.value);
    }

    const handleCardNoInput = (e) =>{
      let {value} = e.target;
      value = (value.replace(/[^0-9]/g,''));
      let x = value;
      x=(x+'').split('').splice(0,2).join('')
      if(x === "34" || x === "37"){
          if(value.length >=15){
              value = validate_amexCard(value);
          }
      }
      else{
          value = cc_format(value);
      }
      setCardNumber(value);
      validateCardNumber(value);
    }

    const validate_amexCard = (value) => {
      value = (value.replace(/(\d{4})(\d{6})(\d)/, "$1 $2 $3").substr(0, 17));
      return value;
    }
    const cc_format = (value) => {
      let v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
      let matches = v.match(/\d{4,16}/g);
      let match = matches && matches[0] || ''
      let parts = []

      for (let i=0,  len=match.length; i<len; i+=4) {
          parts.push(match.substring(i, i+4))
      }

      if (parts.length) {
          return parts.join(' ')
      } else {
          return value
      }
    }
    const validateCardNumber = (value)=>{
      if(luhnCheck(value) && value.length>=17){
          clearErrors("cardNumber")
      }
      else if(value.length>16 && !luhnCheck(value)){
          setError("cardNumber",{ type: 'invalidCard', 
          message: 'Please enter a valid Card Number'})
      }
      else if(value.length<=16){
          clearErrors("cardNumber")
      }
    }

  return <div className={classes.PaymentInfoOuterContainer}>
    <div className={classes.paymentInfoContainer}>
      <nav className={`${customerPartner ? classes.paymentMethodNavCP : classes.paymentMethodNav}`}>
        <h2 className={classes.navBarTitle}>Payment Method On-File {paymentOperation ==="add" ? "(New Card)":"(Card)"}</h2>
        {defaultState && <Button className={classes.mainContactButton} onClick={handleUpdateButton}>
          Update Payment Method
        </Button>}
      </nav>
      {/* {!defaultState && 
        <div className={classes.updateRow1}>
          <p className={classes.paymentLabelBold}>Action: </p>
          <CustomRadioButton items={paymentOperations} name="paymentOperation" handleChange={handleRadioButton} checked={paymentOperationSelected} alignStyle="row" />
        </div>} */}
       {paymentOperation !=="step1" && <div className={classes.row1}>
          <label className={classes.paymentLabelBold}>Payment Method:</label>
          <CustomRadioButton items={["Credit/Debit Card"]} 
            name="paymentType" 
            handleChange={handlePaymentType} 
            checked={paymentTypeSelected} 
            customerPartner={customerPartner}/>
        </div>}  
      {paymentOperation !=="step1" && paymentOperation !=="add"   && 
      <form onSubmit={handleSubmit(handleUpdatePayment)}>     
      {/* <div className={classes.row2}>
        <p className={classes.paymentLabelBold}>Name on Card</p>
        <input 
          className={`${classes.inputStyle1} ${errors.cardHolderName && classes.inputError} `}
          placeholder="Card Holder Name"
          disabled={defaultState}
          {...register("cardHolderName", {required: true})} />
      </div> */}
      {loading ?  <div className={classes.loadingDiv}>
        <PropagateLoader color={`${customerPartner ? "#306AC0" : "#E84E3B"}`} size={14}/>
      </div> :
      <>
        <div className={classes.row3}>
          <div className={classes.row3Col1}>
            <p className={classes.paymentLabelBold}>Card Number</p>
            <input
              placeholder="#### #### #### ####"
              disabled={defaultState}
              value={cardNumber}
              className={`${classes.inputStyle2} ${errors.cardNumber? classes.inputError:undefined} `}
              {...register("cardNumber", {required: true, minLength:14})} 
              onChange={handleCardNoInput}
              />
              {errors?.cardNumber && 
                <p className={classes.errorMessage}>Please enter a valid card Number</p>} 
          </div>
          {!defaultState && <div className={classes.row3Col2}>
            <p className={classes.paymentLabelBold}>CVV</p>
            <input 
              className={`${classes.inputStyle2} ${errors.cvv? classes.inputError:undefined} `}
              placeholder="###"
              maxLength={4}
              disabled={defaultState}
              {...register("cvv", {required: true, minLength:3})} />
              {errors?.cvv && 
                <p className={classes.errorMessage}>Please enter a valid cvv</p>} 
          </div>
          }
        </div>
        <div className={classes.row4}>
          <div className={classes.row4Col1}>
            <p className={classes.paymentLabelBold}>Month</p>
            <input
              className={`${classes.inputStyle2} ${errors.expiryMonth?classes.inputError:undefined} `}
              placeholder="##"
              maxLength={2}
              disabled={defaultState}
              {...register("expiryMonth", {required: true, min:1, max:12})} 
            />
            {errors?.expiryMonth && 
                <p className={classes.errorMessage}>Please enter a valid expiry month</p>}
              {/* <select id="month" {...register("expiryMonth", {required: true})} 
                className={errors.state ? classes.inputError : classes.inputStyle2}>
                {monthArray.map((item, index)=><option value={item.value} key={index}>{item.label}</option>)}                        
              </select> */}
          </div>
          <div className={classes.row4Col2}>
            <p className={classes.paymentLabelBold}>Year</p>
            <input
              className={`${classes.inputStyle2} ${errors.expiryYear?classes.inputError:undefined} `}
              placeholder="####"
              maxLength={4}
              disabled={defaultState}
              {...register("expiryYear", {required: true, minLength:4, min:currentYear})} />
              {errors?.expiryYear && 
                <p className={classes.errorMessage}>Please enter a valid expiry year</p>}
          </div>
        </div>
        {defaultState && <div className={classes.row8}>
        <hr className={`me-3 ${classes.horizontalLine}`}/>
          <p className={classes.paymentStatusPara}>Autopay on your account is currently: &nbsp; 
            <span className={autoPayStatus ? classes.autoPayActive : classes.autoPayInactive}>{autoPayStatus ? "Active" : "Disabled"}</span>
          </p>
          {/* <p className={classes.paymentStatusPara}>Your next payment will be pulled: [Date]</p>
          <p className={classes.paymentStatusPara}>Please restablish service through: <span className={classes.paymentMethodLink}>Update Package</span> in the Service Center below</p> */}
        </div>}
        {paymentOperation ==="update" && <>
        <div className={`paymentCheckbox ${classes.row9}`}>
          <input type="checkbox"
            id="confirmCheck"
            onChange={()=>setConsentCheck(!consentCheck)}
            {...register("confirmCheck", {required: true})}/>
          <label htmlFor="confirmCheck"></label>
          <p className={`${classes.row9Para} ${errors.confirmCheck?classes.errorPara:""}`}>By clicking Save Changes I understand that the information provided will be saved as my Payment Method on file, and authorize monthly payments to be paid automatically through this method.</p>
          </div>
          <Button type="submit" className={`${customerPartner ? classes.updateDataButtonCP : classes.updateDataButton}`}>Save Changes</Button>
        </>}
      </>}
      </form>}
      {/* {paymentOperation ==="add" && <div>
        <div className={classes.row2}>
        <p className={classes.paymentLabelBold}>Name on Card</p>
        <input className={classes.inputStyle1} 
          placeholder="Card Holder Name"
          disabled={defaultState}
          {...register("cardHolderName1", {required: true})} />
      </div>
      <div className={classes.row3}>
        <div className={classes.row3Col1}>
          <p className={classes.paymentLabelBold}>Card Number</p>
          <input className={classes.inputStyle2} 
            placeholder="#### #### #### ####"
            disabled={defaultState}
            {...register("cardNumber1", {required: true})} />
        </div>
        <div className={classes.row3Col2}>
          <p className={classes.paymentLabelBold}>CVV</p>
          <input className={classes.inputStyle2} 
            placeholder="###"
            disabled={defaultState}
            {...register("cvv1", {required: true})} />
        </div>
      </div>
      <div className={classes.row4}>
        <div className={classes.row4Col1}>
          <p className={classes.paymentLabelBold}>Month</p>
          <input className={classes.inputStyle2} 
            placeholder="Month"
            disabled={defaultState}
            {...register("expiryMonth1", {required: true})} />
        </div>
        <div className={classes.row4Col2}>
          <p className={classes.paymentLabelBold}>Year</p>
          <input className={classes.inputStyle2} 
            placeholder="####"
            disabled={defaultState}
            {...register("expiryYear1", {required: true})} />
        </div>
      </div>
      <div className={classes.row9}>
        <input type="checkbox" />
        <p className={classes.row9Para}>By clicking Save Changes I understand that the information provided will be saved as my Payment Method on file, and authorize monthly payments to be paid automatically through this method.</p>
        <Button className={classes.updateDataButton}>Save Changes</Button>
      </div>
      </div>} */}
      {loadingUpdate &&  <div className={classes.loadingDiv}>
        <PropagateLoader color={`${customerPartner ? "#306AC0" : "#E84E3B"}`} size={14}/>
      </div>}
      {showToast && <div className={classes.loadingDiv}>
        <CustomToast 
          show={showToast} 
          delay={toastType==="success" ? 3000: 6000}
          message={toastMessage} 
          handleClose={()=>setShowToast(false)} 
          type={toastType} 
        />
      </div>}
    </div>
  </div>
};

export default PaymentMethod;
