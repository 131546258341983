import React, {useState,useEffect, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import {Navbar, Container, Nav, NavDropdown, Button} from 'react-bootstrap';
import Logo from '../../Assets/Images/synKro_Giga_Logo.png';
import AdeptLogo from '../../Assets/Images/adept_internet_solution_logo.png';
import LamarWirelessLogo from '../../Assets/Images/lamar_wireless_logo.png';
import MobileLogo from '../../Assets/Images/synkro-logo2.png';
import classes from './CustomerAccountNavBar.module.css';
import userServices from '../../Services/UserServices';
// import { loginUrls } from '../../Utils/loginUrls';
import LoginServices from '../../Services/LoginServices';
import LogoutIcon from '../../Assets/SVGIcons/LogoutIcon';
import UserHomeData from '../../Context/UserAccountContext';

const CustomerAccountNavBar = () => {
    const { userAccountInfo } = useContext(UserHomeData);
    const [confirmPwdShown, setConfirmPwdShown] = useState(false);
    // const [loginUrl, setLoginUrl] = useState("");
    // const currentUrl = window.location.host;
    const [refreshToken] = useState(sessionStorage.getItem("refreshToken"));
    const currentPath = window.location.pathname;
    const navigate = useNavigate();

    const handleLogo = () =>{
      switch(userAccountInfo?.locationDetails?.city?.toLowerCase()){
        case "paris":
          return <img src={LamarWirelessLogo} className={classes.brandLogo} alt='logo' 
            onClick={()=>!userAccountInfo && navigate("/home")}/>;
        case "colville":
          return <img src={AdeptLogo} className={classes.brandLogo} alt='logo' 
            onClick={()=>!userAccountInfo && navigate("/home")}/>;
        default :
          return <img src={Logo} className={classes.brandLogo} alt='logo' 
            onClick={()=>!userAccountInfo && navigate("/home")}/>;
      }
    }

    const handleLogout = ()=>{
      let data ={
        RefreshToken:refreshToken
      }
      LoginServices.customerLogout(data)
      .then((resp)=>{
        sessionStorage.removeItem("accessToken");
        sessionStorage.removeItem("refreshToken");
        if(userAccountInfo?.locationDetails?.multiTenantDomain){
          window.location.replace(`https://${userAccountInfo?.locationDetails?.multiTenantDomain}/home`);
        }
        else{
          navigate('/home')
        }
      })
    }

    const handleConfirmPwdView = () => {
        setConfirmPwdShown(!confirmPwdShown);
      }
      
  return <><Navbar expand="md" className={`${(currentPath==="/customer-login" || currentPath==="/password-reset" )?classes.loginNavbar:
  classes.navigationBar}`}>
  <Container fluid className={classes.navbarContainer}>
      <Navbar.Brand>
        {handleLogo()}
        {/* <img src={Logo} className={classes.brandLogo} alt='logo' onClick={()=>navigate("/home")}/> */}
        <img src={MobileLogo} className={classes.mobileLogo} alt='logo'/>
      </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className={classes.navMenubar}>
        {(currentPath==="/customer-login" || currentPath==="/password-reset" )? <>
        {currentPath==="/password-reset" && 
        <p onClick={()=>navigate("/customer-login")} className={classes.loginLink}>Login</p>}
        </>:
        <div>
          <span className={classes.userNameSpan}>Welcome {userAccountInfo.name}!</span>
          {/* <Button className={classes.myAccountButton} onClick={()=>navigate("/account-info/details")} >Manage Account</Button> */}
          {/* <p className={classes.logoutPara} onClick={handleLogout}>
            <LogoutIcon width="24" height="24" color="#000000" />
             &nbsp; <span>Logout</span>
          </p> */}
          <Button className={classes.myAccountButton} onClick={handleLogout} >Logout</Button>
        </div>
        }
      </Nav>
    </Navbar.Collapse>
  </Container>
  </Navbar>
  </>
};

export default CustomerAccountNavBar;
