import React, { useState } from "react";
import { LiveChatWidget } from "@livechat/widget-react";
import { Navigate, Outlet } from "react-router-dom";
import AlertBar from "../../Components/AlertBar/AlertBar";
import Sidebar from "../../Components/SidebarMenu/SidebarMenu";
import { UserHomeData } from "../../Context/UserAccountContext";
import classes from "./LoggedUserRoutes.module.css";
import CustomerAccountNavBar from "../../Components/CustomerAccountNavBar/CustomerAccountNavBar";

const LoggedUserRoutes = () => {
  const [authToken] = useState(sessionStorage.getItem("accessToken"));
  const [refreshToken] = useState(sessionStorage.getItem("refreshToken"));

  return <> {
   (authToken || refreshToken) ? 
    <UserHomeData >
      <AlertBar />
      <CustomerAccountNavBar />
      <Sidebar />          
      <div className={classes.customerPages}>
      <Outlet />
      </div>
    </UserHomeData> : 
    <Navigate to="/customer-login"/>}
    <LiveChatWidget
        license="12250599"
        group="0"
        />
  </>
};

export default LoggedUserRoutes;